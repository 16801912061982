<template>
  <div class="q-pa-sm">
    <RecipientsList
      :UserNotifierCopy="alertList"
      showName
      showActionEdit
      @actionSubmit="notifierSubmit"
      @formSubmit="notifierSubmit"
      @deleteNotifier="deleteNotifier"
    />
  </div>
</template>

<script>
import RecipientsList from '@/components/Notifications/RecipientsList.vue';
import store from '@/store';

export default {
  name: 'FarmUsers',
  components: {
    RecipientsList
  },
  data () {
    return {
      tab: ''
    };
  },
  mounted () {
    if (this.tab === '' && this.$store.state.userList.length > 0) {
      this.tab = this.$store.state.userList[0].id;
    }
  },
  computed: {
    userList () {
      const list = [];
      for (let i = 0; i < this.$store.state.userList.length; i++) {
        if (this.$store.state.userList[i].id !== this.$store.state.user.user_id) {
          list.push(this.$store.state.userList[i]);
        }
      }
      return list;
    },
    alertList () {
      const list = [];
      const myId = this.$store.state.user.user_id;
      for (let i = 0; i < this.$store.state.otherFarmAlerts.length; i++) {
        if (this.$store.state.otherFarmAlerts[i].user_id !== myId) {
          list.push(this.$store.state.otherFarmAlerts[i]);
        }
      }
      return list;
    }
  },
  methods: {
    notifierSubmit (notifier) {
      let dispatch;
      if (notifier.id === 'new') {
        dispatch = 'managerCreateUserAlarm';
      } else {
        dispatch = 'managerUpdateUserAlarm';
      }
      store.dispatch(dispatch, notifier).then(results => {
        this.$finishResult.handleResultsAsync(results).then(response => {
          if (response === 'fail') {
            // No action needed
          } else if (response === 'retry') {
            this.formSubmit(notifier);
          }
        });
      });
    },
    deleteNotifier (notification) {
      store.dispatch('managerDeleteUserAlarm', notification).then(results => {
        this.$finishResult.handleResultsAsync(results).then(response => {
          if (response === 'fail') {
            // No action needed
          } else if (response === 'retry') {
            this.deleteNotifier(notification);
          }
        });
      });
    }
  },
  watch: {
    '$store.state.userList': {
      handler: function () {
        if (this.tab === '' && this.$store.state.userList.length > 0) {
          this.tab = this.$store.state.userList[0].id;
        }
      },
      deep: true
    }
  }
};

</script>
