import { render, staticRenderFns } from "./Charts.vue?vue&type=template&id=cec85f60&scoped=true&"
import script from "./Charts.vue?vue&type=script&lang=js&"
export * from "./Charts.vue?vue&type=script&lang=js&"
import style0 from "./Charts.vue?vue&type=style&index=0&id=cec85f60&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cec85f60",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QCard,QCardSection});
