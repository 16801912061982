<template>
  <div>
    <q-dialog v-model="editActionModal" persistent>
      <q-card
        dark
        square
        bordered
        class="q-pa-sm text-black"
      >
        <q-card-section>
          <ActionCore
            style="min-width:250px"
            class="core-padding"
            :UserNotifierCopy="[notifierCopy]"
            @itemClicked="checkUncheck"
            narrow
            autoCollapse
          />
        </q-card-section>
        <q-card-section>
          <div align="right">
            <div class="row full-width">
              <div class="col spaced">
                <q-btn class="full-width" label="Cancel" color="secondary" @click="cancel"/>
              </div>
              <div class="col spaced">
                <q-btn class="full-width" label="Submit" type="submit" color="primary" @click="onSubmit"/>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import ActionCore from '@/components/Notifications/ActionCore.vue';
export default {
  name: 'ActionModal',
  props: {
    notifier: {
      type: Object,
      required: true
    }
  },
  components: {
    ActionCore
  },
  data () {
    return {
      editActionModal: true,
      notifierCopy: {}
    };
  },
  computed: {},
  mounted () {
    this.setNotifier();
  },
  methods: {
    cancel () {
      this.$emit('cancelEdit');
    },
    onSubmit () {
      this.$emit('formSubmitted', this.notifierCopy);
      this.editActionModal = false;
    },
    setNotifier () {
      this.notifierCopy = JSON.parse(JSON.stringify(this.notifier));
    },
    checkUncheck (alarmId, Index, value) {
      this.$set(this.notifierCopy.alarms, alarmId, value);
    }
  },
  watch: {
    recipient () {
      this.setNotifier();
    }
  }
};

</script>

<style scoped>
.spaced {
  margin-left: 2px;
  margin-right: 2px;
}
</style>
