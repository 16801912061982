import { render, staticRenderFns } from "./ViewSettings.vue?vue&type=template&id=fb60bf36&scoped=true&"
import script from "./ViewSettings.vue?vue&type=script&lang=js&"
export * from "./ViewSettings.vue?vue&type=script&lang=js&"
import style0 from "./ViewSettings.vue?vue&type=style&index=0&id=fb60bf36&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb60bf36",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QCard,QCardSection,QBtn,QRadio,QToggle});
