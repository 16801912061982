<template>
  <div>
    <q-dialog v-model="editNotifierModal" persistent>
      <q-card
        dark
        square
        bordered
        class="q-pa-sm shadow-1"
      >
        <q-card-section>
          <q-form @submit="onSubmit" class="q-gutter-sm">
          <div class="row items-center">
            <q-toolbar class="bg-grey-9 text-white shadow-2" style="width: 310px">
              <q-toolbar-title class="row justify-center items-center">
                <q-icon :name="notifierCopy.icon" size="md" class="q-mr-sm" />
                {{ notifierCopy.type }}
              </q-toolbar-title>
            </q-toolbar>
          </div>
            <q-select
              v-if="showName"
              v-model="notifierCopy.user_id"
              dark
              square
              filled
              :options="userList"
              emit-value
              map-options
              label="User"
            />
            <q-input
              v-model="notifierCopy.name"
              dark
              square
              filled
              label="Notifier Name"
              outlined
              lazy-rules
              hide-bottom-space
              :rules="[
                val => (val && val.length > 0) || 'Please enter Notifier Name'
              ]"
            />
            <q-select
              dark
              square
              filled
              :options="notifierTypes"
              v-model="notifierCopy.type"
              label="Notifier Type"
              @input="setIcon(), clearTarget()"
            />
            <q-input
              v-if="notifierCopy.type === 'Email'"
              v-model="notifierCopy.target"
              dark
              square
              filled
              type="email"
              label="Email"
              outlined
            />
            <q-input
              v-if="notifierCopy.type === 'Phone'"
              v-model="notifierCopy.target"
              dark
              square
              filled
              label="Phone #"
              type="tel"
              mask="(###) ### - ####"
              unmasked-value
              outlined
              lazy-rules
              hide-bottom-space
              :rules="[
                val => (val && val.length === 10) || 'Please enter valid phone #'
              ]"
            />
            <q-input
              v-if="notifierCopy.type === 'Text'"
              v-model="notifierCopy.target"
              dark
              square
              filled
              label="Text #"
              type="tel"
              mask="(###) ### - ####"
              unmasked-value
              outlined
              lazy-rules
              hide-bottom-space
              :rules="[
                val => (val && val.length === 10) || 'Please enter valid phone #'
              ]"
            />
            <q-input
              v-if="notifierCopy.type === 'Whatsapp'"
              v-model="notifierCopy.target"
              dark
              square
              filled
              label="Whatsapp #"
              type="tel"
              mask="(###) ### - ####"
              unmasked-value
              outlined
              lazy-rules
              hide-bottom-space
              :rules="[
                val => (val && val.length === 10) || 'Please enter valid phone #'
              ]"
            />
            <div class="q-mt-md">
              <q-toggle v-model="notifierCopy.active" color="primary" label="Active" />
            </div>
            <div class="q-mb-lg">
              <q-toggle v-model="notifierCopy.snooze" color="red-14" label="Do Not Disturb" />
            </div>
            <div class="row items-center justify-center">
              <timer-set
                size="280px"
                :StartTime="[notifierCopy.snooze_start]"
                :StopTime="[notifierCopy.snooze_end]"
                :Periods="[true]"
                :disabled="!notifierCopy.snooze"
                snap15
                backColor="#1d1d1d"
                circleColor="#C4B983"
                onColor="#F00"
                handleColor="#AD9E52"
                @onValueChanged="timerValueChanged">
              </timer-set>
            </div>
            <div class="q-mt-lg">
              <div class="row full-width">
                <div class="col spaced">
                  <q-btn class="full-width" label="Cancel" color="secondary" @click="cancel"/>
                </div>
                <div class="col spaced">
                  <q-btn class="full-width" label="Submit" type="submit" color="primary"/>
                </div>
              </div>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import TimerSet from '@/components/TimerSet.vue';
export default {

  props: {
    notifier: {
      type: Object,
      required: true
    },
    showName: {
      type: Boolean
    }
  },
  components: {
    TimerSet
  },
  data () {
    return {
      editNotifierModal: true,
      notifierCopy: {},
      notifierTypes: [
        'Email', 'Phone', 'Text', 'Whatsapp'
      ]
    };
  },
  computed: {
    userList () {
      const list = [];
      for (let i = 0; i < this.$store.state.userList.length; i++) {
        if (this.$store.state.userList[i].id !== this.$store.state.user.user_id) {
          list.push({
            label: this.$store.state.userList[i].name,
            value: this.$store.state.userList[i].id
          });
        }
      }
      return list;
    }
  },
  mounted () {
    this.setNotifier();
  },
  methods: {
    cancel () {
      this.$emit('cancelEdit');
    },
    clearTarget () {
      this.notifierCopy.target = '';
    },
    timerValueChanged (values) {
      this.notifierCopy.snooze_start = values.StartTime[0];
      this.notifierCopy.snooze_end = values.StopTime[0];
    },
    onSubmit () {
      this.$emit('formSubmitted', this.notifierCopy);
      this.editNotifierModal = false;
    },
    setIcon () {
      this.notifierCopy.icon = this.notifierCopy.type.toLowerCase();
      if (this.notifierCopy.icon === 'text') {
        this.notifierCopy.icon = 'fas fa-sms';
      } else if (this.notifierCopy.icon === 'whatsapp') {
        this.notifierCopy.icon = 'fab fa-whatsapp';
      }
    },
    setNotifier () {
      this.notifierCopy = JSON.parse(JSON.stringify(this.notifier));

      if (this.notifierCopy.type) {
        // Convert first char to upper
        this.notifierCopy.type = this.notifierCopy.type.charAt(0).toUpperCase() + this.notifierCopy.type.slice(1);
        this.setIcon();
      }
    }
  },
  watch: {
    recipient () {
      this.setNotifier();
    }
  }
};

</script>

<style scoped>
.spaced {
  margin-left: 2px;
  margin-right: 2px;
}
</style>
