
const PondColors = {
  Green: '#008000',
  Yellow: '#FFFF00',
  Orange: '#FFA500',
  Red: '#FF0000',
  Grey: '#808080',
  Seine: '#00BFFF',
  Maintenance: '#FFFFFF',
  /**
   *
   * @param {string} RingColor
   * @returns {string}
   * @constructor
   */
  LowFlash: function (RingColor) {
    const R = parseInt(RingColor.substr(1, 2), 16) * 1.5;
    const G = parseInt(RingColor.substr(3, 2), 16) * 1.5;
    const B = parseInt(RingColor.substr(5, 2), 16) * 1.5;
    return '#' + R.toString(16) + G.toString(16) + B.toString(16);
  },
  Generator: '#00FF00'
};

module.exports = PondColors;
