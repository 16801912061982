<template>
  <div>
    <q-dialog v-model="shown" persistent class="bg-dark text-white">
      <q-card
        dark
        square
        bordered
        class="q-pa-lg bg-grey-10"
      >
        <q-card-section class="bg-grey-10 text-white">
          <q-form @submit="onSubmit" class="q-gutter-sm bg-grey-10 text-white">
            <div class="row">
              <div class="col">
                <q-checkbox dark v-model="in_service" label="In service" color="primary" />
              </div>
              <div class="col">
                <q-checkbox dark v-model="harvest_buoy" label="HB20" color="primary"/>
              </div>
            </div>
            <q-select
              v-model="localData.aerlink_device"
              dark
              square
              filled
              :options="aerlinkDevices"
              emit-value
              map-options
              label="Aerlink Device"
            />
            <div class="row">
              <div class="col separate">
                <q-input
                  dark
                  dense
                  filled
                  v-model="localData.name"
                  label="Name"
                  maxlength="32"
                  outlined
                  square
                />
              </div>
              <div class="col separate">
                <q-input
                  dark
                  filled
                  dense
                  v-model="localData.radio_id"
                  label="Radio ID"
                  maxlength="3"
                  mask="XXX"
                  outlined
                  square
                />
              </div>
            </div>
            <div class="row">
              <div class="col separate" v-for="(n, index) in localData.buoy_names" :key="index">
                <q-input
                  dark
                  dense
                  input-style
                  filled
                  v-model="localData.buoy_names[index]"
                  :label="'Buoy ' + (index + 1)"
                  maxlength="32"
                  outlined
                  square
                />
              </div>
            </div>
            <div class="row" v-for="(n, index) in aeratorColumns" :key="index + 10">
              <div class="col separate">
                <q-input
                  dark
                  dense
                  input-style
                  filled
                  v-model="localData.aerator_names[n.left.index]"
                  :label="'Aerator ' + (n.left.index + 1)"
                  maxlength="32"
                  outlined
                  square
                />
              </div>
              <div class="col separate">
                <q-input
                  dark
                  dense
                  input-style
                  filled
                  v-model="localData.aerator_names[n.right.index]"
                  :label="'Aerator ' + (n.right.index + 1)"
                  maxlength="32"
                  outlined
                  square
                />
              </div>
            </div>
            <div align="right">
              <div class="row full-width">
                <div class="col spaced">
                  <q-btn class="full-width" label="Cancel" color="secondary" @click="onCancel"/>
                </div>
                <div class="col spaced">
                  <q-btn class="full-width" label="Submit" type="submit" color="primary"/>
                </div>
              </div>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>

export default {

  props: {
    shown: {
      type: Boolean,
      required: true
    },
    pondData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      localData: {}
    };
  },
  mounted () {
    this.updateCopy();
  },
  methods: {
    updateCopy () {
      this.localData = JSON.parse(JSON.stringify(this.pondData));
    },
    onSubmit () {
      this.localData.in_service = this.in_service ? 1 : 0;
      this.localData.harvest_buoy = this.harvest_buoy ? 1 : 0;
      this.$emit('submit', this.localData);
    },
    onCancel () {
      this.$emit('cancel');
    }
  },
  computed: {
    aerlinkDevices () {
      const list = [];
      for (let i = 0; i < this.$store.state.aerlinkDevices.length; i++) {
        list.push({
          label: this.$store.state.aerlinkDevices[i].name,
          value: this.$store.state.aerlinkDevices[i].id
        });
      }
      list.push({
        label: 'None selected',
        value: null
      });
      return list;
    },
    aeratorColumns () {
      const columns = [];
      if (!this.localData.aerator_names) {
        return [];
      }
      for (let i = 0; i < this.localData.aerator_names.length; i += 2) {
        columns.push({
          left: {
            name: this.localData.aerator_names[i],
            index: i
          },
          right: {
            name: this.localData.aerator_names[i + 1],
            index: i + 1
          }
        });
      }
      return columns;
    },
    in_service: {
      get: function () {
        return this.localData.in_service > 0;
      },
      set: function (newValue) {
        this.localData.in_service = newValue ? 1 : 0;
      }
    },
    harvest_buoy: {
      get: function () {
        return this.localData.harvest_buoy > 0;
      },
      set: function (newValue) {
        this.localData.harvest_buoy = newValue ? 1 : 0;
      }
    }
  },
  watch: {
    pondData () {
      this.updateCopy();
    }
  }
};
</script>

<style scoped>
.separate {
  margin: 2px;
}

.min-width {
  min-width: 300px;
}

.spaced {
  margin-left: 2px;
  margin-right: 2px;
}
</style>
