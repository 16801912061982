<template>
  <q-footer elevated class="bg-grey-10 text-white">
    <div class="row">
      <div v-if="smallButtons" class="col"></div>
      <div class="col">
        <div class="q-pa-md">
          <q-btn-group spread>
            <q-btn color="secondary" @click="cancel">Cancel</q-btn>
            <q-btn color="primary" @click="save">Save</q-btn>
          </q-btn-group>
        </div>
      </div>
    </div>
  </q-footer>
</template>

<script>
export default {
  name: 'FooterBar',
  methods: {
    cancel () {
      this.$emit('cancel');
    },
    save () {
      this.$emit('save');
    }
  },
  computed: {
    smallButtons () {
      return this.$q.screen.width > 800;
    }
  }
};
</script>

<style scoped>

</style>
