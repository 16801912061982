import { render, staticRenderFns } from "./RecipientsList.vue?vue&type=template&id=41ac617e&scoped=true&"
import script from "./RecipientsList.vue?vue&type=script&lang=js&"
export * from "./RecipientsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41ac617e",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QToolbar,QToolbarTitle,QIcon,QList,QItem,QSeparator,QAvatar,QItemSection,QSpace,QBtn,QPageSticky});
