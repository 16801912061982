<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'pond-root',
  props: {
    id: {
      type: String,
      required: true
    }
  }
};
</script>
