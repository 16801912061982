<template>
  <q-tabs align="right">
    <q-route-tab :to="'/pond/' + id" icon="insert_chart"/>
    <q-route-tab :to="'/pond/' + id + '/alerts'" label="Alerts"/>
    <q-route-tab :to="'/pond/' + id + '/alarms'" label="Alarms"/>
    <q-route-tab :to="'/pond/' + id + '/buoy'" label="Buoy"/>
    <q-route-tab :to="'/pond/' + id + '/setpoints'" label="Setpoints"/>
    <q-route-tab :to="'/pond/' + id + '/timers'" label="Timers"/>
    <q-route-tab :to="'/pond/' + id + '/settings'" label="Settings"/>
    <q-route-tab :to="'/pond/' + id + '/resets'" label="Resets"/>
  </q-tabs>
</template>

<script>
export default {
  name: 'pond-tabs',
  props: {
    id: {
      type: String,
      required: true
    }
  }
};
</script>
