
export default class AuthUtils {
  /**
   *
   * @param {string} target
   * @param {string} user
   * @returns {boolean}
   */
  static validateAuthLevel (target, user) {
    const authLevels = [
      'farm-visitor',
      'farm-worker',
      'acct-manager',
      'acct-owner',
      'installer',
      'super-admin'
    ];
    let userLevel = 0;
    let targetLevel = 0;
    for (let i = 0; i < authLevels.length; i++) {
      if (authLevels[i] === target) {
        targetLevel = i;
      }
      if (authLevels[i] === user) {
        userLevel = i;
      }
    }
    return userLevel >= targetLevel;
  }
}
