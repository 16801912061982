<template>
  <q-page class="flex flex-top">
    <RecipientsList
      v-if="copyLoadingComplete"
      :UserNotifierCopy="UserNotifierCopy"
      @formSubmit="formSubmit"
      @onDeleteNotifier="deleteNotifier"
    />
  </q-page>
</template>

<script>
import RecipientsList from '@/components/Notifications/RecipientsList.vue';
import store from '@/store';

export default {
  name: 'nt-recipients',
  components: {
    RecipientsList
  },
  data () {
    return {
      copyLoadingComplete: false,
      /** @type NotifierSettings[] */
      UserNotifierCopy: []
    };
  },
  mounted () {
    // Notification_root also needs to be watched
    if (this.$store.getters.selectedFarm) {
      this.updateUserCopy();
    }
  },
  methods: {
    deleteNotifier (notification) {
      store.dispatch('deleteUserAlarm', notification).then(results => {
        this.$finishResult.handleResultsAsync(results).then(response => {
          if (response === 'fail') {
            this.updateUserCopy();
          } else if (response === 'retry') {
            this.deleteNotifier(notification);
          }
        });
      });
    },
    firstCharToUpper (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formSubmit (notifier) {
      let dispatch;
      if (notifier.id === 'new') {
        dispatch = 'createUserAlarm';
      } else {
        dispatch = 'updateUserAlarm';
      }
      store.dispatch(dispatch, notifier).then(results => {
        this.$finishResult.handleResultsAsync(results).then(response => {
          if (response === 'fail') {
            this.updateUserCopy();
          } else if (response === 'retry') {
            this.formSubmit(notifier);
          }
        });
      });
    },
    updateUserCopy () {
      this.copyLoadingComplete = false;
      this.UserNotifierCopy =
        JSON.parse(
          JSON.stringify(
            this.$store.state.userFarmAlerts
          ));
      this.copyLoadingComplete = true;
    }
  },
  watch: {
    '$store.state.selectedFarm': {
      handler: function () {
        if (this.$store.getters.selectedFarm) {
          this.updateUserCopy();
        }
      },
      deep: true
    },
    '$store.state.userFarmAlerts': {
      handler: function () {
        if (!this.$store.state.editMode) {
          this.updateUserCopy();
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped>

.custom-offset {
  top: 44px;
  right: 48px;
}

</style>
