<template>
  <div>
    <table :class="tableClass">
      <tr>
        <th class="table-header left-column" >Alarms - Alerts</th>
        <th
          class="table-header table-header-notifiers"
          v-for="(notifier, i) in notifiers" :key="i">
          {{ notifier }}
        </th>
      </tr>
      <tr>
        <th class="critical-alarms-header bg-red-6"
          @click="chevCritical"
          :colspan="notifiers.length + 1">
          <div class="float-left">
            Critical Alarms
          </div>
          <div class="float-right chevron-style">
            <q-icon name="chevron_right" v-show="!showTr.criticalTr"></q-icon>
            <q-icon name="expand_more" v-show="showTr.criticalTr"></q-icon>
          </div>
        </th>
      </tr>
      <ActionRow
        v-show="showTr.criticalTr"
        v-for="(alarm) in Critical_0"
        :key="alarm.NameIndex"
        :alarmType="alarm.NameIndex"
        :alarms="actionRowPivotArray[alarm.NameIndex]"
        :name="alarm.Text"
        :dark="dark"
        @itemClicked="rowItemClicked"
      />
      <tr>
        <th class="level-one-alarms bg-orange-6"
          @click="chevAlarm"
          :colspan="notifiers.length + 1">
          <div class="float-left">
            Level 1 Alarms
          </div>
          <div class="float-right chevron-style">
            <q-icon name="chevron_right" v-show="!showTr.alarmTr"></q-icon>
            <q-icon name="expand_more" v-show="showTr.alarmTr"></q-icon>
          </div>
        </th>
      </tr>
      <ActionRow
        v-show="showTr.alarmTr"
        v-for="(alarm) in Level_1"
        :key="alarm.NameIndex"
        :alarmType="alarm.NameIndex"
        :alarms="actionRowPivotArray[alarm.NameIndex]"
        :name="alarm.Text"
        :dark="dark"
        @itemClicked="rowItemClicked"
      />
      <tr>
        <th class="level-two-alerts bg-yellow-6"
          @click="chevAlert"
          :colspan="notifiers.length + 1">
          <div class="float-left">
            Level 2 Alerts
          </div>
          <div class="float-right chevron-style">
            <q-icon name="chevron_right" v-show="!showTr.alertTr"></q-icon>
            <q-icon name="expand_more" v-show="showTr.alertTr"></q-icon>
          </div>
        </th>
      </tr>
      <ActionRow
        v-show="showTr.alertTr"
        v-for="(alarm) in Level_2"
        :key="alarm.NameIndex"
        :alarmType="alarm.NameIndex"
        :alarms="actionRowPivotArray[alarm.NameIndex]"
        :name="alarm.Text"
        :dark="dark"
        @itemClicked="rowItemClicked"
      />
      <tr>
        <th class="notifications bg-green-6"
          @click="chevNotifications"
          :colspan="notifiers.length + 1">
          <div class="float-left">
            Notifications
          </div>
          <div class="float-right chevron-style">
            <q-icon name="chevron_right" v-show="!showTr.notificationsTr"></q-icon>
            <q-icon name="expand_more" v-show="showTr.notificationsTr"></q-icon>
          </div>
        </th>
      </tr>
      <ActionRow
        v-show="showTr.notificationsTr"
        v-for="(alarm) in Notifications_3"
        :key="alarm.NameIndex"
        :alarmType="alarm.NameIndex"
        :alarms="actionRowPivotArray[alarm.NameIndex]"
        :name="alarm.Text"
        :dark="dark"
        @itemClicked="rowItemClicked"
      />
    </table>
  </div>
</template>

<script>
// NOTE: ActionRow $emits itemClicked

import ActionRow from '@/components/Notifications/ActionRow.vue';
import { AlarmList, Level } from '@/lib/AlarmSettingsList';
import { alarmsLength } from 'alarm-defs';

export default {
  components: {
    ActionRow
  },
  props: {
    /** @type NotifierSettings[]|vue-prop */
    UserNotifierCopy: {
      type: Array,
      required: true
    },
    narrow: {
      type: Boolean
    },
    autoCollapse: {
      type: Boolean
    }
  },
  data () {
    return {
      Critical_0: AlarmList().getLevelList(Level.Critical),
      Level_1: AlarmList().getLevelList(Level.Level1),
      Level_2: AlarmList().getLevelList(Level.Level2),
      Notifications_3: AlarmList().getLevelList(Level.Level3),
      showTr: {
        criticalTr: true,
        alarmTr: !this.narrow,
        alertTr: !this.narrow,
        notificationsTr: !this.narrow
      }
    };
  },
  methods: {
    rowItemClicked (alarmId, Index, value) {
      this.$emit('itemClicked',
        alarmId,
        Index,
        value
      );
    },
    chevCritical () {
      if (this.autoCollapse) {
        this.showTr.criticalTr = true;
        this.showTr.alarmTr = false;
        this.showTr.alertTr = false;
        this.showTr.notificationsTr = false;
      } else {
        this.showTr.criticalTr = !this.showTr.criticalTr;
      }
    },
    chevAlarm () {
      if (this.autoCollapse) {
        this.showTr.criticalTr = false;
        this.showTr.alarmTr = true;
        this.showTr.alertTr = false;
        this.showTr.notificationsTr = false;
      } else {
        this.showTr.alarmTr = !this.showTr.alarmTr;
      }
    },
    chevAlert () {
      if (this.autoCollapse) {
        this.showTr.criticalTr = false;
        this.showTr.alarmTr = false;
        this.showTr.alertTr = true;
        this.showTr.notificationsTr = false;
      } else {
        this.showTr.alertTr = !this.showTr.alertTr;
      }
    },
    chevNotifications () {
      if (this.autoCollapse) {
        this.showTr.criticalTr = false;
        this.showTr.alarmTr = false;
        this.showTr.alertTr = false;
        this.showTr.notificationsTr = true;
      } else {
        this.showTr.notificationsTr = !this.showTr.notificationsTr;
      }
    }
  },
  computed: {
    dark () {
      return this.$q.dark.isActive;
    },
    tableClass () {
      return (this.narrow ? '' : 'table-width') + ' ' +
        (this.$q.dark.isActive ? 'table-dark' : 'table-light');
    },
    notifiers () {
      const notifierArr = [];

      for (let i = 0; i < this.UserNotifierCopy.length; i++) {
        notifierArr.push(this.UserNotifierCopy[i].name);
      }

      return notifierArr;
    },
    actionRowPivotArray () {
      const ret = [];
      for (let i = 0; i < alarmsLength; i++) {
        const row = [];
        for (let r = 0; r < this.UserNotifierCopy.length; r++) {
          row.push(this.UserNotifierCopy[r].alarms[i]);
        }
        ret.push(row);
      }
      return ret;
    }
  }
};

</script>

<style scoped>

  .chevron-style {
    font-size: 1.5em;
    line-height:0;
    padding-right: 10px;
  }

  .critical-alarms-header {
    text-align: left;
    padding-left: 10px;
    background-color: red;
    color: black;
    cursor: pointer;
  }

  .float-left {
    float: left;
  }

  .float-right {
    float: right;
  }

  .left-column {
    text-align: left;
    width: 200px;
    padding-left: 10px;
  }

  .level-one-alarms {
    text-align: left;
    padding-left: 10px;
    background-color: orange;
    color: black;
    cursor: pointer;
  }

  .level-two-alerts {
    text-align: left;
    padding-left: 10px;
    background-color: yellow;
    color: black;
    cursor: pointer;
  }

  .notifications {
    text-align: left;
    padding-left: 10px;
    background-color: green;
    color: black;
    cursor: pointer;
  }

  table {
    margin: 25px auto 0px auto;
    font-size: 12px;
    border-collapse: collapse;
    background-color: #e6e6e5;
  }

  .table-dark {
    background-color: #5b5b57;
  }

  .table-light {
    background-color: #e6e6e5;
  }

  .table-header {
    color: #d5d9dd;
    background-color: #424242;
    border: 1px solid #212121;
  }

  .table-header-notifiers {
    width: 80px;
    padding-left:1px;
    padding-right:1px;
  }

  th {
    padding: 7px 0px;
    border: 1px solid #212121;
  }

  .spinner {
    margin-top: 45vh;
  }

  @media only screen and (max-width: 600px) {
    table {
      width: 100%;
      margin: 5px auto 0px;
    }
  }

  @media only screen and (min-width: 601px) {
    .table-width {
      min-width: 500px;
    }
  }

</style>
