function Point (x, y) {
  this.x = x;
  this.y = y;

  Object.defineProperty(this, 'isEmpty', {
    get: function () {
      return this.x === 0 && this.y === 0;
    }
  });
}

export { Point };
