<template>
  <div style="padding-top:20px; padding-left:2px; padding-right:2px;">
    <PondSetupModal
      :shown="showEditModal"
      :pondData="editCoreCopy"
      @cancel="cancelEdit"
      @submit="editPondComplete"
    >
    </PondSetupModal>
    <table>
      <tr v-if="pondCoreCopy.length > 0">
        <th class="table-header name-td">Name</th>
        <th class="table-header">Run</th>
        <th class="table-header">ID</th>
        <th class="table-header">Aerlink</th>
        <th class="table-header">HB20</th>
        <th class="table-header" style="width:65px;">Pos</th>
        <th class="table-header"></th>
        <th class="table-header"></th>
      </tr>
      <tr v-for="(pond, index) in pondCoreCopy" :key="index">
        <td class="name-td">
          {{ pond.name }}
        </td>
        <td class="in-service-td check-style">
          <q-icon
            v-if="pond.in_service > 0"
            name="check_circle"
            color="teal-8"
          ></q-icon>
          <q-icon
            v-if="pond.in_service === 0"
            name="highlight_off"
            color="negative"
          ></q-icon>
        </td>
        <td class="">
         {{ pond.radio_id }}
        </td>
        <td style="padding-left:1px; padding-right:1px;">
          {{ getAerlinkNameById(pond.aerlink_device) }}
        </td>
        <td class="check-style">
          <q-icon
            v-if="pond.harvest_buoy"
            name="check_circle"
            color="teal-8"
          ></q-icon>
        </td>
        <td>
          <div class="row ud-style">
            <div class="col">
              <q-btn
                v-if="pond.position > 0"
                @click="movePondUp(pond)"
                size="sm" padding="xs" icon="expand_less">
              </q-btn>
            </div>
            <div class="col">
              <q-btn
                v-if="pond.position < pondCount - 1"
                @click="movePondDown(pond)"
                size="sm" padding="xs" icon="expand_more">
              </q-btn>
            </div>
          </div>
        </td>
        <td class="edit-td">
          <q-btn
            icon="edit"
            text-color="primary"
            padding="xs"
            @click="editPond(pond)">
          </q-btn>
        </td>
        <td class="delete-td">
          <q-btn
            icon="delete"
            text-color="negative"
            padding="xs"
            @click="deletePond(pond)">
          </q-btn>
        </td>
      </tr>
    </table>
    <q-page-sticky position="bottom-right" :offset="[28, 28]">
      <q-btn fab icon="add" color="accent" @click="addPond"/>
    </q-page-sticky>
  </div>
</template>

<script>
import PondSetupModal from '@/components/PondSetupModal.vue';
// import Finisher from '@/components/Finisher.vue';
import store from '@/store';

export default {
  name: 'PondSetup',
  components: {
    PondSetupModal
  },
  data () {
    return {
      pondCoreCopy: [],
      pondCount: 0,
      shape: [],
      showEditModal: false,
      editCoreCopy: {}
    };
  },
  mounted () {
    this.updatePondCopy();
  },
  methods: {
    getAerlinkNameById (id) {
      return this.$store.getters.getAerlinkNameById(id);
    },
    updatePondCopy () {
      const pondCoreCopy = [];
      for (let i = 0; i < this.$store.state.ponds.pondData.length; i++) {
        const p = this.$store.state.ponds.pondData[i];
        if (!p.ready) {
          continue;
        }
        pondCoreCopy.push({
          id: p.id,
          position: p.position,
          in_service: p.in_service,
          harvest_buoy: p.harvest_buoy,
          radio_id: p.radio_id,
          aerlink_device: p.aerlink_device,
          name: JSON.parse(JSON.stringify(p.name)),
          aerator_names: JSON.parse(JSON.stringify(p.aerator_names)),
          buoy_names: JSON.parse(JSON.stringify(p.buoy_names))
        });
      }
      pondCoreCopy.sort(function (a, b) {
        return a.position - b.position;
      });
      this.pondCoreCopy = pondCoreCopy;
      this.pondCount = pondCoreCopy.length;
    },
    movePondDown (pond) {
      store.dispatch('setPondPosition', {
        pond,
        direction: 'down'
      }).then(results => {
        this.$finishResult.handleResultsAsync(results).then(response => {
          if (response === 'fail') {
            this.updatePondCopy();
          } else if (response === 'retry') {
            this.movePondDown(pond);
          }
        });
      });
    },
    movePondUp (pond) {
      store.dispatch('setPondPosition', {
        pond,
        direction: 'up'
      }).then(results => {
        this.$finishResult.handleResultsAsync(results).then(response => {
          if (response === 'fail') {
            this.updatePondCopy();
          } else if (response === 'retry') {
            this.movePondUp(pond);
          }
        });
      });
    },
    editPond (pond) {
      this.editCoreCopy = JSON.parse(JSON.stringify(pond));
      this.showEditModal = true;
    },
    editPondComplete (pond) {
      this.showEditModal = false;
      this.$finishResult.show();
      let storeFunction;
      if (pond.new) {
        storeFunction = 'createNewPond';
      } else {
        storeFunction = 'updatePondProfile';
      }
      store.dispatch(storeFunction, pond).then(results => {
        this.$finishResult.handleResultsAsync(results).then(response => {
          if (response === 'fail') {
            this.updatePondCopy();
          } else if (response === 'retry') {
            this.editPondComplete(pond);
          }
        });
      });
    },
    deletePond (pond) {
      this.$deleteResult.getResultsAsync(
        'water',
        pond.name,
        'Delete pond?').then(result => {
        if (result === 'delete') {
          this.dispatchDelete(pond);
        }
      });
    },
    dispatchDelete (pond) {
      store.dispatch('deletePond', pond).then(results => {
        this.$finishResult.handleResultsAsync(results).then(response => {
          if (response === 'fail') {
            this.updatePondCopy();
          } else if (response === 'retry') {
            this.dispatchDelete(pond);
          }
        });
      });
    },
    addPond (pond) {
      this.editCoreCopy = {
        new: true,
        id: 0,
        in_service: 1,
        harvest_buoy: 0,
        radio_id: '000',
        name: '',
        aerator_names: [
          'Aerator 1',
          'Aerator 2',
          'Aerator 3',
          'Aerator 4',
          'Aerator 5',
          'Aerator 6',
          'Aerator 7',
          'Aerator 8',
          'Aerator 9',
          'Aerator 10',
          'Aerator 11',
          'Aerator 12'],
        buoy_names: ['Buoy 1', 'Buoy 2']
      };
      this.showEditModal = true;
    },
    cancelEdit () {
      this.showEditModal = false;
    }
  },
  watch: {
    '$store.state.ponds.pondData': {
      handler: function () {
        this.updatePondCopy();
      },
      deep: true
    }
  }
};
</script>

<style scoped>

.check-style {
  font-size: 1.9em;
  color: #0A0;
  text-align: center;
  line-height: 1.3em;
}

.ud-style {
  margin-left: 3px;
  margin-right: 3px;
}

.btn {
  padding: 2px;
}

.checkbox-adjust {
  padding: 0;
}

.delete-header {
  width: 35px;
}

.delete-td {
  padding: 0 0px 0px 0px;
  margin: 2px;
}

.edit-header {
  width: 40px;
}

.edit-td {
  padding: 0px 0px 0px 0px;
  margin: 2px;
}

.do-not-disturb-header {
  padding: 0 0px 0 8px;
  white-space: nowrap;
  width: 80px;
}

.icon-style {
  font-size: 2.3em;
  text-align: center;
  padding: 2px 0px 0px 0px;
}

.fa-adjust {
  font-size: 2.3em;
  text-align: center;
}

.name-header {
  color: #d5d9dd;
  background-color: #424242;
  padding: 10px 10px 10px 0px;
  width: 35px;
}

.name-td {
  padding: 10px 0px 10px 0px;
}

.no-bottom-border {
  border-bottom: none;
  padding: 15px 0px 0px 0px;
  white-space: nowrap;
}

.no-top-border {
  border-top: none;
  padding: 0px 7px 14px 0px;
}

.in-service-td {
  padding: 0 0 0 5px;
}

.in-service-header {
  width: 35px;
}

table {
  margin: 0px auto 0px auto;
  font-size: 12px;
  border-collapse: collapse;
  background-color: #e6e6e5;
  border-left: none;
  border-right: none;
}

.table-header {
  color: #d5d9dd;
  background-color: #424242;
}

td {
  text-align: center;
  border: 1px solid #212121;
  border-left: none;
  border-right: none;
}

.test-header {
  width: 27px;
  padding: 0 0px 0 0px;
}

.test-td {
  padding: 0 0px 0 0;
}

th {
  padding: 10px 0px;
  border: 1px solid #212121;
  border-left: none;
  border-right: none;
}

.to-header {
  width: 75px;
}

.to-td {
  word-wrap: break-word;
  padding: 10px 2px 10px 1px;
}

.type-header {
  width: 30px;
  padding: 10px 0px 10px 3px;
}

.type-td {
  padding: 10px 0px 10px 0px;
}

@media only screen and (max-width: 570px) {
  table {
    table-layout: fixed;
    width: 100%;
  }
}

@media only screen and (min-width: 401px) {
  .name-header {
    padding: 10px 10px 10px 0px;
    width: 45px;
  }

  .name-td {
    padding: 10px 0px 10px 0px;
  }

  .test-header {
    width: 27px;
    padding: 0 0px 0 0px;
  }

  .test-td {
    padding: 0 0px 0 0;
  }

  .to-header {
    width: 90px;
  }

  .to-td {
    word-wrap: break-word;
    padding: 10px 5px 10px 5px;
  }

  .type-header {
    width: 40px;
    padding: 10px 0px 10px 3px;
  }

  .type-td {
    padding: 10px 0px 10px 0px;
  }
}

@media only screen and (min-width: 471px) {
  .name-header {
    padding: 10px 10px 10px 0px;
    width: 45px;
  }

  .name-td {
    padding: 10px 0px 10px 0px;
  }

  .test-header {
    width: 47px;
    padding: 0 0px 0 0px;
  }

  .test-td {
    padding: 0 0px 0 0;
  }

  .to-header {
    width: 130px;
  }

  .to-td {
    word-wrap: break-word;
    padding: 10px 5px 10px 5px;
  }

  .type-header {
    width: 40px;
    padding: 10px 0px 10px 3px;
  }

  .type-td {
    padding: 10px 0px 10px 0px;
  }
}

@media only screen and (min-width: 571px) {
  .btn {
    padding: 2px 7px;
  }

  .delete-td {
    padding: 0px 12px 0px 7px;
  }

  .edit-td {
    padding: 0 7px 0px 20px;
  }

  .name-header {
    padding: 10px 0px 10px 0px;
  }

  .name-td {
    padding: 10px 0px 10px 0px;
  }

  .in-service-td {
    padding: 0 15px;
  }

  .test-header {
    padding: 0 17px 0 12px;
  }

  .test-td {
    padding: 0 17px 0px 12px;
  }

  .type-td {
    padding: 10px 15px;
  }
}

@media only screen and (min-width: 630px) {
  .edit-td {
    padding: 0px 7px 0px 30px;
  }

  .name-header {
    padding: 10px 0px 10px 10px;
  }

  .name-td {
    padding: 10px 0px 10px 10px;
    min-width: 120px;
  }

  .in-service-td {
    padding: 0 15px;
  }

  .test-header {
    padding: 0 27px 0 12px;
  }

  .test-td {
    padding: 0 27px 0px 12px;
  }

  .type-td {
    padding: 10px 25px;
  }
}

@media only screen and (min-width: 700px) {
  .icon-style {
    padding: 0px 10px 0px 10px;
  }

  .test-header {
    padding: 0 47px 0 12px;
  }

  .test-td {
    padding: 0 47px 0px 12px;
  }

}

@media only screen and (min-width: 1070px) {
  .btn {
    padding: 2px 7px;
  }

  .edit-td {
    padding: 0px 7px 0px 20px;
  }

  .fa-adjust {
    padding-top: 7px;
  }

  .icon-style {
    padding: 0px 20px 0px 20px;
  }

  .test-td {
    padding: 0 47px 0px 12px;
  }

}
@media only screen and (min-width: 1130px) {
  .btn {
    padding: 5px 17px;
  }
}

</style>
