<template>
  <span>{{ formatted }}</span>
</template>

<script>
export default {
  name: 'timer-format',
  props: {
    time: {
      type: Number,
      required: true
    }
  },
  computed: {
    formatted () {
      const minute = (this.time * 5) % 60;
      const hour = Math.floor((this.time * 5) / 60);
      return `${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}`;
    }
  }
};
</script>
