<template>
  <div>
    <ActionCore
      class="core-padding"
      v-if="copyLoadingComplete && UserNotifierCopy.length > 0"
      @itemClicked="checkUncheck"
      :UserNotifierCopy="UserNotifierCopy"
    />
    <template v-if="copyLoadingComplete && UserNotifierCopy.length === 0">
      <div class="q-pa-md row items-start q-gutter-md fixed-center">
        <q-card dark bordered class="bg-grey-9 my-card">
          <q-card-section>
            <div class="text-h5">No user notifications</div>
          </q-card-section>
          <q-separator dark inset/>
          <q-card-section style="text-align: center;">
            <router-link class="text-blue-7 text-h6" to="/notifications/recipients">Click here to set up</router-link>
          </q-card-section>
        </q-card>
      </div>
    </template>
    <FooterBar
      v-show="actionsChanged"
      @cancel="cancelChanges"
      @save="saveChanges"
    />
  </div>
</template>

<script>
import store from '@/store';
import ActionCore from '@/components/Notifications/ActionCore.vue';
import FooterBar from '@/components/FooterBar.vue';

export default {
  name: 'nt-actions',
  components: {
    ActionCore,
    FooterBar
  },
  data () {
    return {
      actionsChanged: false,
      copyLoadingComplete: false,
      /** @type NotifierSettings[] */
      UserNotifierCopy: []
    };
  },
  mounted () {
    // Notification_root also needs to be watched
    if (this.$store.getters.selectedFarm) {
      this.updateUserCopy();
    }
  },
  methods: {
    updateUserCopy () {
      this.copyLoadingComplete = false;
      this.UserNotifierCopy =
        JSON.parse(
          JSON.stringify(
            this.$store.state.userFarmAlerts
            // this.$store.getters.selectedFarm.user_alerts
          ));
      this.copyLoadingComplete = true;
    },
    checkUncheck (alarmId, Index, value) {
      this.$set(this.UserNotifierCopy[Index].alarms, alarmId, value);
      if (this.actionsChanged === false) {
        this.actionsChanged = true;
        store.dispatch('setEditMode', true);
      }
    },
    saveChanges () {
      store.dispatch('setUserAlarmSettings', this.UserNotifierCopy).then(results => {
        // TODO disallow changes while pending
        // TODO show good or bad
        if (results.sent) {
          console.log('Server updated');
        } else {
          this.updateUserCopy();
          console.log('Error', results.error);
        }
      });
      this.actionsChanged = false;
      store.dispatch('setEditMode', false);
    },
    cancelChanges () {
      this.updateUserCopy();
      this.actionsChanged = false;
      store.dispatch('setEditMode', false);
    }
  },
  computed: {
    corePadding () {
      if (this.actionsChanged) {
        return 'core-padding-hidden';
      } else {
        return 'core-padding-shown';
      }
    }
  },
  watch: {
    '$store.state.farmIsLoaded': {
      handler: function () {
        if (this.$store.state.farmIsLoaded) {
          this.updateUserCopy();
        }
      },
      deep: true
    },
    '$store.state.userFarmAlerts': {
      handler: function () {
        if (this.$store.state.farmIsLoaded && !this.$store.state.editMode) {
          this.updateUserCopy();
        }
      },
      deep: true
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.actionsChanged) {
      this.$q.dialog({
        dark: true,
        title: 'Navigation error',
        message: 'Save or cancel changes first'
      }).onOk(() => {
      });
    } else {
      next();
    }
  }
};
</script>

<style lang="sass" scoped>
.core-padding
  padding: 10px 3px 75px 3px

.row > div
  padding: 5px 20px 10px 20px

.row + .row
  margin-top: 1rem
</style>
