// Custom graphics shims
CanvasRenderingContext2D.prototype.FillEllipse = function (color, rect) {
  this.fillStyle = color;
  this.beginPath();
  this.ellipse(
    rect.x + (rect.width / 2),
    rect.y + (rect.height / 2),
    rect.width / 2,
    rect.height / 2,
    0,
    0,
    2 * Math.PI);
  this.fill();
};

CanvasRenderingContext2D.prototype.DrawEllipse = function (color, width, rect) {
  this.strokeStyle = color;
  this.lineWidth = width;
  this.beginPath();
  this.ellipse(
    rect.x + (rect.width / 2),
    rect.y + (rect.height / 2),
    rect.width / 2,
    rect.height / 2,
    0,
    0,
    2 * Math.PI);
  this.stroke();
};

CanvasRenderingContext2D.prototype.FillPie = function (color, rect, startAngle, sweepAngle) {
  const cX = rect.x + (rect.width / 2);
  const cY = rect.y + (rect.height / 2);
  this.fillStyle = color;
  this.beginPath();
  this.moveTo(cX, cY);
  this.arc(cX, cY, (rect.width / 2),
    startAngle * Math.PI / 180,
    (startAngle + sweepAngle) * Math.PI / 180,
    sweepAngle < 0);
  this.lineTo(cX, cY);
  this.fill();
};

CanvasRenderingContext2D.prototype.DrawArc = function (width, color, rect, start, sweep) {
  const cX = rect.x + (rect.width / 2);
  const cY = rect.y + (rect.height / 2);
  const end = start + sweep;
  this.strokeStyle = color;
  this.lineWidth = width;
  this.beginPath();
  this.arc(cX, cY, (rect.width / 2), end * Math.PI / 180, start * Math.PI / 180);
  this.stroke();
};

CanvasRenderingContext2D.prototype.DrawLine = function (color, width, p1, p2) {
  this.strokeStyle = color;
  this.lineWidth = width;
  this.beginPath();
  this.moveTo(p1.x, p1.y);
  this.lineTo(p2.x, p2.y);
  this.stroke();
};

CanvasRenderingContext2D.prototype.DrawString = function (value, font, color, rect, align) {
  this.font = font;
  this.strokeStyle = color;
  this.textAlign = 'center';
  this.fillStyle = '#000';
  // Calculate position
  const txt = this.measureText(value);
  const x = rect.x + rect.width / 2 - 0.5;
  const y = rect.y + (rect.height / 2) + txt.fontBoundingBoxAscent / 2 - 1;
  this.offsetFillText(value, x, y);
};
