<template>
  <div>
    <RecipientsList
      v-if="copyLoadingComplete && SentinelNotifierCopy.length > 0"
      :UserNotifierCopy="SentinelNotifierCopy"
      @formSubmit="formSubmit"
      @notificationsActiveSet="notificationsActiveSet"
      @deleteNotifier="deleteNotifier"
    />
  </div>
</template>

<script>
import RecipientsList from '@/components/Notifications/RecipientsList.vue';
import store from '@/store';

export default {
  name: 'nt-recipients',
  components: {
    RecipientsList
  },
  data () {
    return {
      copyLoadingComplete: false,
      /** @type NotifierSettings[] */
      SentinelNotifierCopy: []
    };
  },
  mounted () {
    // Notification_root also needs to be watched
    if (this.$store.getters.selectedFarm) {
      this.updateSentinelCopy();
    }
  },
  methods: {
    deleteNotifier (notification) {
      store.dispatch('deleteSentinelAlarm', notification).then(results => {
        this.$finishResult.handleResultsAsync(results).then(response => {
          if (response === 'fail') {
            this.updateSentinelCopy();
          } else if (response === 'retry') {
            this.deleteNotifier(notification);
          }
        });
      });
    },
    firstCharToUpper (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formSubmit (notifier) {
      let dispatch;
      if (notifier.id === 'new') {
        dispatch = 'createSentinelAlarm';
      } else {
        dispatch = 'updateSentinelAlarm';
      }

      store.dispatch(dispatch, notifier).then(results => {
        this.$finishResult.handleResultsAsync(results).then(response => {
          if (response === 'fail') {
            this.updateSentinelCopy();
          } else if (response === 'retry') {
            this.formSubmit(notifier);
          }
        });
      });
    },
    notificationsActiveSet (toggleSnooze, index) {
      this.SentinelNotifierCopy[index].active = toggleSnooze ? 1 : 0;
      store.dispatch('updateSentinelAlarm', this.SentinelNotifierCopy[index]).then(results => {
        this.$finishResult.handleResultsAsync(results).then(response => {
          if (response === 'fail') {
            this.updateSentinelCopy();
          } else if (response === 'retry') {
            this.notificationsActiveSet(toggleSnooze, index);
          }
        });
      });
    },
    updateSentinelCopy () {
      this.copyLoadingComplete = false;
      this.SentinelNotifierCopy =
        JSON.parse(
          JSON.stringify(
            this.$store.state.sentinelAlerts
          ));
      this.copyLoadingComplete = true;
    }
  },
  watch: {
    '$store.state.selectedFarm': {
      handler: function () {
        if (this.$store.getters.selectedFarm) {
          this.updateSentinelCopy();
        }
      },
      deep: true
    },
    '$store.state.sentinelAlerts': {
      handler: function () {
        if (!this.$store.state.editMode) {
          this.updateSentinelCopy();
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped>

.custom-offset {
  top: 44px;
  right: 48px;
}

</style>
