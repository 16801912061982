<template>
  <div id="chart-component">
    <canvas ref="canvas" :width="width" :height="height" :style="style"></canvas>
  </div>
</template>

<script>

import './../lib/typedef.js'; /* intellisense helper */
import { Chart } from './../lib/do-chart';
import MonitorUtils from './../lib/monitor-utils';

export default {
  props: {
    /** @type pondDataItem|vue-prop */
    pond_data: {
      type: Object,
      required: true
    },
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    scale: {
      type: Number
    },
    backColor: {
      type: String
    }
  },
  data () {
    return {
      O2Chart: null,
      update: 0
    };
  },
  mounted () {
    this.O2Chart = new Chart(this.$refs.canvas);
    this.updateChart();
  },
  methods: {
    updateChart: function () {
      if (this.pond_data && this.pond_data.Setpoints) {
        this.O2Chart.set_aerators(
          this.pond_data.aerator_names,
          MonitorUtils.EnabledAerators(this.pond_data.Setpoints)
        );
        this.O2Chart.set_buoys(
          this.pond_data.buoy_names,
          this.pond_data.MainSets.Buoy2Off === 0);
        if (this.$store.state.userSettings.temperatureSetting === 'Celsius') {
          this.O2Chart.set_options({
            Celsius: true,
            Co2Bottom: 0,
            Co2Top: 20,
            DoBottom: 0,
            DoTop: 20,
            TempTop: 35,
            TempBottom: 10,
            ChartHours: 36,
            backColor: this.backColor
          });
        } else {
          this.O2Chart.set_options({
            Celsius: false,
            Co2Bottom: 0,
            Co2Top: 20,
            DoBottom: 0,
            DoTop: 20,
            TempTop: 80,
            TempBottom: 50,
            ChartHours: 36,
            backColor: this.backColor
          });
        }
        this.O2Chart.set_data(this.pond_data.ChartLogs);
      }
    }
  },
  watch: {
    pond_data: function (newData, oldData) {
      console.log('watch', newData, oldData);
      this.updateChart();
    }
  },
  beforeUpdate () {
    console.log('beforeUpdate');
    this.updateChart();
  },
  computed: {
    style () {
      return `width: ${Math.floor(this.width * this.scale)}px`;
    },
    chartData () {
      console.log('computed');
      if (!!this.pond_data && !!this.pond_data.ChartLogs) {
        return this.pond_data.ChartLogs;
      }
      return [];
    },
    info () {
      if (!!this.pond_data && !!this.pond_data.ChartLogs && !!this.pond_data.ChartLogsModified) {
        return this.pond_data.ChartLogsModified;
      }
      return '';
    }
  }
};

</script>

<style scoped>

  #chart-component {
    /* padding-top: 10px; */
    text-align: center;
  }

  .hidden {
    display: none;
  }
</style>
