<template>
  <div>
    <!-- Core page -->
    <div class="full-width">
      <div class="q-pa-md q-mb-xl row">
        <div
          v-for="(device, index) in aerlinkDevices"
          :key="index"
          class="q-pa-sm col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <q-card dark square bordered class="q-pa-lg" style="min-width: 280px; max-width: 500px;">
            <div class="q-pa-">
              <div class="row items-center">
                <q-toolbar v-if="!device.in_service" class="bg-negative text-black shadow-2">
                  <q-toolbar-title class="row justify-center items-center">
                    <q-icon name="signal_cellular_no_sim" size="md" class="q-mr-sm"/>
                    {{ device.name }}
                  </q-toolbar-title>
                </q-toolbar>
                <q-toolbar v-if="device.in_service" class="bg-grey-6 text-black shadow-2">
                  <q-toolbar-title class="row justify-center items-center">
                    <q-icon name="signal_cellular_alt"
                            size="md" class="q-mr-sm"/>
                    {{ device.name }}
                  </q-toolbar-title>
                </q-toolbar>
              </div>
              <q-list dark bordered class="full-width">
                <q-item class="q-ml-sm">
                  <div class="row justify-center items-center">
                    <div class="q-ml-md">
                      Device GUID
                      <div class="text-caption text-grey-7">
                        {{ device.guid }}
                      </div>
                    </div>
                  </div>
                </q-item>
                <q-separator dark/>
                <q-item class="q-ml-sm">
                  <div class="row justify-center items-center">
                    <q-icon name="settings" size="md" class="q-mr-sm" color="teal"/>
                  </div>
                  <div class="full-width text-right">
                    <q-btn outline
                           color="primary"
                           size="sm"
                           style="margin-right:5px"
                           @click="RevokeToken(device)">Revoke
                    </q-btn>
                    <q-btn outline
                           size="sm"
                           color="primary" @click="GenerateToken(device)">Generate token
                    </q-btn>
                  </div>
                </q-item>
                <q-separator dark/>
                <q-item class="q-my-xs">
                  <div class="row justify-center items-center full-width">
                    <q-space/>
                    <div>
                      <q-btn
                        icon="edit"
                        class="q-mr-sm"
                        outline
                        padding="xs sm"
                        color="#e6e6e5"
                        text-color="primary"
                        size="sm"
                        @click="editDevice(device)">
                      </q-btn>
                      <q-btn
                        icon="delete"
                        outline
                        color="#e6e6e5"
                        text-color="negative"
                        padding="xs sm"
                        size="sm"
                        @click="deleteDevice(device)">
                      </q-btn>
                    </div>
                  </div>
                </q-item>
              </q-list>
            </div>
          </q-card>
        </div>
        <q-page-sticky position="bottom-right" :offset="[28, 28]">
          <q-btn fab icon="add" color="accent" @click="addDevice"/>
        </q-page-sticky>
      </div>
    </div>
    <!-- edit modal -->
    <q-dialog v-model="showEditModal" persistent class="bg-dark text-white">
      <q-card
        dark
        square
        bordered
        class="q-pa-lg bg-grey-10"
      >
        <q-card-section class="bg-grey-10 text-white">
          <q-form @submit="onSubmit" class="q-gutter-sm bg-grey-10 text-white">
            <q-toolbar class="bg-grey-9 text-white shadow-2">
              <q-toolbar-title class="row justify-center items-center">
                <q-icon name="signal_cellular_alt" size="sm" class="q-mr-sm" />
                Gateway setup
              </q-toolbar-title>
            </q-toolbar>
            <div class="row">
              <div class="col">
                <q-checkbox dark v-model="editDeviceCopy.in_service" label="In service" color="primary"/>
              </div>
            </div>
            <q-input
              dark
              dense
              filled
              v-model="editDeviceCopy.name"
              label="Name"
              maxlength="32"
              outlined
              square
            />
            <div align="right">
              <div class="row full-width">
                <div class="col spaced">
                  <q-btn class="full-width" label="Cancel" color="secondary" @click="onCancel"/>
                </div>
                <div class="col spaced">
                  <q-btn class="full-width" label="Submit" type="submit" color="primary"/>
                </div>
              </div>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
    <!-- end edit modal -->
  </div>
</template>

<script>
import store from '@/store';
import { copyToClipboard } from 'quasar';
export default {
  name: 'AerlinkDevices',
  data () {
    return {
      showEditModal: false,
      editDeviceCopy: {}
    };
  },
  computed: {
    aerlinkDevices () {
      return this.$store.state.aerlinkDevices;
    }
  },
  methods: {
    GenerateToken (device) {
      store.dispatch('generateAerlinkJWT', device).then(results => {
        this.$finishResult.handleResultsAsync(results).then(response => {
          if (response === 'fail') {
            // No action needed
          } else if (response === 'retry') {
            this.GenerateToken(device);
          } else {
            copyToClipboard(results.data.jwt)
              .then(() => {
                this.$q.notify({
                  message: 'Token copied to clipboard',
                  color: 'primary'
                });
              })
              .catch(() => {
                this.$q.notify({
                  message: 'Token copy error',
                  color: 'negative'
                });
              });
            // display result somehow
          }
        });
      });
    },
    RevokeToken (device) {
      // TODO different revoke modal
      this.$deleteResult.getResultsAsync(
        'Revoke device',
        `Delete ${device.name} authentication ?`).then(result => {
        if (result === 'delete') {
          this.dispatchRevoke(device);
        }
      });
    },
    dispatchRevoke (device) {
      store.dispatch('revokeAerlinkGuid', device).then(results => {
        this.$finishResult.handleResultsAsync(results).then(response => {
          if (response === 'fail') {
            // No action needed
          } else if (response === 'retry') {
            this.dispatchRevoke(device);
          } else {
            this.$q.notify({
              message: 'Authorization revoked',
              color: 'primary'
            });
          }
        });
      });
    },
    onCancel () {
      this.showEditModal = false;
    },
    onSubmit () {
      let dispatch;
      if (this.editDeviceCopy.id === 'new') {
        dispatch = 'createAerlinkDevice';
      } else {
        dispatch = 'updateAerlinkDevice';
      }
      store.dispatch(dispatch, this.editDeviceCopy).then(results => {
        this.$finishResult.handleResultsAsync(results).then(response => {
          if (response === 'retry') {
            this.onSubmit();
          } else {
            this.showEditModal = false;
          }
        });
      });
    },
    addDevice () {
      this.editDeviceCopy = {
        id: 'new',
        name: '',
        in_service: true
      };
      this.showEditModal = true;
    },
    editDevice (device) {
      this.editDeviceCopy = JSON.parse(JSON.stringify(device));
      this.showEditModal = true;
    },
    deleteDevice (device) {
      this.$deleteResult.getResultsAsync(
        'Delete Device',
        `Delete ${device.name}?`).then(result => {
        if (result === 'delete') {
          this.dispatchDelete(device);
        }
      });
    },
    dispatchDelete (device) {
      // Confirm dialog
      store.dispatch('deleteAerlinkDevice', device).then(results => {
        this.$finishResult.handleResultsAsync(results).then(response => {
          if (response === 'fail') {
            // No action needed
          } else if (response === 'retry') {
            this.dispatchDelete(device);
          }
        });
      });
    }
  }
};
</script>

<style scoped>
.spaced {
  margin-left: 2px;
  margin-right: 2px;
}
</style>
