<template>
  <router-link :to="'/pond/' + index" v-slot="{ href, navigate }">
    <div class="pond-data-container"
         :href="href"
         @click="navigate"
         variant="primary"
         :style="dynamicClass.outerContainer"
         :class="dynamicClass.pondDataContainer">
      <div class="upper-message"
           :class="dynamicClass.upperMessage">
        {{ upperMessage }}
      </div>
      <div class="triangle-left"
           :class="dynamicClass.triangleLeft"
           v-if="cornerAlert">
      </div>
      <div class="triangle-right"
           :class="dynamicClass.triangleRight"
           v-if="cornerAlarm">
      </div>
      <div class="triangle-right"
           :class="dynamicClass.triangleRight"
           v-if="cornerAlert">
      </div>
      <div class="pond-data-inside"
           :class="dynamicClass.pondDataInside">
        <div class="pond-name"
             :class="dynamicClass.pondName">
          {{ pondInfo.name }}
        </div>
        <div class="pond-do"
             variant="primary"
             :class="dynamicClass.pondDO">
          {{ pondInfo.water.do[0].toFixed(1) }}
        </div>
        <div class="pond-temperature"
             variant="primary"
             :class="dynamicClass.pondTemp"
             v-html="buoy2.display">
        </div>
        <div class="shaded-boxes-parent">
          <div class="shaded-boxes"
               v-for="(x, index) in 6" :key="index"
               :class="[dynamicClass.shadedBoxes, aeratorData.aeratorClass[index]]">
            {{ aeratorData.aeratorMessage[index] }}
          </div>
        </div>
      </div>
      <div class="lower-message"
           :class="dynamicClass.lowerMessage">
        {{ lowerMessage }}
      </div>
    </div>
  </router-link>
</template>

<script>

import './../lib/typedef.js'; /* intellisense helper */
import { AlarmDefs, alarmNames, alarmPriorities, AlarmGroup } from 'alarm-defs';
import ColorDefs from './../lib/ColorDefs.js';

export default {
  props: {
    /** @type pondDataItem|vue-prop */
    pondInfo: {
      type: Object,
      required: true
    },
    flash: {
      type: Boolean,
      required: true
    },
    aerator: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  methods: {
    temperatureFormat (temp) {
      if (this.$store.state.userSettings.temperatureSetting !== 'Celsius') {
        return (temp * 9 / 5 + 32).toFixed(1) + '&deg;';
      } else {
        return temp.toFixed(1) + '&deg;';
      }
    }
  },
  computed: {
    buoy2Enabled () {
      return this.pondInfo.MainSets.Buoy2Off === 0;
    },
    upperMessage () {
      const PowerIsOff = AlarmDefs().AlarmBitIndex(alarmNames.Power_is_off);
      const PowerSupply = AlarmDefs().AlarmBitIndex(alarmNames.PowerSupply);
      const LastAeratorDown = AlarmDefs().AlarmBitIndex(alarmNames.LastAeratorDown);
      if (this.pondInfo.alarms.alarms.findIndex((alarm) => PowerIsOff === alarm) !== -1) {
        return 'POWER OFF';
      } else if (this.pondInfo.alarms.alarms.findIndex((alarm) => PowerSupply === alarm) !== -1) {
        return 'POWER SUPPLY';
      } else if (this.pondInfo.MainSets.SeineMode ||
        this.pondInfo.alarms.alarms.findIndex((alarm) => LastAeratorDown === alarm) !== -1) {
        return 'NO AERATION';
      } else {
        if (this.pondInfo.water.ddo_active && this.pondInfo.water.do_boost > 0) {
          return 'Boost ' + this.pondInfo.water.do_boost.toFixed(1);
        } else {
          return '';
        }
      }
    },
    lowerMessage () {
      if (!this.pondInfo.in_service) {
        return 'Out of service';
      } else if (this.pondInfo.maint_mode) {
        return 'Service mode';
      } else if (this.$store.getters.getCriticalAlarmFiltered(this.index)) {
        if (this.pondInfo.MainSets.SeineMode) {
          return 'Alarm/Seine';
        } else {
          if (this.pondInfo.other.generator_on) {
            return 'Alarm/Generator';
          } else {
            return 'Critical Alarm';
          }
        }
      } else if (this.$store.getters.getAlarmFiltered(this.index)) {
        if (this.pondInfo.MainSets.SeineMode) {
          return 'Alarm/Seine';
        } else {
          if (this.pondInfo.other.generator_on) {
            return 'Alarm/Generator';
          } else {
            return 'Alarm';
          }
        }
      } else if (this.pondInfo.MainSets.SeineMode) {
        return 'Seine mode';
      } else if (this.pondInfo.other.generator_on) {
        if (this.$store.getters.getAlertFiltered(this.index)) {
          return 'Alert';
        } else {
          return 'Alert/Generator';
        }
      } else if (this.$store.getters.getAlertFiltered(this.index)) {
        return 'Alert';
      }
      return '';
    },
    ringFlash () {
      const highPriority = AlarmDefs().findHighPriority(
        new AlarmGroup(
          this.pondInfo.alarms.alarms,
          this.pondInfo.alarms.alerts,
          this.pondInfo.alarms.server)
      );
      if (this.pondInfo.maint_mode) {
        return true;
      } else if (highPriority === alarmPriorities.critical_super_L7) {
        return true;
      } else if (highPriority === alarmPriorities.critical_flash_L6) {
        return true;
      } else if (this.pondInfo.MainSets.SeineMode) {
        return true;
      } else if (highPriority === alarmPriorities.critical_alarm_L4) {
        const unAcknowledged = this.$store.getters.getUnAcknowledged(this.index);
        return AlarmDefs().priorityFound(
          new AlarmGroup(
            unAcknowledged.alarms,
            unAcknowledged.alerts,
            unAcknowledged.server),
          alarmPriorities.critical_alarm_L4);
      } else if (highPriority === alarmPriorities.level_1_alert_L3) {
        const unAcknowledged = this.$store.getters.getUnAcknowledged(this.index);
        return AlarmDefs().priorityFound(
          new AlarmGroup(
            unAcknowledged.alarms,
            unAcknowledged.alerts,
            unAcknowledged.server),
          alarmPriorities.level_1_alert_L3);
      }
      return false;
    },
    ringColor () {
      const highPriority = AlarmDefs().findHighPriority(
        new AlarmGroup(
          this.pondInfo.alarms.alarms,
          this.pondInfo.alarms.alerts,
          this.pondInfo.alarms.server)
      );
      if (this.pondInfo.maint_mode) {
        return {
          on: ColorDefs.Maintenance,
          off: ColorDefs.LowFlash(ColorDefs.Maintenance)
        };
      } else if (highPriority === alarmPriorities.critical_super_L7) {
        return {
          on: ColorDefs.Red,
          off: ColorDefs.Yellow
        };
      } else if (highPriority === alarmPriorities.critical_flash_L6) {
        return {
          on: ColorDefs.Red,
          off: ColorDefs.LowFlash(ColorDefs.Red)
        };
      } else if (this.pondInfo.MainSets.SeineMode) {
        return {
          on: ColorDefs.Seine,
          off: ColorDefs.LowFlash(ColorDefs.Seine)
        };
      } else if (highPriority === alarmPriorities.critical_alarm_L4) {
        return {
          on: ColorDefs.Red,
          off: ColorDefs.Grey
        };
      } else if (highPriority === alarmPriorities.level_1_alert_L3) {
        return {
          on: ColorDefs.Orange,
          off: ColorDefs.Grey
        };
      } else if (this.pondInfo.other.generator_on) {
        return {
          on: ColorDefs.Generator,
          off: ColorDefs.Generator
        };
      } else if (highPriority === alarmPriorities.level_2_alert_L1) {
        return {
          on: ColorDefs.Yellow,
          off: ColorDefs.Yellow
        };
      } else if (this.pondInfo.harvest_buoy) {
        return {
          on: ColorDefs.Seine,
          off: ColorDefs.Seine
        };
      } else {
        return {
          on: ColorDefs.Green,
          off: ColorDefs.Green
        };
      }
    },
    cornerAlarm () {
      return AlarmDefs().priorityFound(
        new AlarmGroup(
          this.pondInfo.alarms.alarms,
          this.pondInfo.alarms.alerts,
          this.pondInfo.alarms.server),
        alarmPriorities.cornerRed_L0);
    },
    cornerAlert () {
      return AlarmDefs().priorityFound(
        new AlarmGroup(
          this.pondInfo.alarms.alarms,
          this.pondInfo.alarms.alerts,
          this.pondInfo.alarms.server),
        alarmPriorities.cornerYellow_L0);
    },
    buoy2 () {
      const buoy2 = {};

      if (this.buoy2Enabled === false) {
        buoy2.display = this.temperatureFormat(this.pondInfo.water.temp[0]);
        buoy2.hover = 'Temp ' + this.temperatureFormat(this.pondInfo.water.temp[0]);
        return buoy2;
      }

      buoy2.display = this.pondInfo.water.do[1].toFixed(1);
      buoy2.hover = 'Temp ' + this.pondInfo.water.temp[1].toFixed(1) + ' &deg;';
      return buoy2;
    },
    aeratorToggle () {
      return this.$store.getters.getAeratorToggle(this.index);
    },
    aeratorData () {
      const messageArr = [];
      const classArr = [];

      let relayArr = [];
      const colorArr = [];
      let ampsArr = [];

      if (this.aerator.secondSix === true && this.aeratorToggle === true) {
        // TODO colorize by alarm
        for (let i = 6; i < 12; i++) {
          if (this.pondInfo.amps.relay_state[i] > 0) {
            colorArr.push('green');
          } else {
            colorArr.push('gray');
          }
        }
        relayArr = this.pondInfo.amps.relay_state.slice(6);
        // colorArr = this.pondInfo.amps.aerator_color.slice(6);
        ampsArr = this.pondInfo.amps.aerator_amps.slice(6);
      } else {
        // TODO colorize by alarm
        for (let i = 0; i < 6; i++) {
          if (this.pondInfo.amps.relay_state[i] > 0) {
            colorArr.push('green');
          } else {
            colorArr.push('gray');
          }
        }
        relayArr = this.pondInfo.amps.relay_state.slice(0, 6);
        // colorArr = this.pondInfo.amps.aerator_color.slice(0, 6);
        ampsArr = this.pondInfo.amps.aerator_amps.slice(0, 6);
      }

      for (let i = 0; i < 6; i++) {
        let message = 'off';

        if (relayArr[i] === 1) {
          message = ampsArr[i].toFixed(1);
        }

        let colorClass = 'aerator-color-red';

        if (colorArr[i] === 'green') {
          colorClass = 'aerator-color-green';
        } else if (colorArr[i] === 'gray') {
          colorClass = 'aerator-color-gray';
          message = '';
        }

        messageArr.push(message);

        classArr.push(colorClass);
      }

      const aeratorInfo = {
        aeratorMessage: messageArr,
        aeratorClass: classArr
      };

      return aeratorInfo;
    },
    dynamicClass () {
      const selectedSize = this.$store.state.userSettings.pondIndicatorSize;
      const dynamicClass = {};

      if (selectedSize === 'small') {
        dynamicClass.pondDataContainer = 'pond-data-container-sm';
        dynamicClass.pondDataInside = 'pond-data-inside-sm';
        dynamicClass.triangleLeft = 'triangle-left-sm';
        dynamicClass.triangleRight = 'triangle-right-sm';
        dynamicClass.upperMessage = 'upper-message-sm';
        dynamicClass.lowerMessage = 'lower-message-sm';
        dynamicClass.pondName = 'pond-name-sm';
        dynamicClass.pondDO = 'pond-do-sm';
        dynamicClass.shadedBoxes = 'shaded-boxes-sm';
        dynamicClass.pondTemp = 'pond-temperature-sm';
        if (this.pondInfo.MainSets.Buoy2Off === 0) {
          dynamicClass.pondTemp = 'pond-temperature-alt-sm';
        }
      } else if (selectedSize === 'medium') {
        dynamicClass.pondDataContainer = 'pond-data-container-md';
        dynamicClass.pondDataInside = 'pond-data-inside-md';
        dynamicClass.triangleLeft = 'triangle-left-md';
        dynamicClass.triangleRight = 'triangle-right-md';
        dynamicClass.upperMessage = 'upper-message-md';
        dynamicClass.lowerMessage = 'lower-message-md';
        dynamicClass.pondName = 'pond-name-md';
        dynamicClass.pondDO = 'pond-do-md';
        dynamicClass.shadedBoxes = 'shaded-boxes-md';
        dynamicClass.pondTemp = 'pond-temperature-md';
        if (this.pondInfo.MainSets.Buoy2Off === 0) {
          dynamicClass.pondTemp = 'pond-temperature-alt-md';
        }
      } else if (selectedSize === 'large') {
        dynamicClass.pondDataContainer = 'pond-data-container-lg';
        dynamicClass.pondDataInside = 'pond-data-inside-lg';
        dynamicClass.triangleLeft = 'triangle-left-lg';
        dynamicClass.triangleRight = 'triangle-right-lg';
        dynamicClass.upperMessage = 'upper-message-lg';
        dynamicClass.lowerMessage = 'lower-message-lg';
        dynamicClass.pondName = 'pond-name-lg';
        dynamicClass.pondDO = 'pond-do-lg';
        dynamicClass.shadedBoxes = 'shaded-boxes-lg';
        dynamicClass.pondTemp = 'pond-temperature-lg';
        if (this.pondInfo.MainSets.Buoy2Off === 0) {
          dynamicClass.pondTemp = 'pond-temperature-alt-lg';
        }
      }

      dynamicClass.outerContainer = {};

      if (this.ringFlash) {
        dynamicClass.outerContainer.backgroundColor =
          this.flash ? this.ringColor.on : this.ringColor.off;
      } else {
        dynamicClass.outerContainer.backgroundColor = this.ringColor.on;
      }

      return dynamicClass;
    }
  }
};
</script>

<style scoped>
  .aerator-color-red {
    background-image: linear-gradient(#c40100, #f36b54);
  }

  .aerator-color-green {
    background-image: linear-gradient(#068a06, #15eda5);
  }

  .aerator-color-gray {
    background-image: linear-gradient(#868686, #cdcdcd);
  }

  .lower-message {
    grid-column: 22 / 81;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    color: black;
  }

  .lower-message-sm {
    font-size: 12px;
    grid-row: 91 / 101;
  }

  .lower-message-md {
    font-size: 14px;
    grid-row: 92 / 101;
  }

  .lower-message-lg {
    font-size: 17px;
    grid-row: 93 / 101;
  }

  .pond-data-container {
    display: grid;
    grid-template-columns: repeat(100, minmax(0, 1fr));
    grid-template-rows: repeat(100, minmax(0, 1fr));
    background-color: #008100;
    border: 2px solid black;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;
  }

  .pond-data-container-sm {
    width: 150px;
    height: 150px;
    margin: 15px 15px 35px 15px;
  }

  .pond-data-container-md {
    width: 200px;
    height: 200px;
    margin: 15px 25px 35px 25px;
  }

  .pond-data-container-lg {
    width: 300px;
    height: 300px;
    margin: 15px 35px 35px 35px;
  }

  .pond-data-inside {
    display: grid;
    grid-template-columns: repeat(20, minmax(0, 1fr));
    grid-template-rows: repeat(20, minmax(0, 1fr));
    background-color: #d4d4d4;
    border: 2px solid black;
    border-radius: 3px;
  }

  .pond-data-inside-sm {
    grid-column: 11 / 91;
    grid-row: 11 / 91;
  }

  .pond-data-inside-md {
    grid-column: 10 / 92;
    grid-row: 10 / 92;
  }

  .pond-data-inside-lg {
    grid-column: 9 / 93;
    grid-row: 9 / 93;
  }

  .pond-do {
    grid-column: 2 / 14;
    grid-row: 5 / 11;
    text-align: center;
    font-weight: bold;
    color: black;
  }

  .pond-do-sm {
    font-size: 29px;
    padding-top: 2px;
  }

  .pond-do-md {
    font-size: 38px;
    padding-top: 3px;
  }

  .pond-do-lg {
    font-size: 44px;
    padding-top: 7px;
  }

  .pond-name {
    grid-column: 2 / 20;
    grid-row: 1 / 3;
    text-align: center;
    font-weight: bold;
    color: black;
  }

  .pond-name-sm {
    font-size: 13px;
    padding-top: 1px;
  }

  .pond-name-md {
    font-size: 17px;
    padding-top: 3px;
  }

  .pond-name-lg {
    font-size: 20px;
    padding-top: 5px;
  }

  .pond-temperature {
    grid-column: 2 / 14;
    grid-row: 12 / 17;
    text-align: center;
  }

  .pond-temperature-sm {
    font-size: 23px;
    color: black;
  }

  .pond-temperature-md {
    font-size: 33px;
    color: black;
  }

  .pond-temperature-lg {
    font-size: 38px;
    color: black;
  }

  .pond-temperature-alt-sm {
    font-size: 29px;
    font-weight: bold;
    color: blue;
  }

  .pond-temperature-alt-md {
    font-size: 38px;
    font-weight: bold;
    color: blue;
  }

  .pond-temperature-alt-lg {
    font-size: 44px;
    font-weight: bold;
    color: blue;
  }

  .shaded-boxes {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
  }

  .shaded-boxes-sm {
    font-size: 12px;
    width: 29px;
    height: 13px;
    border-radius: 3px;
    margin: 2px;
    padding: 0px 0 0px 0;
  }

  .shaded-boxes-md {
    font-size: 14px;
    width: 37px;
    height: 16px;
    border-radius: 4px;
    margin: 4px;
    padding: 2px 0 0px 0;
  }

  .shaded-boxes-lg {
    font-size: 22px;
    width: 58px;
    height: 25px;
    border-radius: 5px;
    margin: 6px;
    padding: 0px 0 0px 0;
  }

  .shaded-boxes-parent {
    grid-column: 14 / 19;
    grid-row: 4 / 19;
  }

  .triangle-left {
    grid-column: 1 / 25;
    grid-row: 1 / 25;
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    transform: rotate(90deg);
  }

  .triangle-left-sm {
    border-right: 32px solid transparent;
    border-bottom: 32px solid red;
  }

  .triangle-left-md {
    border-right: 38px solid transparent;
    border-bottom: 38px solid red;
  }

  .triangle-left-lg {
    border-right: 50px solid transparent;
    border-bottom: 50px solid red;
  }

  .triangle-right {
    grid-column: 76 / 101;
    grid-row: 1 / 25;
    width: 0;
    height: 0;
    margin-right: -1px;
    justify-self: end;
    border-left: 0px solid transparent;
    transform: rotate(180deg);
  }

  .triangle-right-sm {
    border-right: 32px solid transparent;
    border-bottom: 32px solid yellow;
  }

  .triangle-right-md {
    border-right: 38px solid transparent;
    border-bottom: 38px solid yellow;
  }

  .triangle-right-lg {
    border-right: 50px solid transparent;
    border-bottom: 50px solid yellow;
  }

  .upper-message {
    grid-column: 22 / 81;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    color: black;
    font-size: 14px;
    grid-row: 1 / 10;
  }

  .upper-message-sm {
    font-size: 11px;
    grid-row: 1 / 11;
  }

  .upper-message-md {
    font-size: 14px;
    grid-row: 1 / 10;
  }

  .upper-message-lg {
    font-size: 17px;
    grid-row: 1 / 9;
  }
</style>
