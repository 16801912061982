/*
  Notes: we may want to follow the structure as laid out in
  real-world-vue-error-handling-finish at some point with multiple files
  https://github.com/Code-Pop/real-world-vue/releases/tag/error-handling-finish
 */

import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import CookieUtils from './../lib/cookie-utils';
import PondStore from './pond.js';
import networkPlug from './plugins/network';
import connector from '@/services/mqtt/connector';
import AuthUtils from '@/lib/auth-utils';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showLoadingCircle: true,
    OnLine: false,
    editMode: false,
    testValue: '0',
    userSettings: {
      pondIndicatorSize: 'small',
      temperatureSetting: 'Fahrenheit'
    },
    /** @type FarmSettings */
    farm: {},
    userFarmAlerts: [],
    otherFarmAlerts: [],
    farmIsLoaded: false,
    farmList: [],
    userList: [],
    aerlinkDevices: [],
    sentinelAlerts: [],
    user: {
      aerware_level: 'none',
      display_name: '',
      /** @type FarmSettings[] */
      farms: [],
      is_user_logged_in: false,
      user_email: '',
      user_id: 0,
      user_name: ''
    },
    appVersion: process.env.VUE_APP_VERSION,
    newVersion: process.env.VUE_APP_VERSION,
    selectedFarm: {
      /** @type number */
      farm_id: -1,
      /** @type number | null */
      location_id: null
    }
  },
  mutations: {
    SET_ONLINE (state, payload) {
      state.OnLine = payload.OnLine;
    },
    SET_FARM_USER_LIST (state, value) {
      state.userList = value.users;
    },
    SET_USER_FARM_LIST (state, payload) {
      state.farmList = payload.farms;
      if (state.farmList && state.farmList.length > 0) {
        const storage = sessionStorage.getItem('savedFarm');
        const cached = storage ? JSON.parse(storage) : null;
        if (cached === null) {
          state.selectedFarm.farm_id = state.farmList[0].id;
          state.selectedFarm.location_id = state.farmList[0].location_id;
        } else {
          if (state.farmList.find(
            farm => farm.id === cached.farm_id &&
              farm.location_id === cached.location_id)) {
            state.selectedFarm.farm_id = cached.farm_id;
            state.selectedFarm.location_id = cached.location_id;
          } else {
            state.selectedFarm.farm_id = state.user.farms[0].id;
            state.selectedFarm.location_id = state.user.farms[0].location_id;
          }
        }
      }
    },
    SET_USER_FARM (state, payload) {
      state.farm = payload.farm;
      // TODO Not really right, there is a list of needed items
      state.farmIsLoaded = true;
    },
    /* SET_USER_FARM_ALERTS (state, payload) {
      state.userFarmAlerts = payload.user_alerts;
    }, */
    SET_USER_FARM_ALERT (state, payload) {
      if (payload.userId === state.user.user_id) {
        const index = state.userFarmAlerts.findIndex(element => element.id === payload.user_alert.id);
        if (index !== -1) {
          Vue.set(state.userFarmAlerts, index, payload.user_alert);
        } else {
          state.userFarmAlerts.push(payload.user_alert);
        }
      } else {
        const index = state.otherFarmAlerts.findIndex(element => element.id === payload.user_alert.id);
        if (index !== -1) {
          Vue.set(state.otherFarmAlerts, index, payload.user_alert);
        } else {
          state.otherFarmAlerts.push(payload.user_alert);
        }
      }
    },
    DELETE_USER_FARM_ALERT (state, payload) {
      if (payload.userId === state.user.user_id) {
        const index = state.userFarmAlerts.findIndex(element => element.id === payload.alertId);
        if (index !== -1) {
          Vue.delete(state.userFarmAlerts, index);
        }
      } else {
        const index = state.otherFarmAlerts.findIndex(element => element.id === payload.alertId);
        if (index !== -1) {
          Vue.delete(state.otherFarmAlerts, index);
        }
      }
    },
    SET_SENTINEL_FARM_ALERT (state, payload) {
      const index = state.sentinelAlerts.findIndex(element => element.id === payload.sentinel_alert.id);
      if (index !== -1) {
        Vue.set(state.sentinelAlerts, index, payload.sentinel_alert);
      } else {
        state.sentinelAlerts.push(payload.sentinel_alert);
      }
    },
    DELETE_SENTINEL_FARM_ALERT (state, payload) {
      const index = state.sentinelAlerts.findIndex(element => element.id === payload.alertId);
      if (index !== -1) {
        Vue.delete(state.sentinelAlerts, index);
      }
    },
    SET_AERLINK_DEVICE (state, payload) {
      const index = state.aerlinkDevices.findIndex(element => element.id === payload.device.id);
      if (index !== -1) {
        Vue.set(state.aerlinkDevices, index, payload.device);
      } else {
        state.aerlinkDevices.push(payload.device);
      }
    },
    DELETE_AERLINK_DEVICE (state, payload) {
      const index = state.aerlinkDevices.findIndex(element => element.id === payload.deviceId);
      if (index !== -1) {
        Vue.delete(state.aerlinkDevices, index);
      }
    },
    SET_USER_FARM_UNLOADED (state) {
      state.farmIsLoaded = false;
      state.farm = {};
      state.userFarmAlerts = [];
      state.sentinelAlerts = [];
      state.otherFarmAlerts = [];
      state.aerlinkDevices = [];
      // TODO Delete ponds somewhere ?
    },
    SET_USER_FARM_LOADED (state) {
      state.farmIsLoaded = true;
    },
    SET_EDIT_MODE (state, mode) {
      state.editMode = mode;
    },
    SET_USER_DATA (state, userData) {
      if (userData === null) {
        state.user.aerware_level = 'none';
        state.user.is_user_logged_in = false;
        state.user.farms = [];
      } else {
        state.user = userData;
      }
      if (!localStorage.indicatorSize) {
        localStorage.indicatorSize = 'medium';
      } else {
        state.userSettings.pondIndicatorSize = localStorage.indicatorSize;
      }

      if (!localStorage.temperatureSetting) {
        localStorage.temperatureSetting = 'Fahrenheit';
      } else {
        state.userSettings.temperatureSetting = localStorage.temperatureSetting;
      }
      state.showLoadingCircle = false;
    },
    SET_POND_INDICATOR_SIZE (state, size) {
      state.userSettings.pondIndicatorSize = size;
      localStorage.indicatorSize = size;
    },
    SET_TEMPERATURE_SETTING (state, temperature) {
      state.userSettings.temperatureSetting = temperature;
      localStorage.temperatureSetting = temperature;
    },
    SET_API_VERSION (state, payload) {
      state.newVersion = payload.Version;
    },
    SET_SELECTED_FARM (state, values) {
      state.selectedFarm.farm_id = values.farm_id;
      state.selectedFarm.location_id = values.location_id;
      sessionStorage.setItem('savedFarm', JSON.stringify({
        farm_id: values.farm_id,
        location_id: values.location_id
      }));
    },
    SET_USER_ALARM_SETTINGS (state, updatedNotifiers) {
      const selectedFarmIndex = state.user.farms.findIndex((element) => {
        return element.location_id === state.selectedFarm.location_id &&
          element.id === state.selectedFarm.farm_id;
      });
      if (selectedFarmIndex === -1) {
        return;
      }
      for (let i = 0; i < state.user.farms[selectedFarmIndex].user_alerts.length; i++) {
        Vue.set(state.user.farms[selectedFarmIndex].user_alerts[i], 'alarms', updatedNotifiers[i].alarms);
      }
    }
  },
  actions: {
    subscribeFarmList ({ commit }, userId) {
      connector.subscribe(`farmList/${userId}/#`);
    },
    subscribeFarm ({ commit, getters }, selectedFarm) {
      const loc = selectedFarm.location_id || null;
      const user_id = getters.userId;
      const subs = [
        `farm/${selectedFarm.farm_id}/${loc}/ponds/#`
      ];
      if (AuthUtils.validateAuthLevel('installer', getters.userLevel)) {
        subs.push(`farm/${selectedFarm.farm_id}/${loc}/sentinel/#`);
        subs.push(`farm/${selectedFarm.farm_id}/all/aerlink/#`);
      }
      if (AuthUtils.validateAuthLevel('acct-manager', getters.userLevel)) {
        subs.push(`farm/${selectedFarm.farm_id}/all/userList/#`);
        subs.push(`farm/${selectedFarm.farm_id}/${loc}/users/#`);
      } else {
        subs.push(`farm/${selectedFarm.farm_id}/${loc}/users/${user_id}/#`);
      }
      connector.subscribe(subs).then(results => {
        commit('SET_USER_FARM_LOADED');
        console.log('subscribeFarm', results, subs);
      });
    },
    unSubscribeFarm ({ commit, getters }, selectedFarm) {
      const loc = selectedFarm.location_id || null;
      const user_id = getters.userId;
      const unSubs = [
        `farm/${selectedFarm.farm_id}/${loc}/ponds/#`,
        `farm/${selectedFarm.farm_id}/${loc}/users/${user_id}/#`
      ];
      if (AuthUtils.validateAuthLevel('installer', getters.userLevel)) {
        unSubs.push(`farm/${selectedFarm.farm_id}/${loc}/sentinel/#`);
        unSubs.push(`farm/${selectedFarm.farm_id}/all/aerlink/#`);
      }
      if (AuthUtils.validateAuthLevel('acct-manager', getters.userLevel)) {
        unSubs.push(`farm/${selectedFarm.farm_id}/all/userList/#`);
        unSubs.push(`farm/${selectedFarm.farm_id}/${loc}/users/#`);
      } else {
        unSubs.push(`farm/${selectedFarm.farm_id}/${loc}/users/${user_id}/#`);
      }
      connector.unSubscribe(unSubs).then(results => {
        console.log('unSubscribeFarm', results, unSubs);
      });
    },
    logout ({ commit }) {
      commit('SET_USER_DATA', null);
      return axios
        .post(process.env.VUE_APP_ACCOUNTS_LOGIN + '/api/logout', null)
        .then(() => {
          CookieUtils.redirectToAccounts();
        })
        .catch(() => {
          // Some issue with the server, we will delete our client side cookie
          CookieUtils.deleteLocalCookie();
          CookieUtils.redirectToAccounts();
        });
    },
    setApiVersion ({ commit }, version) {
      commit('SET_API_VERSION', version);
    },
    setSelectedFarm ({ commit, dispatch, getters }, values) {
      if (getters.farmIsLoaded) {
        // Unsubscribe from this farm
        commit('SET_USER_FARM_UNLOADED');
        commit('SET_POND_CLEAN_FARM');
        dispatch('unSubscribeFarm', getters.selectedFarmInfo);
      }
      commit('SET_SELECTED_FARM', values);
    },
    setPondIndicatorSize ({ commit }, size) {
      commit('SET_POND_INDICATOR_SIZE', size);
    },
    setTemperatureSetting ({ commit }, temperature) {
      commit('SET_TEMPERATURE_SETTING', temperature);
    },
    // User alarms
    async setUserAlarmSettings ({ commit, getters }, updatedNotifiers) {
      const loc = getters.selectedFarmInfo.location_id || null;
      return connector.publish(
        `farm/${getters.selectedFarmInfo.farm_id}/${loc}/users/${getters.userId}/alerts/`,
        JSON.stringify(updatedNotifiers));
    },
    async deleteUserAlarm ({ commit, getters }, deletedNotifier) {
      const loc = getters.selectedFarmInfo.location_id || null;
      return connector.publish(
        `farm/${getters.selectedFarmInfo.farm_id}/${loc}/users/${getters.userId}/alerts/${deletedNotifier.id}/delete/`,
        JSON.stringify(deletedNotifier));
    },
    async updateUserAlarm ({ commit, getters }, updatedNotifier) {
      const loc = getters.selectedFarmInfo.location_id || null;
      return connector.publish(
        `farm/${getters.selectedFarmInfo.farm_id}/${loc}/users/${getters.userId}/alerts/${updatedNotifier.id}/update/`,
        JSON.stringify(updatedNotifier));
    },
    async createUserAlarm ({ commit, getters }, newNotifier) {
      const loc = getters.selectedFarmInfo.location_id || null;
      newNotifier.class = 'User';
      newNotifier.farm_id = getters.selectedFarmInfo.farm_id;
      newNotifier.location_id = loc;
      newNotifier.user_id = getters.userId;
      return connector.publish(
        `farm/${getters.selectedFarmInfo.farm_id}/${loc}/users/${getters.userId}/alerts/new/create/`,
        JSON.stringify(newNotifier));
    },
    // Manager changes
    async managerDeleteUserAlarm ({ commit, getters }, deletedNotifier) {
      const loc = getters.selectedFarmInfo.location_id || null;
      return connector.publish(
        `farm/${getters.selectedFarmInfo.farm_id}/${loc}/users/${deletedNotifier.user_id}/alerts/${deletedNotifier.id}/delete/`,
        JSON.stringify(deletedNotifier));
    },
    async managerUpdateUserAlarm ({ commit, getters }, updatedNotifier) {
      const loc = getters.selectedFarmInfo.location_id || null;
      return connector.publish(
        `farm/${getters.selectedFarmInfo.farm_id}/${loc}/users/${updatedNotifier.user_id}/alerts/${updatedNotifier.id}/update/`,
        JSON.stringify(updatedNotifier));
    },
    async managerCreateUserAlarm ({ commit, getters }, newNotifier) {
      const loc = getters.selectedFarmInfo.location_id || null;
      newNotifier.class = 'User';
      newNotifier.farm_id = getters.selectedFarmInfo.farm_id;
      newNotifier.location_id = loc;
      return connector.publish(
        `farm/${getters.selectedFarmInfo.farm_id}/${loc}/users/${newNotifier.user_id}/alerts/new/create/`,
        JSON.stringify(newNotifier));
    },
    // Sentinel alarms
    async setSentinelAlarmSettings ({ commit, getters }, updatedNotifiers) {
      const loc = getters.selectedFarmInfo.location_id || null;
      return connector.publish(
        `farm/${getters.selectedFarmInfo.farm_id}/${loc}/sentinel/alerts/`,
        JSON.stringify(updatedNotifiers));
    },
    async deleteSentinelAlarm ({ commit, getters }, deletedNotifier) {
      const loc = getters.selectedFarmInfo.location_id || null;
      return connector.publish(
        `farm/${getters.selectedFarmInfo.farm_id}/${loc}/sentinel/alerts/${deletedNotifier.id}/delete/`,
        JSON.stringify(deletedNotifier));
    },
    async updateSentinelAlarm ({ commit, getters }, updatedNotifier) {
      const loc = getters.selectedFarmInfo.location_id || null;
      return connector.publish(
        `farm/${getters.selectedFarmInfo.farm_id}/${loc}/sentinel/alerts/${updatedNotifier.id}/update/`,
        JSON.stringify(updatedNotifier));
    },
    async createSentinelAlarm ({ commit, getters }, newNotifier) {
      const loc = getters.selectedFarmInfo.location_id || null;
      newNotifier.class = 'Sentinel';
      newNotifier.farm_id = getters.selectedFarmInfo.farm_id;
      newNotifier.location_id = loc;
      newNotifier.user_id = null;
      return connector.publish(
        `farm/${getters.selectedFarmInfo.farm_id}/${loc}/sentinel/alerts/new/create/`,
        JSON.stringify(newNotifier));
    },
    // Aerlink
    async generateAerlinkJWT ({ commit, getters }, device) {
      return connector.publish(
        `farm/${getters.selectedFarmInfo.farm_id}/all/aerlink/${device.id}/generate/`,
        JSON.stringify(device));
    },
    async revokeAerlinkGuid ({ commit, getters }, device) {
      return connector.publish(
        `farm/${getters.selectedFarmInfo.farm_id}/all/aerlink/${device.id}/revoke/`,
        JSON.stringify(device));
    },
    async deleteAerlinkDevice ({ commit, getters }, device) {
      return connector.publish(
        `farm/${getters.selectedFarmInfo.farm_id}/all/aerlink/${device.id}/delete/`,
        JSON.stringify(device));
    },
    async updateAerlinkDevice ({ commit, getters }, device) {
      return connector.publish(
        `farm/${getters.selectedFarmInfo.farm_id}/all/aerlink/${device.id}/update/`,
        JSON.stringify(device));
    },
    async createAerlinkDevice ({ commit, getters }, device) {
      device.farm_id = getters.selectedFarmInfo.farm_id;
      return connector.publish(
        `farm/${getters.selectedFarmInfo.farm_id}/all/aerlink/new/create/`,
        JSON.stringify(device));
    },
    setEditMode ({ commit }, mode) {
      commit('SET_EDIT_MODE', mode);
    }
  },
  getters: {
    farmIsLoaded (state) {
      return state.farmIsLoaded;
    },
    userLevel (state) {
      return state.user.aerware_level;
    },
    loggedIn (state) {
      return !!state.user;
    },
    newVersionAvailable (state) {
      return state.appVersion !== state.newVersion;
    },
    appVersion (state) {
      return state.appVersion;
    },
    userId (state) {
      return state.user.user_id;
    },
    getUserNameById: (state) => (id) => {
      const user = state.userList.find(user => user.id === id);
      return user ? user.name : '--';
    },
    getAerlinkNameById: (state) => (id) => {
      const device = state.aerlinkDevices.find(device => device.id === id);
      return device ? device.name : '--';
    },
    selectedFarmInfo (state) {
      return {
        farm_id: state.selectedFarm.farm_id,
        location_id: state.selectedFarm.location_id
      };
    },
    /** @returns FarmSettings */
    selectedFarm (state) {
      return state.farmList.find(
        farm => farm.id === state.selectedFarm.farm_id &&
          farm.location_id === state.selectedFarm.location_id);
    }
  },
  modules: {
    ponds: PondStore
  },
  plugins: [networkPlug()]
});
