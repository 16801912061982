
const { alarmNames, alertNames, serverNames } = require('alarm-defs');

/**
 * @typedef _AlarmSettingList
 * @property {_Level} Level
 * @property {String} Text
 * @property {alarmNames|alertNames|serverNames} NameIndex
 */

/**
 * @typedef {Number} _Level
 **/

/**
 * @enum {_Level}
 */
const Level = {
  Critical: 0,
  Level1: 1,
  Level2: 2,
  Level3: 3
};

const AlarmList = function () {
  /** @type _AlarmSettingList[] */
  const List = [];
  /**
   *
   * @param {_Level} Level
   * @param {String} Text
   * @param {alarmNames|alertNames|serverNames} NameIndex
   */
  const build = function (Level, Text, NameIndex) {
    List.push({
      Level,
      Text,
      NameIndex
    });
  };

  /**
   *
   * @param {_Level}Level
   * @returns _AlarmSettingList[]
   */
  const getLevelList = function (Level) {
    const list = [];
    for (let i = 0; i < List.length; i++) {
      if (List[i].Level === Level) {
        list.push(List[i]);
      }
    }
    return list;
  };

  // Critical
  let level = Level.Critical;
  build(level, 'Line power off', alarmNames.Power_is_off);
  build(level, 'Power Supply', alarmNames.PowerSupply);
  build(level, 'Last Aerator Down', alarmNames.LastAeratorDown);
  build(level, 'No Polls', serverNames.NoPolls);
  build(level, 'Low DO', alarmNames.Low_do);
  build(level, 'Re alarmed Low DO', serverNames.DoReAlarm);
  build(level, 'Aerator High', alarmNames.AeratorHigh);
  build(level, 'Aerator Low', alarmNames.AeratorLow);
  build(level, 'Probe Unresponsive', alarmNames.ProbeTestSanity);
  build(level, 'Radio unplugged', serverNames.ComError);
  build(level, 'Clock sync', serverNames.ClockSync);

  // Level 1 alarm
  level = Level.Level1;
  build(level, 'Low Bromine', alertNames.Bromine);
  build(level, 'Amp sensor', alarmNames.AmpSensor);
  build(level, 'Unlinked remote', alarmNames.UnlinkedRemote);
  build(level, 'Probe Range', alarmNames.ProbeRange);
  build(level, 'Seine mode', serverNames.SeineMode);
  build(level, 'Service mode', serverNames.Maintenance);
  build(level, 'Buoy pump Cal High', alarmNames.CalHigh);
  build(level, 'Buoy pump Cal Low', alarmNames.CalLow);
  build(level, 'Buoy pump Test High', alarmNames.TestHigh);
  build(level, 'Buoy pump Test Low', alarmNames.TestLow);
  build(level, 'Temp range', alertNames.TempRange);
  build(level, 'Clock error', alarmNames.Clock);
  build(level, 'Cal change excessive', alarmNames.ProbeCalSanity);
  build(level, 'Battery low', alarmNames.Battery);

  // Level 2 alert
  level = Level.Level2;
  build(level, 'Int low DO', alertNames.IntLowDo);
  build(level, 'Aerator on hand', alertNames.RunningNoCmd);
  build(level, 'Buoy service', alertNames.BuoyService);
  build(level, 'Probe unstable', alertNames.ProbeUnstable);
  build(level, 'Aerator Mid', alertNames.AeratorMid);
  build(level, 'Prior 24', alertNames.Prior24);
  build(level, 'Rapid DO', alertNames.RapidDo);
  // Level 3 alert
  level = Level.Level3;
  build(level, 'Generator', alertNames.Generator);
  build(level, 'Barometer', alertNames.Barometer);
  build(level, 'Water in buoy', alertNames.WaterInBuoy);
  build(level, 'Buoy pump Test Mid', alertNames.TestMid);
  build(level, 'Buoy pump Cal Mid', alertNames.CalMid);
  build(level, 'CO2 probe', alertNames.Co2);
  build(level, 'Cable temp', alertNames.CableTemp);
  build(level, 'Cable probe', alertNames.CableProbe);
  build(level, 'Test message', serverNames.OutTest);
  build(level, 'Line power on', serverNames.LinePowerOn);
  build(level, 'Re Polls', serverNames.ReturnedPoll);

  return {
    List,
    getLevelList
  };
};

module.exports.AlarmList = AlarmList;
module.exports.Level = Level;
