<template>
  <div>
    <h1>Users Page</h1>

  </div>
</template>

<script>
export default {
  name: 'Users'
};
</script>

<style scoped>

</style>
