<template>
  <q-dialog v-model="confirmDisplay" persistent>
    <q-card dark class="q-pa-md outer-card">
      <div
        class="row items-center bg-grey-7 q-ma-md q-pa-xs text-grey-1 row items-center rounded-borders"
      >
        <q-icon :name="icon" size="md" class="q-ml-sm" />
        <div class="q-ml-sm">
          {{ headerMessage }}
        </div>
      </div>
      <q-card-section class="row items-center text-grey-4">
        <span class="q-px-xs q-pb-sm"> {{ message }} </span>
      </q-card-section>

      <q-card-actions align="right" class="q-mr-sm">
        <q-btn
          flat
          label="Cancel"
          icon="clear"
          color="primary"
          @click="cancelClicked"
        />
        <q-btn
          :label="btnLabel"
          :icon="btnIcon"
          :color="btnColor"
          @click="confirmClicked"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    headerMessage: {
      type: String,
      required: true
    },
    btnIcon: {
      type: String,
      required: true
    },
    btnLabel: {
      type: String,
      required: true
    },
    btnColor: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      confirmDisplay: true,
      confirmUpdate: false,
      displayName: '',
      email: ''
    };
  },
  mounted () {
    console.log('mounted');
  },
  methods: {
    cancelClicked () {
      this.$emit('canceled');
    },
    confirmClicked () {
      this.$emit('confirmed');
      this.confirmDisplay = false;
    }
  }
};
</script>

<style scoped>
.outer-card {
  min-width: 280px;
}
</style>
