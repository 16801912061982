<template>
  <q-page class="flex flex-top">
    <div class="full-width">
      <div class="q-pa-md q-mb-xl row">
        <div v-for="(pond, i) in pondData" :key="i"
             class="col-xs-12 col-sm-6 col-md-8 col-lg-6">
          <q-card class="bg-grey-6 q-ma-xs">
            <q-card-section class="bg-grey-8 text-white">
              <div class="text-h6">{{ pond.name }}</div>
            </q-card-section>
            <div style="padding:5px;">
              <Chart
                :pond_data="pond"
                :width="chartSize.width"
                :height="chartSize.height"
                :scale="chartSize.scale"
                backColor="#9e9e9e"
              />
            </div>
          </q-card>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>

import Chart from '@/components/ChartCanvas.vue';

export default {
  name: 'Charts',
  data () {
    return {};
  },
  computed: {
    pondData () {
      const filtered = [];
      for (let i = 0; i < this.$store.state.ponds.pondData.length; i++) {
        if (this.$store.state.ponds.pondData[i].ready) {
          filtered.push(this.$store.state.ponds.pondData[i]);
        }
      }
      filtered.sort(function (a, b) {
        return a.position - b.position;
      });
      return filtered;
    },
    chartSize () {
      if (this.$q.screen.width < 350) {
        return {
          width: 500,
          height: 380,
          scale: 0.55
        };
      } else if (this.$q.screen.width < 400) {
        return {
          width: 500,
          height: 380,
          scale: 0.6
        };
      } else {
        return {
          width: 500,
          height: 380,
          scale: 1.0
        };
      }
    }
  },
  components: {
    Chart
  },
  methods: {}
};
</script>

<style scoped>
  .overview {
    width: 100%;
    min-height: calc(100vh - var(--below-nav-value));
    background-color: #969492;
  }

  .overview-inner {
    height: calc(100vh - var(--below-nav-value));
    overflow: auto;
  }
</style>
