import { Point } from './Point';
import { Size } from './Size';

function Rectangle (locationX, sizeY, optWidth, optHeight) {
  if (optWidth && optHeight) {
    this.location = new Point(locationX, sizeY);
    this.size = new Size(optWidth, optHeight);
  } else {
    this.location = locationX;
    this.size = sizeY;
  }
  Object.defineProperties(this, {
    x: {
      get: function () {
        return this.location.x;
      },
      set: function (x) {
        this.location.x = x;
      }
    },
    y: {
      get: function () {
        return this.location.y;
      },
      set: function (y) {
        this.location.y = y;
      }
    },
    width: {
      get: function () {
        return this.size.width;
      },
      set: function (w) {
        this.size.width = w;
      }
    },
    height: {
      get: function () {
        return this.size.height;
      },
      set: function (h) {
        this.size.height = h;
      }
    },
    top: {
      get: function () {
        return this.location.y;
      }
    },
    bottom: {
      get: function () {
        return this.location.y + this.size.height;
      }
    },
    left: {
      get: function () {
        return this.location.x;
      }
    },
    right: {
      get: function () {
        return this.location.x + this.size.width;
      }
    },
    center: {
      get: function () {
        return {
          x: this.width / 2 + this.x,
          y: this.height / 2 + this.y
        };
      }
    },
    radius: {
      get: function () {
        if (this.width < this.height) {
          return this.width / 2;
        } else {
          return this.height / 2;
        }
      }
    }
  });
}

Rectangle.prototype.fill = function (context) {
  context.offsetFillRect(this.x, this.y, this.width, this.height);
};

Rectangle.prototype.stroke = function (context) {
  context.offsetStrokeRect(this.x, this.y, this.width, this.height);
};

Rectangle.prototype.fillAndStroke = function (context) {
  this.fill(context);
  this.stroke(context);
};

Rectangle.prototype.contains = function (point) {
  return point.x >= this.left && point.x <= this.right &&
    point.y >= this.top && point.y <= this.bottom;
};

Rectangle.prototype.circleContains = function (point, extra) {
  const dist = Math.sqrt(
    Math.pow(this.center.x - point.x, 2) +
    Math.pow(this.center.y - point.y, 2));
  if (extra) {
    return dist < (this.radius * extra);
  } else {
    return dist < this.radius;
  }
};

// module.exports =
export { Rectangle };
