// MainChart Ver 1.2
// Vue integration EF
// @ts-nocheck
export { Chart };

CanvasRenderingContext2D.prototype.offsetFillRect = function (x, y, w, h) {
  this.fillRect(x + 0.5, y + 0.5, w, h);
};

CanvasRenderingContext2D.prototype.offsetStrokeRect = function (x, y, w, h) {
  this.strokeRect(x + 0.5, y + 0.5, w, h);
};

CanvasRenderingContext2D.prototype.offsetLineTo = function (x, y) {
  this.lineTo(x + 0.5, y + 0.5);
};

CanvasRenderingContext2D.prototype.offsetMoveTo = function (x, y) {
  this.moveTo(x + 0.5, y + 0.5);
};

CanvasRenderingContext2D.prototype.offsetArcTo = function (x1, y1, x2, y2, r) {
  this.arcTo(x1 + 0.5, y1 + 0.5, x2 + 0.5, y2 + 0.5, r);
};

CanvasRenderingContext2D.prototype.offsetFillText = function (text, x, y) {
  this.fillText(text, x + 0.5, y + 0.5);
};

Math.trunc = function (value) {
  return Math[value < 0 ? 'ceil' : 'floor'](value);
};

function error (msg) {
  console.log(msg);
  window.alert(msg);
}

function dayToString (day) {
  let text;

  switch (day) {
    case 0:
      text = 'Sun';
      break;
    case 1:
      text = 'Mon';
      break;
    case 2:
      text = 'Tue';
      break;
    case 3:
      text = 'Wed';
      break;
    case 4:
      text = 'Thu';
      break;
    case 5:
      text = 'Fri';
      break;
    case 6:
      text = 'Sat';
      break;
    default:
      error('dayToString: argument day out of bounds. Day is ' + day +
        ' but must be less than or equal to 6');
      text = 'Err';
      break;
  }

  return text;
}

function fahrenheit (celsius) {
  return celsius * 9 / 5 + 320;
}

function TimeSpan (milliseconds) {
  this.totalMilliseconds = milliseconds;

  Object.defineProperties(this, {
    milliseconds: {
      get: function () {
        return this.totalMilliseconds % 1000;
      }
    },
    seconds: {
      get: function () {
        return Math.trunc(this.totalSeconds) % 60;
      }
    },
    totalSeconds: {
      get: function () {
        return this.totalMilliseconds / 1000;
      }
    },
    minutes: {
      get: function () {
        return Math.trunc(this.totalSeconds) % 60;
      }
    },
    totalMinutes: {
      get: function () {
        return this.totalMilliseconds / (1000 * 60);
      }
    },
    hours: {
      get: function () {
        return Math.trunc(this.totalMinutes) % 60;
      }
    },
    totalHours: {
      get: function () {
        return this.totalMilliseconds / (1000 * 60 * 60);
      }
    },
    days: {
      get: function () {
        return Math.trunc(this.totalHours) % 24;
      }
    },
    totalDays: {
      get: function () {
        return this.totalHours / (1000 * 60 * 60 * 24);
      }
    }
  });
}

function Point (x, y) {
  this.x = x;
  this.y = y;

  Object.defineProperty(this, 'isEmpty', {
    get: function () {
      return this.x === 0 && this.y === 0;
    }
  });
}

function Size (width, height) {
  this.width = width;
  this.height = height;
}

// begin defining Rectangle
function Rectangle (location, size) {
  this.location = location;
  this.size = size;

  Object.defineProperties(this, {
    x: {
      get: function () {
        return this.location.x;
      },
      set: function (x) {
        this.location.x = x;
      }
    },
    y: {
      get: function () {
        return this.location.y;
      },
      set: function (y) {
        this.location.y = y;
      }
    },
    width: {
      get: function () {
        return this.size.width;
      },
      set: function (w) {
        this.size.width = w;
      }
    },
    height: {
      get: function () {
        return this.size.height;
      },
      set: function (h) {
        this.size.height = h;
      }
    },
    top: {
      get: function () {
        return this.location.y;
      }
    },
    bottom: {
      get: function () {
        return this.location.y + this.size.height;
      }
    },
    left: {
      get: function () {
        return this.location.x;
      }
    },
    right: {
      get: function () {
        return this.location.x + this.size.width;
      }
    }
  });
};

Rectangle.prototype.fill = function (context) {
  context.offsetFillRect(this.x, this.y, this.width, this.height);
};

Rectangle.prototype.stroke = function (context) {
  context.offsetStrokeRect(this.x, this.y, this.width, this.height);
};

Rectangle.prototype.fillAndStroke = function (context) {
  this.fill(context);
  this.stroke(context);
};

Rectangle.prototype.contains = function (point) {
  return point.x >= this.left && point.x <= this.right &&
    point.y >= this.top && point.y <= this.bottom;
};
// end definition of Rectangle

// begin defining object RoundRect as a sub-type of Rectangle
function RoundRect (rectangle, radius) {
  Rectangle.call(this, rectangle.location, rectangle.size);
  this.radius = radius;
};

RoundRect.prototype = Object.create(Rectangle.prototype);
RoundRect.prototype.constructor = RoundRect;

RoundRect.prototype.setupPath = function (context) {
  context.beginPath();
  context.offsetMoveTo(this.left, this.top + this.radius);
  context.offsetArcTo(this.left, this.top, this.left + this.radius * 2, this.top, this.radius);
  context.offsetArcTo(this.right, this.top, this.right, this.top + this.radius, this.radius);
  context.offsetArcTo(this.right, this.bottom, this.right - this.radius * 2, this.bottom, this.radius);
  context.offsetArcTo(this.left, this.bottom, this.left, this.bottom - this.radius * 2, this.radius);
  context.offsetLineTo(this.left, this.top + this.radius);
};

RoundRect.prototype.fill = function (context) {
  this.setupPath(context);
  context.fill();
};

RoundRect.prototype.stroke = function (context) {
  this.setupPath(context);
  context.stroke();
};

RoundRect.prototype.fillAndStroke = function (context) {
  this.setupPath(context);
  context.fill();
  context.stroke();
};
// end definition of RoundRect

const Direction = {
  stopped: 1,
  forward: 2,
  reverse: 3
};

// begin defining object Chart
function Chart (canvas) {
//    this.flags    = flags;
  this.canvas = canvas;
  this.context = canvas.getContext('2d', { alpha: false });// alpha=false hugely improves text anti-alias fuzzy issue
  this.aerEnables = [];

  this.timeStart = 0;
  this.haveData = false;
  this.maxZoom = 768;
  this.aerconDisplay = true;

  this.Celsius = false;
  this._tempTop = 100;
  this.tempBottom = 60;
  this.DOTop = 25;
  this.DOBottom = 0;
  this.CO2Bottom = 0;
  this.CO2Top = 20;

  // console.log(canvas.style.width);
  this.calculate_scale();
  // this.scale = 500 / 350;

  this.penOffChartColor = 'red';
  this.size = new Size(canvas.width, canvas.height);
  this.location = new Point(0, 0);
  this.tabIndex = 0;
  this.legendWidth = 110;
  this.minMoveVelocity = 70;
  this.minMoveSpacing = 1;
  this.minPixelSpacing = 6;
  this.minHorizontalPixelSpacing = 60;
  this.animationFrameRequested = false;
  this.mouseIsDown = false;
  this.ZoomIsDown = false;
  this.downPosition = 0;
  this.PinchZoom = {
    X0: 0,
    X1: 0
  };
  this.OriginalZoom = 0;
  this.OriginalStart = 0;
  this.velocity = 100;
  this.moveSpacing = 0;
  this.dir = Direction.stopped;
  this.maxDO = 255;
  this.maxTemp = 1200;
  this.maxCO2 = 500;
  this.DOLineWidth = 3;
  this.tempLineWidth = 2;
  this.minZoom = 48;
  this.aerationGradientBottom = 'rgb(0,154,0)';
  this.aerationGradientTop = 'rgb(0,217,0)';
  this.aerationOutline = 'rgb(0,175,0)';
  this.backgroundColor = 'rgb(224,224,224)';
  this.DO1Color = 'black';
  this.DO2Color = 'cornflowerblue';
  this.temp1Color = 'rgb(200,76,27)';
  this.temp2Color = 'rgb(200,173,58)';
  this.CO2Color = 'rgb(200,173,58)';
  this.darkHatch = 'rgb(215,215,215)';
  this.dayMarkColor = 'rgb(133,133,133)';
  this.lightHatch = 'rgb(230,230,230)';
  this.tickMarkColor = 'rgb(113,113,113)';
  this.verticalLineColor = 'rgb(190,190,190)';
  this.mouseLocation = new Point(0, 0);
  this.ChartRect = new Rectangle(new Point(0, 0),
    new Size(this.canvas.width, this.canvas.height));
  this.ProcessCount = 0;
  // this.speed
  // this.moveRectFind
  // this.tempBottom
  // this.DOBottom
  // this.DOTop
  // this.CO2Bottom
  // this.CO2Top
  // this.scrollTimerId

  Object.defineProperties(this, {
    tempTop: {
      get: function () {
        return this._tempTop;
      },
      set: function (value) {
        if (value * 10 > this.maxTemp) {
          this._tempTop = (this.maxTemp / 10) - 1;
        } else {
          this._tempTop = value;
        }
      }
    },
    tempRange: {
      get: function () {
        if (this._tempTop > this.tempBottom) {
          return this._tempTop - this.tempBottom;
        } else {
          return 1;
        }
      }
    },
    DORange: {
      get: function () {
        if (this.DOTop > this.DOBottom) {
          return this.DOTop - this.DOBottom;
        } else {
          return 1;
        }
      }
    },
    CO2Range: {
      get: function () {
        if (this.CO2Top > this.CO2Bottom) {
          return this.CO2Top - this.CO2Bottom;
        } else {
          return 1;
        }
      }
    },
    timeEnd: {
      get: function () {
        return this.timeStart + this.timeRange;
      }
    },
    numberAerators: {
      get: function () {
        let RV = 0;
        for (let a = 0; a < this.aerEnables.length; a++) {
          if (this.aerEnables[a]) {
            RV++;
          }
        }
        return RV;
      }
    },
    timeStartInt: {
      get: function () {
        return Math.trunc(this.timeStart);
      },
      set: function (v) {
        this.timeStart = v;
      }
    },
    timeEndInt: {
      get: function () {
        return Math.trunc(this.timeStart + this.timeRange);
      }
    }
  });

  //    this.aerEnables = [true,  true,  true,  false, false, false,
  //                       false, false, false, false, false, false];
  //    this.aerNames   = ["My Local 1", "My Local 2", "Aerator 3", "Breakit 4",
  //                       "List it 5",  "Ebay 6",     null,         null,
  //                       null,         null,         null,         null];

  // this.dataArrived = Chart.prototype.dataArrived.bind(this);
  this.scrollTick = Chart.prototype.scrollTick.bind(this);
  this.onAnimationFrame = Chart.prototype.onAnimationFrame.bind(this);

  this.handleEvent('mousemove', 'onMouseMoved');
  this.handleEvent('mousedown', 'onMouseDown');
  this.handleEvent('mouseup', 'onMouseUp');
  this.handleEvent('touchmove', 'onTouchMoved');
  this.handleEvent('touchstart', 'onTouchStart');
  this.handleEvent('touchend', 'onTouchEnd');
  this.handleEvent('wheel', 'onWheel');
}

Chart.prototype.calculate_scale = function () {
  const styleWidth = parseInt(this.canvas.style.width.replace('px', ''));
  if (isNaN(styleWidth)) {
    this.scale = 1.0;
  } else {
    const canvasWidth = this.canvas.width;
    this.scale = canvasWidth / styleWidth;
  }
};

Chart.prototype.set_aerators = function (names, enables) {
  this.aerNames = names;

  for (let i = 0; i < enables.length; i++) {
    this.aerEnables[i] = enables[i];
  }
};

Chart.prototype.set_buoys = function (names, type) {
  for (let i = 0; i < names.length; i++) {
    this['buoy' + (i + 1)] = names[i];
  }
  if (type === 'CO2') {
    this.CO2 = true;
    this.useBuoy2 = false;
  } else if (type === 'Double') {
    this.CO2 = false;
    this.useBuoy2 = true;
  } else {
    this.CO2 = false;
    this.useBuoy2 = false;
  }
};

Chart.prototype.set_options = function (options) {
  this.timeRange = options.ChartHours * 4;
  this.aerconDisplay = true;

  this.Celsius = options.Celsius === 'true' || options.Celsius === true;
  this._tempTop = parseInt(options.TempTop);
  this.tempBottom = parseInt(options.TempBottom);
  this.DOTop = parseInt(options.DoTop);
  this.DOBottom = parseInt(options.DoBottom);
  this.CO2Bottom = parseInt(options.Co2Bottom);
  this.CO2Top = parseInt(options.Co2Top);
  if (options.backColor) {
    this.backgroundColor = options.backColor;
  }
};

Chart.prototype.set_data = function (data) {
  this.data = data;
  this.maxZoom = data.length;
  this.haveData = true;
  this.draw(this.ChartRect, this.timeStartInt, this.timeRange);
};

Chart.prototype.handleEvent = function (event, handler) {
  this[handler] = Chart.prototype[handler].bind(this);
  this.canvas.addEventListener(event, this[handler]);
};

Chart.prototype.clientXToLocalX = function (event) {
  const bRect = this.canvas.getBoundingClientRect();
  return (event.clientX - bRect.left) / (bRect.right - bRect.left) *
    this.canvas.width;
};

Chart.prototype.localPosition = function (event) {
  const bRect = this.canvas.getBoundingClientRect();
  if (event.type.indexOf('touch') > -1) {
    return new Point(
      Math.floor((event.touches[0].clientX - bRect.left) * this.scale),
      Math.floor((event.touches[0].clientY - bRect.top) * this.scale));
  } else {
    return new Point(
      Math.floor((event.clientX - bRect.left) * this.scale),
      Math.floor((event.clientY - bRect.top) * this.scale));
  }
};

Chart.prototype.scrollTick = function () {
  if (this.dir === Direction.forward) {
    this.timeStartInt += (this.moveSpacing * this.chartMod);
  } else {
    this.timeStartInt -= (this.moveSpacing * this.chartMod);
  }

  if (this.timeStartInt < 0) {
    this.timeStartInt = 0;
    clearInterval(this.scrollTimerId);
    this.scrollTimerId = null;
  }

  if (this.timeStartInt > (this.maxZoom - this.timeRange)) {
    this.timeStartInt = this.maxZoom - this.timeRange;
    clearInterval(this.scrollTimerId);
    this.scrollTimerId = null;
  }

  if (!this.animationFrameRequested) {
    this.requestAnimationFrame();
  }
};

Chart.prototype.requestAnimationFrame = function () {
  requestAnimationFrame(this.onAnimationFrame);
};

Chart.prototype.onAnimationFrame = function (timestamp) {
  this.animationFrameRequested = false;
  if (this.haveData) {
    this.draw(this.ChartRect, this.timeStartInt, this.timeRange);
  }
  // this.draw(new Rectangle(new Point(0, 0), new Size(this.canvas.width,
  //          this.canvas.height)), this.timeStartInt, this.timeRange);
};

Chart.prototype.onWheel = function (event) {
  const location = this.localPosition(event);

  for (let a = 0; a < this.moveRectFind.length; a++) {
    if (this.moveRectFind[a].contains(location)) {
      // Determine where to zoom from
      const PC = 1 / (this.moveRectFind.length + 1) * a;
      if (event.deltaY > 0) {
        this.timeRange += (12 * this.chartMod);
        if (this.timeRange > this.maxZoom) {
          this.timeRange = this.maxZoom;
        } else {
          // Calculate movement center
          this.timeStart -= (12 * this.chartMod) * PC;
        }
      } else if (event.deltaY < 0) {
        this.timeRange -= (12 * this.chartMod);
        if (this.timeRange < this.minZoom) {
          this.timeRange = this.minZoom;
        } else {
          this.timeStart += (12 * this.chartMod) * PC;
        }
      }
      if (this.timeStart < 0) {
        this.timeStart = 0;
      }
      if (this.timeStart > (this.maxZoom - this.timeRange)) {
        this.timeStart = this.maxZoom - this.timeRange;
      }
      break;
    }
  }

  if (!this.animationFrameRequested) {
    this.requestAnimationFrame();
  }

  event.stopPropagation();
  event.preventDefault();
};

Chart.prototype.zoom = function (delta) {
  const PC = 1 / (this.moveRectFind.length + 1) * this.downPosition;
  this.timeRange += (12 * this.chartMod * delta);
  if (this.timeRange > this.maxZoom) {
    this.timeRange = this.maxZoom;
  } else {
    // Calculate movement center
    this.timeStart -= (12 * this.chartMod * delta) * PC;
  }
  if (this.timeStart < 0) {
    this.timeStart = 0;
  }
  if (this.timeStart > (this.maxZoom - this.timeRange)) {
    this.timeStart = this.maxZoom - this.timeRange;
  }

  if (!this.animationFrameRequested) {
    this.requestAnimationFrame();
  }
};

// An event handler. The constructor binds all event handlers to the object
// being constructed.
Chart.prototype.onMouseMoved = function (event) {
  const location = this.localPosition(event);
  if (this.mouseIsDown) {
    for (let a = 0; a < this.moveRectFind.length; a++) {
      if (this.moveRectFind[a].contains(location)) {
        if (a !== parseInt(this.downPosition)) {
          const posDif = this.downPosition - a;
          this.timeStartInt += (posDif * this.chartMod);
          this.downPosition = a;
          this.moveSpacing = Math.abs(posDif) * 2;
          if (this.moveSpacing < this.minMoveSpacing) {
            this.moveSpacing = this.minMoveSpacing;
          }
          const t = new TimeSpan(new Date() - this.speed);
          this.velocity = Math.trunc(t.totalMilliseconds / this.moveSpacing) * 4;
          if (this.velocity < 5) {
            this.velocity = 5;
          }
          this.speed = new Date();
          if (posDif === 0) {
            this.dir = Direction.stopped;
          } else if (posDif > 0) {
            this.dir = Direction.forward;
          } else if (posDif < 0) {
            this.dir = Direction.reverse;
          }
        } else {
          this.velocity = 100;
        }
        if (this.timeStartInt < 0) {
          this.timeStartInt = 0;
        }
        if (this.timeStartInt > (this.maxZoom - this.timeRange)) {
          this.timeStartInt = this.maxZoom - this.timeRange;
        }
        break;
      }
    }
  }
  this.mouseLocation = location;

  if (!this.animationFrameRequested) {
    this.requestAnimationFrame();
  }

  // prevent sideways movement of the page when scrolling
  // the chart on touchscreen devices
  event.preventDefault();
};

Chart.prototype.onMouseDown = function (event) {
  const location = this.localPosition(event);

  this.mouseIsDown = true;
  this.velocity = 100;
  if (this.scrollTimerId) {
    clearInterval(this.scrollTimerId);
    this.scrollTimerId = null;
  }
  this.speed = new Date();
  for (let a = 0; a < this.moveRectFind.length; a++) {
    if (this.moveRectFind[a].contains(location)) {
      this.downPosition = a;
      break;
    }
  }
};

Chart.prototype.onTouchStart = function (event) {
  const bRect = this.canvas.getBoundingClientRect();
  if (event.touches.length > 1) {
    this.ZoomIsDown = true;
    const location =
      new Point(
        ((Math.floor((event.touches[0].clientX - bRect.left) * this.scale) +
          (Math.floor((event.touches[1].clientX - bRect.left) * this.scale))) / 2),
        Math.floor((event.touches[0].clientY - bRect.top) * this.scale));
    this.PinchZoom.X0 = Math.floor((event.touches[0].clientX - bRect.left) * this.scale);
    this.PinchZoom.X1 = Math.floor((event.touches[1].clientX - bRect.left) * this.scale);
    this.OriginalZoom = this.timeRange;
    this.OriginalStart = this.timeStart;
    for (let a = 0; a < this.moveRectFind.length; a++) {
      if (this.moveRectFind[a].contains(location)) {
        this.downPosition = a;// Get zoom center
        break;
      }
    }
  } else {
    const location = this.localPosition(event);

    this.mouseIsDown = true;
    this.velocity = 100;
    if (this.scrollTimerId) {
      clearInterval(this.scrollTimerId);
      this.scrollTimerId = null;
    }
    this.speed = new Date();
    for (let a = 0; a < this.moveRectFind.length; a++) {
      if (this.moveRectFind[a].contains(location)) {
        this.downPosition = a;
        break;
      }
    }
  }
};

Chart.prototype.onMouseUp = function (event) {
  if (this.mouseIsDown) {
    if (this.velocity < this.minMoveVelocity) {
      this.scrollTimerId = setInterval(this.scrollTick, this.velocity * this.moveSpacing);
    }
  }
  this.mouseIsDown = false;
};

Chart.prototype.onTouchEnd = function (event) {
  if (this.mouseIsDown) {
    if (this.velocity < this.minMoveVelocity) {
      this.scrollTimerId = setInterval(this.scrollTick, this.velocity * this.moveSpacing);
    }
  }
  this.mouseIsDown = false;
  this.ZoomIsDown = false;
};

Chart.prototype.onTouchMoved = function (event) {
  if (this.ZoomIsDown) {
    this.mouseIsDown = false;
    event.preventDefault();
    const bRect = this.canvas.getBoundingClientRect();
    const X0 = new Point(event.touches[0].clientX - bRect.left, event.touches[0].clientY - bRect.top);
    const X1 = new Point(event.touches[1].clientX - bRect.left, event.touches[1].clientY - bRect.top);
    // let CenterLocation = new Point(((X0.x + X1.x) / 2) - bRect.left, event.touches[0].clientY - bRect.top);
    const DiffOriginal = this.PinchZoom.X0 - this.PinchZoom.X1;
    const DiffNow = X0.x - X1.x;
    const Calculation = DiffOriginal / DiffNow;
    // const PC = 1 / (this.moveRectFind.length + 1) * this.downPosition;
    this.timeRange = parseInt(this.OriginalZoom * Calculation / 12) * 12;
    // (12 * this.chartMod * delta);
    if (this.timeRange > this.maxZoom) {
      this.timeRange = this.maxZoom;
    } else if (this.timeRange < this.minZoom) {
      this.timeRange = this.minZoom;
    } else {
      // console.log(this.timeRange, this.OriginalZoom, this.timeStart, this.OriginalStart);
      // Calculate movement center
      const OriginalCenter = this.OriginalStart + (this.OriginalZoom / 2);
      this.timeStart = OriginalCenter - (this.timeRange / 2);
      // this.timeStart = this.OriginalStart + parseInt(this.OriginalZoom * Calculation) / 4;
    }
    if (this.timeStart < 0) {
      this.timeStart = 0;
    }
    if (this.timeStart > (this.maxZoom - this.timeRange)) {
      this.timeStart = this.maxZoom - this.timeRange;
    }
    // this.onMouseMoved(event, CenterLocation);
    // console.log(Calculation);
    if (!this.animationFrameRequested) {
      this.requestAnimationFrame();
    }
  } else {
    this.onMouseMoved(event);
  }
};

Chart.prototype.needsUpdate = function () {
  window.requestAnimationFrame(this.update);
};

Chart.prototype.Fahrenheit = function (celsius) {
  return celsius * 9 / 5 + 320;
};

Chart.prototype.toBeginning = function () {
  this.timeStartInt = 0;

  if (!this.animationFrameRequested) {
    this.requestAnimationFrame();
  }
};

Chart.prototype.toEnd = function () {
  this.timeStartInt = this.maxZoom - this.timeRange;

  if (!this.animationFrameRequested) {
    this.requestAnimationFrame();
  }
};

Chart.prototype.draw = function (drawSize, localTimeStart, localTimeRange) {
  // let localTimeEnd = localTimeStart + localTimeRange;
  let NeedVertUpdate = false;
  if (this.UpdatedTimeRange !== localTimeRange) {
    this.UpdatedTimeRange = localTimeRange;
    NeedVertUpdate = true;
  }
  const localLegendWidth = drawSize.width < 550 ? 80 : this.legendWidth;
  this.calculate_scale();
  // region Calculate and fill aerator and main chart rectangle
  this.context.fillStyle = this.backgroundColor;
  this.context.lineWidth = 1;
  drawSize.fill(this.context);
  // Have to start at 0,0 rather than 0.5,0.5 because of alpha = false
  this.context.fillRect(0, 0, drawSize.width, drawSize.height);
  const upperCorner = new Point(drawSize.x + 20, drawSize.y + 20);
  const chartAerSize = new Size(
    Math.trunc(drawSize.width - localLegendWidth - 50 - upperCorner.x),
    Math.trunc(this.numberAerators * drawSize.height * 0.036));
  if (this.numberAerators > 0) {
    chartAerSize.height -= (chartAerSize.height % this.numberAerators) - 1;
  }
  const upperAerBoxCorner = new Point(
    upperCorner.x,
    drawSize.y + drawSize.height - chartAerSize.height - 20);

  const chartAerBox = new Rectangle(upperAerBoxCorner, chartAerSize);
  if (this.numberAerators > 0) {
    this.context.fillStyle = this.darkHatch;
    this.context.strokeStyle = this.tickMarkColor;
    chartAerBox.fill(this.context);
    chartAerBox.stroke(this.context);
  }
  const chartSize = new Size(
    chartAerSize.width,
    Math.trunc(drawSize.height - chartAerSize.height - 70));
  const chartBox = new Rectangle(upperCorner, chartSize);
  this.context.fillStyle = 'rgb(215,215,215)';
  chartBox.fill(this.context);
  chartBox.stroke(this.context);
  // endregion

  // region Pre-calculate necessary zoom and hatch
  // let hatchHeight = ((chartSize.height - 2) / this.DORange);
  const availableHeight = ((chartSize.height - 2) /
    (this.minHorizontalPixelSpacing - 1));
  const hatchLevel = Math.trunc((this.DORange / availableHeight) + 1);
  let DOHatchFreq = 1;
  switch (hatchLevel) {
    case 0:
    case 1:
      DOHatchFreq = 1;
      break;
    case 2:
      DOHatchFreq = 2;
      break;
    case 3:
      DOHatchFreq = 5;
      break;
    default:
      DOHatchFreq = 5;
      break;
  }

  let tempFreqValue = Math.trunc((this.tempRange / availableHeight) + 1);
  if (tempFreqValue < 4) {
    tempFreqValue = 2;
  } else {
    tempFreqValue = 5;
  }

  // let zoomWidth = ((chartSize.width - 2) / localTimeRange - 1);
  const availableWidth = ((chartSize.width - 2) / this.minPixelSpacing - 1);
  // Find next width up, if needed
  let zoomLevel;
  if (this.aerconDisplay) {
    zoomLevel = Math.trunc(localTimeRange / availableWidth) + 1;
  } else {
    zoomLevel = Math.trunc(localTimeRange * 4 / 3 / availableWidth) + 1;
  }
  // let scaleRange = Math.trunc(localTimeRange / zoomLevel);
  let gridMod = 0;
  let dayMod = 0;
  this.chartMod = 0;
  if (this.aerconDisplay) {
    switch (zoomLevel) {
      case 0:
        gridMod = 4;
        this.chartMod = 1;
        dayMod = 1;
        break; // 1 hour gridlines
      case 1:
        gridMod = 4;
        this.chartMod = 1;
        dayMod = 1;
        break; // 1 hour gridlines
      case 2:
        gridMod = 8;
        this.chartMod = 1;
        dayMod = 1;
        break; // 2 hour gridlines
      case 3:
        gridMod = 16;
        this.chartMod = 2;
        dayMod = 1;
        break; // 4 hour gridlines
      case 4:
        gridMod = 24;
        this.chartMod = 2;
        dayMod = 1;
        break; // 6 hour gridlines
      case 5:
        gridMod = 32;
        this.chartMod = 4;
        dayMod = 1;
        break; // 12 hour gridlines
      case 6:
        gridMod = 48;
        this.chartMod = 8;
        dayMod = 1;
        break; // 12 hour gridlines
      case 7:
      case 8:
      case 9:
      case 10:
        gridMod = 96;
        this.chartMod = 16;
        dayMod = 7;
        break; // 24 hour gridlines
      default:
        gridMod = 96;
        this.chartMod = 16;
        dayMod = 14;
        break;
    }
  } else {
    switch (zoomLevel) {
      case 0:
        gridMod = 3;
        this.chartMod = 1;
        dayMod = 1;
        break; // 1 hour gridlines
      case 1:
        gridMod = 3;
        this.chartMod = 1;
        dayMod = 1;
        break; // 1 hour gridlines
      case 2:
        gridMod = 6;
        this.chartMod = 2;
        dayMod = 1;
        break; // 2 hour gridlines
      case 3:
        gridMod = 12;
        this.chartMod = 3;
        dayMod = 1;
        break; // 4 hour gridlines
      case 4:
        gridMod = 24;
        this.chartMod = 4;
        dayMod = 1;
        break; // 6 hour gridlines
      case 5:
        gridMod = 36;
        this.chartMod = 6;
        dayMod = 1;
        break; // 12 hour gridlines
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        gridMod = 72;
        this.chartMod = 16;
        dayMod = 7;
        break; // 24 hour gridlines
      default:
        gridMod = 72;
        this.chartMod = 16;
        dayMod = 14;
        break;
    }
  }
  // endregion

  // region Calculate Main horizontal DO Y points
  const DOPoints = [];
  const jumps = ((chartSize.height - 1) / (this.DORange * 10));
  const bottomX = upperCorner.y + chartSize.height;
  for (let a = 0; a < this.maxDO; a++) {
    DOPoints[a] = Math.trunc(bottomX - (jumps * (a - (this.DOBottom * 10))));
    if (DOPoints[a] < upperCorner.y) {
      DOPoints[a] = upperCorner.y;
    }
    if (DOPoints[a] > (upperCorner.y + chartSize.height - 1)) {
      DOPoints[a] = (upperCorner.y + chartSize.height - 1);
    }
  }
  // endregion

  // region Calculate Main horizontal Temp Y points
  const tempPoints = [];
  const jumpsTemps = ((chartSize.height - 1) / (this.tempRange * 10));
  // let bottomX  = upperCorner.y + chartSize.height;//calculated
  for (let a = 0; a < this.maxTemp; a++) {
    tempPoints[a] = Math.trunc(bottomX - (jumpsTemps *
      (a - (this.tempBottom * 10))));
    if (tempPoints[a] < upperCorner.y) {
      tempPoints[a] = upperCorner.y;
    }
    if (tempPoints[a] > (upperCorner.y + chartSize.height - 1)) {
      tempPoints[a] = (upperCorner.y + chartSize.height - 1);
    }
  }
  // endregion

  // region Calculate Main horizontal CO2 Y points
  const CO2Points = [];
  const jumpsCO2 = ((chartSize.height - 2) / (this.CO2Range * 10));
  // let bottomX  = upperCorner.y + chartSize.height;//calculated
  for (let a = 0; a < this.maxCO2; a++) {
    CO2Points[a] = Math.trunc(bottomX - (jumpsCO2 * (a -
      (this.CO2Bottom * 10))));
    if (CO2Points[a] < upperCorner.y) {
      CO2Points[a] = upperCorner.y;
    }
    if (CO2Points[a] > (upperCorner.y + chartSize.height - 1)) {
      CO2Points[a] = (upperCorner.y + chartSize.height - 1);
    }
  }
  // endregion

  // region Calculate Main horizontal aerator Y points
  const aeratorPoints = [];
  const aerJumps = ((chartAerSize.height - 1) / this.numberAerators);
  const aerIndHeight = Math.trunc(aerJumps * 0.65);
  const aerIndOffset = Math.trunc((aerJumps - aerIndHeight) / 2);
  for (let a = 0; a < this.numberAerators; a++) {
    aeratorPoints[a] = Math.trunc(upperAerBoxCorner.y + 1 + (aerJumps * a));
  }
  // endregion

  // region Calculate vertical X points
  const timeRangeDiv = Math.trunc(localTimeRange / this.chartMod);
  const timeRangeInt = timeRangeDiv * this.chartMod;
  const vertJumps = ((chartSize.width - 2) / (timeRangeDiv - 1));
  let verticalPoints = [];
  if (NeedVertUpdate === true) {
    for (let a = 0; a < timeRangeDiv - 1; a++) {
      verticalPoints.push({ x: Math.trunc(chartBox.right - 1 - (vertJumps * a)) });
    }
    verticalPoints.push({ x: Math.trunc(chartBox.left + 1) });
    this.SavedVertPoints = verticalPoints;
  } else {
    verticalPoints = this.SavedVertPoints;// Performance mod
  }
  // endregion

  // <editor-fold>Draw main hatch and DO numbers
  this.context.textBaseline = 'middle';
  this.context.textAlign = 'end';
  this.context.fillStyle = 'black';
  this.context.strokeStyle = this.tickMarkColor;
  this.context.font = '13px "Tahoma", sans-serif';
  this.context.offsetFillText(this.DOBottom, upperCorner.x - 8, DOPoints[0]);
  this.context.beginPath();
  this.context.offsetMoveTo(upperCorner.x - 1, DOPoints[0]);
  this.context.offsetLineTo(upperCorner.x - 5, DOPoints[0]);
  this.context.stroke();
  let hatchSize = new Size(chartSize.width - 2,
    Math.trunc(jumps * DOHatchFreq * 10));
  let darkColorLast = false;
  let lastDarkY = 0;
  for (let a = this.DOBottom + 1; a < this.DOTop + 1; a++) {
    const TR = new Rectangle(
      new Point(
        upperCorner.x + 1,
        DOPoints[a * 10]),
      new Size(hatchSize.width, hatchSize.height));
    if (TR.bottom > (chartBox.bottom - 1)) {
      TR.height = chartBox.height - (TR.top - chartBox.top) - 1;
    }

    if (a % (DOHatchFreq * 2) === 0) {
      this.context.fillStyle = this.lightHatch;
      TR.fill(this.context);
      darkColorLast = false;
      this.context.fillStyle = 'black';
      this.context.strokeStyle = this.tickMarkColor;
      this.context.offsetFillText(a, upperCorner.x - 6, DOPoints[a * 10]);
      this.context.beginPath();
      this.context.offsetMoveTo(upperCorner.x - 1, DOPoints[a * 10]);
      this.context.offsetLineTo(upperCorner.x - 5, DOPoints[a * 10]);
      this.context.stroke();
    } else if (a % (DOHatchFreq * 2) === DOHatchFreq) {
      this.context.fillStyle = this.darkHatch;
      TR.fill(this.context);
      darkColorLast = true;
      lastDarkY = TR.y;
      this.context.fillStyle = 'black';
      this.context.offsetFillText(a, upperCorner.x - 6, DOPoints[a * 10]);
      this.context.beginPath();
      this.context.offsetMoveTo(upperCorner.x - 1, DOPoints[a * 10]);
      this.context.offsetLineTo(upperCorner.x - 5, DOPoints[a * 10]);
      this.context.stroke();
    } else {
      this.context.beginPath();
      this.context.offsetMoveTo(upperCorner.x - 1, DOPoints[a * 10]);
      this.context.offsetLineTo(upperCorner.x - 3, DOPoints[a * 10]);
      this.context.stroke();
    }
  }
  if (darkColorLast) {
    let yPoint = DOPoints[this.maxDO - 1];
    if (yPoint < upperCorner.y + 1) {
      yPoint = upperCorner.y + 1;
    }
    this.context.fillStyle = this.lightHatch;
    this.context.offsetFillRect(upperCorner.x + 1, yPoint, chartBox.width - 2,
      lastDarkY - chartBox.y - 1);
  }
  // </editor-fold>Draw main hatch and DO numbers

  // <editor-fold>Draw Temp scale and numbers
  this.context.font = '10.5px "Tahoma", sans-serif';
  this.context.fillStyle = 'black';
  const temperatureX = upperCorner.x + chartAerSize.width;
  this.context.beginPath();
  this.context.offsetMoveTo(temperatureX + 1, tempPoints[0]);
  this.context.offsetLineTo(temperatureX + 5, tempPoints[0]);
  this.context.stroke();
  for (let a = this.tempBottom + 1; a < this.tempTop; a++) {
    if (a % (tempFreqValue * 2) === 0) {
      this.context.offsetFillText(a + '\u00b0', temperatureX + 31,
        tempPoints[(a * 10)]);
      this.context.beginPath();
      this.context.offsetMoveTo(temperatureX + 1, tempPoints[(a * 10)]);
      this.context.offsetLineTo(temperatureX + 5, tempPoints[(a * 10)]);
      this.context.stroke();
    } else if (a % (tempFreqValue * 2) === tempFreqValue) {
      this.context.offsetFillText(a + '\u00b0', temperatureX + 31,
        tempPoints[(a * 10)]);
      this.context.beginPath();
      this.context.offsetMoveTo(temperatureX + 1, tempPoints[(a * 10)]);
      this.context.offsetLineTo(temperatureX + 5, tempPoints[(a * 10)]);
      this.context.stroke();
    } else {
      this.context.beginPath();
      this.context.offsetMoveTo(temperatureX + 1, tempPoints[(a * 10)]);
      this.context.offsetLineTo(temperatureX + 3, tempPoints[(a * 10)]);
      this.context.stroke();
    }
  }
  // </editor-fold>Draw Temp scale and numbers

  // <editor-fold>Draw aerator hatch
  this.context.font = '12px "Tahoma", sans-serif';
  hatchSize = new Size(chartSize.width - 2, Math.trunc(aerJumps));
  let aerSpacer = 0;
  for (let a = 0; a < this.numberAerators; a++) {
    const VHR = new Rectangle(new Point(upperAerBoxCorner.x +
      chartAerSize.width + 5,
    aeratorPoints[a]),
    new Size(drawSize.width - (upperAerBoxCorner.x +
        chartAerSize.width + 7),
    hatchSize.height));
    if (VHR.bottom > (chartAerBox.bottom - 1)) {
      VHR.height = chartAerBox.height - (VHR.top - chartAerBox.top);
    }
    while (!this.aerEnables[aerSpacer]) {
      aerSpacer++;
    }
    if (this.aerEnables[aerSpacer]) {
      this.context.textAlign = 'start';
      this.context.fillStyle = 'black';
      this.context.offsetFillText(this.aerNames[aerSpacer], VHR.x,
        VHR.y + VHR.height / 2);
      aerSpacer++;
    }
    const TR = new Rectangle(new Point(upperAerBoxCorner.x + 1,
      aeratorPoints[a]), hatchSize);
    if (TR.bottom > (chartAerBox.bottom - 1)) {
      TR.height = chartAerBox.height - (TR.top - chartAerBox.top) - 1;
    }
    if (a % 2 === 0) {
      this.context.fillStyle = this.lightHatch;
      TR.fill(this.context);
    } else {
      this.context.fillStyle = this.darkHatch;
      TR.fill(this.context);
    }
  }
  // </editor-fold>Draw aerator hatch

  // <editor-fold>Draw vertical lines and hours
  this.context.textAlign = 'center';
  this.context.fillStyle = 'black';

  for (let a = 0; a < timeRangeInt && this.data.length > 0; a++) {
    const CF = localTimeStart + a;
    let CalcHours = 0;
    let CalcDay = 0;
    let CalcDate = 0;
    let CalcMonth = 0;

    CalcHours = this.data[CF].datetime.getHours();
    CalcDay = this.data[CF].datetime.getDay();
    CalcDate = this.data[CF].datetime.getDate();
    CalcMonth = this.data[CF].datetime.getMonth();
    const CalcGrid = Math.floor((this.data[CF].datetime.getMinutes() / 15) % 4);
    const CalcOther = Math.floor((this.data[CF].datetime.getMinutes() / 15) + (CalcHours * 4));
    // if (CF/* this.data[CF].pos */ % this.chartMod === 0) { // Pre-calculate positions for hovers
    if (CalcOther % this.chartMod === 0) { // Pre-calculate positions for hovers
      verticalPoints[Math.trunc(a / this.chartMod)].pos = CF;
    }

    // if (CF/* this.data[CF].pos */ % gridMod === 0) {
    if (CalcOther % gridMod === 0) {
      const A = Math.trunc(a / this.chartMod);
      const VHP = new Point(verticalPoints[A].x - 1, (upperCorner.y +
        chartSize.height) + (chartAerBox.y -
        (chartBox.y + chartSize.height)) / 2);
      this.context.font = '15px "Tahoma", sans-serif';

      if (dayMod === 1) {
        this.context.offsetFillText(CalcHours, VHP.x, VHP.y);
      } else if (dayMod === 7) {
        // let day = CalcDay;
        this.context.offsetFillText(dayToString(CalcDay), VHP.x, VHP.y);
      } else if (dayMod === 14 && (CalcDay === 0 || CalcDay === 3 || CalcDay === 5)) {
        this.context.offsetFillText(dayToString(CalcDay), VHP.x, VHP.y);
      }
      this.context.lineWidth = 0.8;
      // Upper lines
      this.context.strokeStyle = this.verticalLineColor;
      this.context.beginPath();
      this.context.offsetMoveTo(verticalPoints[A].x, upperCorner.y + 1);
      this.context.offsetLineTo(verticalPoints[A].x, upperCorner.y +
        chartSize.height - 1);
      this.context.stroke();
      this.context.strokeStyle = this.tickMarkColor;
      // this.context.lineWidth = 0.8;
      this.context.beginPath();
      this.context.offsetMoveTo(verticalPoints[A].x, upperCorner.y +
        chartSize.height + 1);
      this.context.offsetLineTo(verticalPoints[A].x, upperCorner.y +
        chartSize.height + 3);
      this.context.stroke();

      // Lower lines
      if (this.numberAerators > 0) {
        this.context.beginPath();
        this.context.offsetMoveTo(verticalPoints[A].x, upperAerBoxCorner.y - 1);
        this.context.offsetLineTo(verticalPoints[A].x, upperAerBoxCorner.y - 3);
        this.context.stroke();
        this.context.strokeStyle = this.verticalLineColor;
        this.context.beginPath();
        this.context.offsetMoveTo(verticalPoints[A].x, upperAerBoxCorner.y + 1);
        this.context.offsetLineTo(verticalPoints[A].x, upperAerBoxCorner.y +
          chartAerSize.height - 1);
        this.context.stroke();
      }
    }

    if (CalcGrid === 0 && CalcHours === 0) {
      if (dayMod === 1 || (dayMod >= 7 && CalcDay === 1)) {
        const A = Math.trunc(a / this.chartMod);
        this.context.strokeStyle = this.dayMarkColor;
        this.context.lineWidth = 3;

        this.context.beginPath();
        this.context.offsetMoveTo(verticalPoints[A].x, upperCorner.y - 2);
        this.context.offsetLineTo(verticalPoints[A].x, upperCorner.y +
          chartSize.height + 4);
        this.context.stroke();

        this.context.beginPath();
        this.context.offsetMoveTo(verticalPoints[A].x, upperAerBoxCorner.y - 3);
        this.context.offsetLineTo(verticalPoints[A].x, upperAerBoxCorner.y +
          chartAerSize.height);
        this.context.stroke();

        this.context.font = '12px "Tahoma", sans-serif';

        if (this.data[CF].valid) {
          let date = dayToString(CalcDay);
          date += ' ' + (CalcMonth + 1) + '/';
          if (CalcDate < 10) {
            date += 0;
          }
          date += CalcDate;
          this.context.offsetFillText(date, verticalPoints[A].x,
            upperCorner.y - 10);
        } else {
          this.context.offsetFillText('No log', verticalPoints[A].x,
            upperCorner.y - 10);
        }
      }
    }
  }

  this.context.font = '12px "Tahoma", sans-serif';
  this.context.offsetFillText('Hour', chartBox.x + chartBox.width + 25, upperCorner.y +
    chartSize.height + (chartAerBox.y -
      (chartBox.y + chartSize.height)) / 2);
  // </editor-fold>Draw vertical lines and hours

  // <editor-fold>region Chart legends
  this.context.strokeStyle = 'black';
  this.context.lineCap = 'round';
  this.context.lineWidth = 1;
  let numLegends = 2;
  if (this.CO2) {
    numLegends = 3;
  } else if (this.useBuoy2) {
    numLegends = 4;
  }

  const legendPoint = new Point(drawSize.width - localLegendWidth - 15, chartBox.y);
  const legendSize = new Size(localLegendWidth, numLegends * 20);
  const legendRect = new RoundRect(new Rectangle(legendPoint, legendSize), 5);
  this.context.fillStyle = this.darkHatch;
  legendRect.fillAndStroke(this.context);

  const R1 = new Rectangle(new Point(legendPoint.x + 25, legendPoint.y),
    new Size(legendSize.width - 35, 20));
  const R2 = new Rectangle(new Point(legendPoint.x + 25, legendPoint.y + 20),
    new Size(legendSize.width - 35, 20));
  const R3 = new Rectangle(new Point(legendPoint.x + 25, legendPoint.y + 40),
    new Size(legendSize.width - 35, 20));
  const R4 = new Rectangle(new Point(legendPoint.x + 25, legendPoint.y + 60),
    new Size(legendSize.width - 35, 20));

  if (this.CO2) {
    this.context.strokeStyle = this.DO1Color;
    this.context.lineWidth = this.DOLineWidth;
    this.context.beginPath();
    this.context.offsetMoveTo(legendPoint.x + 5, legendPoint.y + 10);
    this.context.offsetLineTo(legendPoint.x + 20, legendPoint.y + 10);
    this.context.stroke();

    this.context.strokeStyle = this.temp1Color;
    this.context.lineWidth = this.tempLineWidth;
    this.context.beginPath();
    this.context.offsetMoveTo(legendPoint.x + 5, legendPoint.y + 30);
    this.context.offsetLineTo(legendPoint.x + 20, legendPoint.y + 30);
    this.context.stroke();

    this.context.strokeStyle = this.CO2Color;
    this.context.lineWidth = this.tempLineWidth;
    this.context.beginPath();
    this.context.offsetMoveTo(legendPoint.x + 5, legendPoint.y + 50);
    this.context.offsetLineTo(legendPoint.x + 20, legendPoint.y + 50);

    this.context.textAlign = 'start';
    this.context.font = '12px "Tahoma", sans-serif';
    this.context.fillStyle = 'black';
    this.context.offsetFillText('DO', R1.x, R1.y + R1.height / 2);
    this.context.offsetFillText('Temp', R2.x, R2.y + R2.height / 2);
    this.context.offsetFillText('CO2', R3.x, R3.y + R3.height / 2);
  } else if (this.useBuoy2) {
    this.context.strokeStyle = this.DO1Color;
    this.context.lineWidth = this.DOLineWidth;
    this.context.beginPath();
    this.context.offsetMoveTo(legendPoint.x + 5, legendPoint.y + 10);
    this.context.offsetLineTo(legendPoint.x + 20, legendPoint.y + 10);
    this.context.stroke();

    this.context.strokeStyle = this.DO2Color;
    this.context.beginPath();
    this.context.offsetMoveTo(legendPoint.x + 5, legendPoint.y + 30);
    this.context.offsetLineTo(legendPoint.x + 20, legendPoint.y + 30);
    this.context.stroke();

    this.context.strokeStyle = this.temp1Color;
    this.context.lineWidth = this.tempLineWidth;
    this.context.beginPath();
    this.context.offsetMoveTo(legendPoint.x + 5, legendPoint.y + 50);
    this.context.offsetLineTo(legendPoint.x + 20, legendPoint.y + 50);
    this.context.stroke();

    this.context.strokeStyle = this.temp2Color;
    this.context.beginPath();
    this.context.offsetMoveTo(legendPoint.x + 5, legendPoint.y + 70);
    this.context.offsetLineTo(legendPoint.x + 20, legendPoint.y + 70);
    this.context.stroke();

    this.context.textAlign = 'start';
    this.context.font = '12px "Tahoma", sans-serif';
    this.context.fillStyle = 'black';
    this.context.offsetFillText('DO 1', R1.x, R1.y + R1.height / 2);
    this.context.offsetFillText('DO 2', R2.x, R2.y + R2.height / 2);
    if (localLegendWidth === 80) {
      this.context.offsetFillText('Tmp 1', R3.x, R3.y + R3.height / 2);
      this.context.offsetFillText('Tmp 2', R4.x, R4.y + R4.height / 2);
    } else {
      this.context.offsetFillText('Temp 1', R3.x, R3.y + R3.height / 2);
      this.context.offsetFillText('Temp 2', R4.x, R4.y + R4.height / 2);
    }
  } else {
    this.context.strokeStyle = this.DO1Color;
    this.context.lineWidth = this.DOLineWidth;
    this.context.beginPath();
    this.context.offsetMoveTo(legendPoint.x + 5, legendPoint.y + 10);
    this.context.offsetLineTo(legendPoint.x + 20, legendPoint.y + 10);
    this.context.stroke();

    this.context.strokeStyle = this.temp1Color;
    this.context.lineWidth = this.tempLineWidth;
    this.context.beginPath();
    this.context.offsetMoveTo(legendPoint.x + 5, legendPoint.y + 30);
    this.context.offsetLineTo(legendPoint.x + 20, legendPoint.y + 30);
    this.context.stroke();

    this.context.textAlign = 'start';
    this.context.font = '12px "Tahoma", sans-serif';
    this.context.fillStyle = 'black';
    this.context.offsetFillText('DO', R1.x, R1.y + R1.height / 2);
    this.context.offsetFillText('Temp', R2.x, R2.y + R2.height / 2);
  }
  // endregion</editor-fold>Chart legends

  // <editor-fold>Draw Main Chart Lines
  let average = 0;
  let count = 0;
  let lastPoint = new Point(0, 0); let thisPoint;
  let offChart;
  let firstFlag = true;
  let CF;

  // region Draw C02
  if (this.CO2) {
    this.context.lineWidth = this.tempLineWidth;
    firstFlag = true;
    average = 0;
    count = 0;
    for (let a = 0; a < timeRangeInt; a++) {
      const CL = a + localTimeStart;
      average += this.data[CL].do[1] * 10;
      count++;
      if (a % this.chartMod === 0) {
        const myValue = Math.trunc(average / count);
        average = 0;
        count = 0;
        const A = Math.trunc(a / this.chartMod);

        if (firstFlag) {
          lastPoint = new Point(0, 0);
          firstFlag = false;
        }
        if (!this.data[CL].valid) {
          lastPoint = new Point(0, 0);
          firstFlag = true;
        }
        if (myValue <= 0 || myValue <= (this.CO2Bottom * 10)) {
          thisPoint = new Point(verticalPoints[A].x, chartBox.bottom - 2);
          offChart = true;
        } else if (myValue >= (this.CO2Top * 10) || myValue >= this.maxCO2) {
          thisPoint = new Point(verticalPoints[A].x, CO2Points[myValue]);
          offChart = true;
        } else {
          thisPoint = new Point(verticalPoints[A].x, CO2Points[myValue]);
          offChart = false;
        }
        if (!lastPoint.isEmpty) {
          if (offChart) {
            this.context.strokeStyle = this.penOffChartColor;
            this.context.beginPath();
            this.context.offsetMoveTo(lastPoint.x, lastPoint.y);
            this.context.offsetLineTo(thisPoint.x, thisPoint.y);
            this.context.stroke();
          } else {
            this.context.strokeStyle = this.CO2Color;
            this.context.beginPath();
            this.context.offsetMoveTo(lastPoint.x, lastPoint.y);
            this.context.offsetLineTo(thisPoint.x, thisPoint.y);
            this.context.stroke();
          }
          lastPoint = thisPoint;
        }
      }
    }
  }
  // endregion

  if (this.useBuoy2) {
    // region Temp #2 draw
    firstFlag = true;
    average = 0;
    count = 0;
    for (let a = timeRangeInt - 1; a >= 0; a--) {
      const CL = a + localTimeStart;
      if (this.Celsius === true) {
        CF = this.data[CL].temp[1] * 10;
      } else {
        CF = fahrenheit(this.data[CL].temp[1] * 10);
      }

      if (this.data[CL].valid) {
        average += CF;
        count++;
      }
      if (a % this.chartMod === 0) {
        if (count === 0) {
          count = 1;
        }
        const A = Math.trunc(a / this.chartMod);
        CF = Math.trunc(average / count);
        average = 0;
        count = 0;

        if (firstFlag) {
          lastPoint = new Point(0, 0);
          firstFlag = false;
        }
        if (!this.data[CL].valid) {
          lastPoint = new Point(0, 0);
          firstFlag = true;
        }
        if (CF >= this.maxTemp || CF >= (this.tempTop * 10)) {
          thisPoint = new Point(verticalPoints[A].x, chartBox.top + 2);
          offChart = true;
        } else if (CF <= 0 || CF <= (this.tempBottom * 10)) {
          thisPoint = new Point(verticalPoints[A].x, chartBox.bottom - 2);
          offChart = true;
        } else {
          thisPoint = new Point(verticalPoints[A].x, tempPoints[CF]);
          offChart = false;
        }
        if (!lastPoint.isEmpty) {
          if (offChart) {
            this.context.strokeStyle = this.penOffChartColor;
            this.context.beginPath();
            this.context.offsetMoveTo(lastPoint.x, lastPoint.y);
            this.context.offsetLineTo(thisPoint.x, thisPoint.y);
            this.context.stroke();
          } else {
            this.context.strokeStyle = this.temp2Color;
            this.context.beginPath();
            this.context.offsetMoveTo(lastPoint.x, lastPoint.y);
            this.context.offsetLineTo(thisPoint.x, thisPoint.y);
            this.context.stroke();
          }
        }
        lastPoint = thisPoint;
      }
    }
    // endregion

    // region DO #2 Top item
    this.context.lineWidth = this.DOLineWidth;
    firstFlag = true;
    average = 0;
    count = 0;
    for (let a = timeRangeInt - 1; a >= 0; a--) {
      const CL = a + localTimeStart;

      if (this.data[CL].valid) {
        average += this.data[CL].do[1] * 10;
        count++;
      }
      if (a % this.chartMod === 0) {
        if (count === 0) {
          count = 1;
        }
        const A = Math.trunc(a / this.chartMod);
        const myValue = Math.trunc(average / count);
        average = 0;
        count = 0;

        if (firstFlag) {
          lastPoint = new Point(0, 0);
          firstFlag = false;
        }
        if (!this.data[CL].valid) {
          lastPoint = new Point(0, 0);
          firstFlag = true;
        }
        if (myValue >= this.maxDO || myValue >= (this.DOTop * 10)) {
          thisPoint = new Point(verticalPoints[A].x, chartBox.top + 2);
          offChart = true;
        } else if (myValue <= 0 || myValue <= (this.DOBottom * 10)) {
          thisPoint = new Point(verticalPoints[A].x, chartBox.bottom - 2);
          offChart = true;
        } else {
          thisPoint = new Point(verticalPoints[A].x,
            DOPoints[this.data[CL].do[1]]);
          offChart = false;
        }
        if (!lastPoint.isEmpty) {
          if (offChart) {
            this.context.strokeStyle = this.penOffChartColor;
            this.context.beginPath();
            this.context.offsetMoveTo(lastPoint.x, lastPoint.y);
            this.context.offsetLineTo(thisPoint.x, thisPoint.y);
            this.context.stroke();
          } else {
            this.context.strokeStyle = this.DO2Color;
            this.context.beginPath();
            this.context.offsetMoveTo(lastPoint.x, lastPoint.y);
            this.context.offsetLineTo(thisPoint.x, thisPoint.y);
            this.context.stroke();
          }
        }
        lastPoint = thisPoint;
      }
    }
    // endregion
  }

  // region Temp #1 draw
  this.context.lineWidth = this.tempLineWidth;
  firstFlag = true;
  average = 0;
  count = 0;
  for (let a = timeRangeInt - 1; a >= 0 && this.data.length > 0; a--) {
    const CL = a + localTimeStart;
    // if (this.data[CL].celsius == true) {
    if (this.Celsius === true) {
      CF = this.data[CL].temp[0] * 10;
    } else {
      CF = fahrenheit(this.data[CL].temp[0] * 10);
    }

    if (this.data[CL].valid) {
      average += CF;
      count++;
    }
    if (a % this.chartMod === 0) {
      if (count === 0) {
        count = 1;
      }
      const A = Math.trunc(a / this.chartMod);
      CF = Math.trunc(average / count);
      average = 0;
      count = 0;

      if (firstFlag) {
        lastPoint = new Point(0, 0);
        firstFlag = false;
      }
      if (!this.data[CL].valid) {
        lastPoint = new Point(0, 0);
        firstFlag = true;
      }
      if (CF >= this.maxTemp || CF >= (this.tempTop * 10)) {
        thisPoint = new Point(verticalPoints[A].x, chartBox.top + 2);
        offChart = true;
      } else if (CF <= 0 || CF <= (this.tempBottom * 10)) {
        thisPoint = new Point(verticalPoints[A].x, chartBox.bottom - 2);
        offChart = true;
      } else {
        thisPoint = new Point(verticalPoints[A].x, tempPoints[CF]);
        offChart = false;
      }
      if (!lastPoint.isEmpty) {
        if (offChart) {
          this.context.strokeStyle = this.penOffChartColor;
          this.context.beginPath();
          this.context.offsetMoveTo(lastPoint.x, lastPoint.y);
          this.context.offsetLineTo(thisPoint.x, thisPoint.y);
          this.context.stroke();
        } else {
          this.context.strokeStyle = this.temp1Color;
          this.context.beginPath();
          this.context.offsetMoveTo(lastPoint.x, lastPoint.y);
          this.context.offsetLineTo(thisPoint.x, thisPoint.y);
          this.context.stroke();
        }
      }
      lastPoint = thisPoint;
    }
  }
  // endregion

  // region DO #1 Top item
  this.context.lineWidth = this.DOLineWidth;
  firstFlag = true;
  average = 0;
  count = 0;
  for (let a = timeRangeInt - 1; a >= 0 && this.data.length > 0; a--) {
    const CL = a + localTimeStart;

    if (this.data[CL].valid) {
      average += this.data[CL].do[0] * 10;
      count++;
    }
    if (a % this.chartMod === 0) {
      if (count === 0) {
        count = 1;
      }
      const A = Math.trunc(a / this.chartMod);
      const myValue = Math.trunc(average / count);
      average = 0;
      count = 0;

      if (firstFlag) {
        lastPoint = new Point(0, 0);
        firstFlag = false;
      }
      if (!this.data[CL].valid) {
        lastPoint = new Point(0, 0);
        firstFlag = true;
      }
      if (myValue >= this.maxDO || myValue >= (this.DOTop * 10)) {
        thisPoint = new Point(verticalPoints[A].x, chartBox.top + 2);
        offChart = true;
      } else if (myValue <= 0 || myValue <= (this.DOBottom * 10)) {
        thisPoint = new Point(verticalPoints[A].x, chartBox.bottom - 2);
        offChart = true;
      } else {
        thisPoint = new Point(verticalPoints[A].x, DOPoints[myValue]);
        offChart = false;
      }
      if (!lastPoint.isEmpty) {
        if (offChart) {
          this.context.strokeStyle = this.penOffChartColor;
          this.context.beginPath();
          this.context.offsetMoveTo(lastPoint.x, lastPoint.y);
          this.context.offsetLineTo(thisPoint.x, thisPoint.y);
          this.context.stroke();
        } else {
          this.context.strokeStyle = this.DO1Color;
          this.context.beginPath();
          this.context.offsetMoveTo(lastPoint.x, lastPoint.y);
          this.context.offsetLineTo(thisPoint.x, thisPoint.y);
          this.context.stroke();
        }
      }
      lastPoint = thisPoint;
    }
  }
  // endregion

  // region Draw Aeration indication
  this.context.lineCap = 'butt';
  this.context.lineWidth = 1;
  // region Aerator #1 ->
  // let firstFlag = true;
  let isRunning = false;
  let isValid = true;
  let startPoint = 0;
  let endPoint = 0;

  aerSpacer = 0;
  for (let slot = 0; slot < this.numberAerators; slot++) {
    while (!this.aerEnables[aerSpacer]) {
      aerSpacer++;
    }
    firstFlag = true;
    isRunning = false;
    let average = 0;
    let count = 0;
    for (let a = timeRangeInt - 1; a >= 0 && this.data.length > 0; a--) {
      const CL = a + localTimeStart;
      if (this.data[CL].aer_on[aerSpacer] > 0) {
        average += 1;
      }
      count++;
      if (a % this.chartMod === 0) {
        let isOn = false;
        average = Math.trunc(average * 10 / count);
        if (average > 3) {
          isOn = true;
        }
        count = 0;
        average = 0;
        const A = Math.trunc(a / this.chartMod);
        if (firstFlag) {
          firstFlag = false;
          isRunning = false;
        }
        isValid = this.data[CL].valid;
        if (isValid && isOn && a >= this.chartMod) {
          if (!isRunning) {
            startPoint = A;
            isRunning = true;
          }
        } else {
          if (isRunning) {
            endPoint = A;
            const aerationTestInd = new Rectangle(
              new Point(verticalPoints[startPoint].x,
                aeratorPoints[slot] + aerIndOffset),
              new Size(verticalPoints[endPoint].x -
                verticalPoints[startPoint].x, aerIndHeight));
            const gradient = this.context.createLinearGradient(
              aerationTestInd.x, aerationTestInd.top,
              aerationTestInd.x, aerationTestInd.bottom);
            gradient.addColorStop(0, this.aerationGradientTop);
            gradient.addColorStop(1, this.aerationGradientBottom);
            this.context.fillStyle = gradient;
            this.context.strokeStyle = this.aerationOutline;
            aerationTestInd.fillAndStroke(this.context);
            isRunning = false;
          }
        }
      }
    }
    aerSpacer++;
  }
  // </editor-fold>Draw Main Chart Lines

  // <editor-fold>Hover information
  let chosenLog = 0;
  let hoverItems;
  if (this.CO2) {
    hoverItems = 7;
  } else if (this.useBuoy2) {
    hoverItems = 8;
  } else {
    hoverItems = 5;
  }
  const infoPoint = new Point(legendPoint.x, legendPoint.y + legendSize.height + 15);
  const infoSize = new Size(legendSize.width, (hoverItems * 20));
  // see if item hovered
  const findSize = new Size(Math.trunc(vertJumps + 1),
    Math.trunc(this.size.height * 0.9));
  const LOffset = Math.trunc(this.size.height * 0.05);
  const halfJump = Math.trunc(vertJumps / 2);

  if (NeedVertUpdate === true) {
    this.moveRectFind = [];
    for (let a = 0; a < verticalPoints.length; a++) {
      this.moveRectFind.push(
        new Rectangle(new Point(verticalPoints[a].x - halfJump, LOffset), new Size(findSize.width, findSize.height)));
    }
  }
  if (!this.mouseIsDown && this.scrollTimerId == null && this.data.length > 0) {
    let StartPos = verticalPoints.length - Math.trunc((this.mouseLocation.x - chartBox.left) / vertJumps) - 3;
    if (StartPos < 0) {
      StartPos = 0;
    }
    for (let a = StartPos; a < verticalPoints.length; a++) {
      if (this.mouseLocation.x < chartBox.left || this.mouseLocation.x > chartBox.right) {
        break;
      }
      if (this.moveRectFind[a].contains(this.mouseLocation)) {
        // console.log(StartPos,a,this.moveRectFind[a].x,this.moveRectFind[a].width,this.mouseLocation);
        chosenLog = verticalPoints[a].pos;
        // if (this.data[chosenLog].UTC === true) {
        if (!this.data[chosenLog]) {
          console.log(a);
        }
        const _day = this.data[chosenLog].datetime.getDay();
        const _month = this.data[chosenLog].datetime.getMonth();
        const _date = this.data[chosenLog].datetime.getDate();
        const _hours = this.data[chosenLog].datetime.getHours();
        const _minutes = this.data[chosenLog].datetime.getMinutes();

        this.context.strokeStyle = 'black';
        this.context.lineWidth = 1;
        this.context.beginPath();
        this.context.offsetMoveTo(verticalPoints[a].x, chartBox.top - 3);
        this.context.offsetLineTo(verticalPoints[a].x, chartAerBox.bottom + 3);
        this.context.offsetMoveTo(verticalPoints[a].x - 4, chartBox.top - 3);
        this.context.offsetLineTo(verticalPoints[a].x + 4, chartBox.top - 3);
        this.context.offsetMoveTo(verticalPoints[a].x - 4, chartAerBox.bottom + 3);
        this.context.offsetLineTo(verticalPoints[a].x + 4, chartAerBox.bottom + 3);
        this.context.stroke();

        const infoRect = new Rectangle(infoPoint, infoSize);
        const infoRectRound = new RoundRect(infoRect, 5);
        const gradient = this.context.createLinearGradient(infoRect.x, infoRect.top,
          infoRect.x, infoRect.bottom);
        gradient.addColorStop(0, 'rgb(190,190,190)');
        gradient.addColorStop(1, 'rgb(215,215,215)');
        this.context.fillStyle = gradient;
        infoRectRound.fillAndStroke(this.context);

        this.context.textAlign = 'center';
        this.context.fillStyle = 'black';
        this.context.font = '13px "Tahoma", sans-serif';
        this.context.textBaseline = 'alphabetic';
        let time = dayToString(_day);
        time += ' ' + (_month + 1);
        if (_date < 10) {
          time += '/0' + _date;
        } else {
          time += '/' + _date;
        }
        this.context.offsetFillText(time, infoPoint.x + infoRect.width / 2, infoPoint.y + 17.5);
        let hours = _hours;
        const minutes = _minutes;
        if (hours > 0 && hours < 13) {
          // AM
          time = hours.toString();
          // we need to make sure minutes is in two digits
          if (minutes < 10) {
            time += ':0' + minutes;
          } else {
            time += ':' + minutes;
          }
          time += ' AM';
        } else if (hours > 12) {
          // PM
          hours -= 12;
          time = hours.toString();
          // we need to make sure minutes is in two digits
          if (minutes < 10) {
            time += ':0' + minutes;
          } else {
            time += ':' + minutes;
          }
          time += ' PM';
        } else if (hours === 0) {
          // JS 0:00 is really 24:00
          if (minutes < 10) {
            time = '12:0' + minutes;
          } else {
            time = '12:' + minutes;
          }
          time += ' PM';
        }
        this.context.textBaseline = 'top';
        this.context.offsetFillText(time, infoPoint.x + infoRect.width / 2, infoPoint.y + 22.5);
        this.context.textAlign = 'start';
        this.context.textBaseline = 'middle';
        this.context.offsetFillText(this.buoy1, infoPoint.x + 5, infoPoint.y + 50);
        this.context.strokeStyle = this.DO1Color;
        this.context.lineWidth = this.DOLineWidth;
        this.context.lineCap = 'round';
        this.context.beginPath();
        this.context.offsetMoveTo(infoRect.x + 5, infoRect.y + 70);
        this.context.offsetLineTo(infoRect.x + 20, infoRect.y + 70);
        this.context.stroke();
        this.context.font = '13px "Tahoma", sans-serif';
        const ppmText = this.data[chosenLog].valid
          ? Number(this.data[chosenLog].do[0]).toFixed(1) + ' ppm'
          : 'No log';
        this.context.offsetFillText(ppmText, infoPoint.x + 25, infoPoint.y + 70);
        this.context.strokeStyle = this.temp1Color;
        this.context.lineWidth = this.tempLineWidth;
        this.context.beginPath();
        this.context.offsetMoveTo(infoRect.x + 5, infoRect.y + 90);
        this.context.offsetLineTo(infoRect.x + 20, infoRect.y + 90);
        this.context.stroke();
        let build, build2;
        if (!this.data[chosenLog].valid) {
          build = 'No log';
          build2 = 'No log';
        } else if (this.Celsius === true) {
          build = Number(this.data[chosenLog].temp[0]).toFixed(1);
          build += '\u00b0 C';
          build2 = Number(this.data[chosenLog].temp[1]).toFixed(1);
          build2 += '\u00b0 C';
        } else {
          let IV = this.data[chosenLog].temp[0] * 9 / 5 + 32;
          build = Number(IV).toFixed(1) + '\u00b0 F';
          IV = this.data[chosenLog].temp[1] * 9 / 5 + 32;
          build2 = Number(IV).toFixed(1) + '\u00b0 F';
        }
        this.context.offsetFillText(build, infoPoint.x + 25, infoPoint.y + 90);
        if (this.CO2) {
          this.context.font = '13px "Tahoma", sans-serif';
          this.context.offsetFillText('CO2', infoPoint.x + 5, infoPoint.y + 110);
          this.context.strokeStyle = this.CO2Color;
          this.context.beginPath();
          this.context.offsetMoveTo(infoRect.x + 5, infoRect.y + 130);
          this.context.offsetLineTo(infoRect.y + 20, infoRect.y + 130);
          this.context.stroke();
          this.context.font = '13px "Tahoma", sans-serif';
          this.context.offsetFillText((this.data[chosenLog].do[1]).toFixed(1) +
            ' ppm', infoPoint.x + 25, infoPoint.y + 130);
        } else if (this.useBuoy2) {
          this.context.font = '13px "Tahoma", sans-serif';
          this.context.offsetFillText(this.buoy2, infoPoint.x + 5, infoPoint.y + 110);
          this.context.strokeStyle = this.DO2Color;
          this.context.beginPath();
          this.context.offsetMoveTo(infoPoint.x + 5, infoPoint.y + 130);
          this.context.offsetLineTo(infoPoint.x + 20, infoPoint.y + 130);
          this.context.stroke();
          this.context.font = '13px "Tahoma", sans-serif';
          this.context.offsetFillText((this.data[chosenLog].do[1]).toFixed(1) +
            ' ppm', infoPoint.x + 25, infoPoint.y + 130);
          this.context.strokeStyle = this.temp2Color;
          this.context.beginPath();
          this.context.offsetMoveTo(infoPoint.x + 5, infoPoint.y + 150);
          this.context.offsetLineTo(infoPoint.x + 20, infoPoint.y + 150);
          this.context.stroke();
          this.context.offsetFillText(build2, infoPoint.x + 25, infoPoint.y + 150);
        }
        break;
      }
    }
  }
  // </editor-fold>Hover information

  // <editor-fold>Button positions
  /* let buttons = document.getElementsByClassName('chartbtn');
  for (let j = 0; j < buttons.length; j++) {
      let nodeClasses = buttons[j].getAttribute('class');
      if ((nodeClasses.search('beginning') >= 0 || nodeClasses.search('end') >= 0)
              && nodeClasses.search(this.canvas.getAttribute('id'))) {
          buttons[j].style.top = (infoPoint.y + infoSize.height + 15) + 'px';
      } else if ((nodeClasses.search('zoomin') >= 0 || nodeClasses.search('zoomout') >= 0)
              && nodeClasses.search(this.canvas.getAttribute('id'))) {
          buttons[j].style.top = (infoPoint.y + infoSize.height + 70) + 'px';
      }
  } */
  // </editor-fold>Button positions
};
// end of definition for object Chart
