import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=a2bf19e4&scoped=true&"
import script from "./Home.vue?vue&type=script&lang=js&"
export * from "./Home.vue?vue&type=script&lang=js&"
import style0 from "./Home.vue?vue&type=style&index=0&id=a2bf19e4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2bf19e4",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QSlider from 'quasar/src/components/slider/QSlider.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QDialog,QCheckbox,QCard,QCardSection,QBadge,QSlider,QPageSticky});
