import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import CookieUtils from './lib/cookie-utils';
import FinishModal from './plugins/FinishResult';
import DeleteConfirm from './plugins/DeleteConfirm';
// Bootstrap
import './quasar';

// Importing the global custom colors
import '@/styles/colors.css';

Vue.use(FinishModal);
Vue.use(DeleteConfirm);
Vue.config.productionTip = false;

axios.defaults.withCredentials = true;

new Vue({
  router,
  store,
  render: h => h(App),
  data () {
    return {
      status: null,
      loggedIn: false,
      isCheckingStarted: false
    };
  },
  created () {
    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response.status === 401) {
          CookieUtils.redirectToAccounts();
        } else if (error.response.status === 404 && process.env.NODE_ENV !== 'production') {
          // noinspection JSForgottenDebugStatementInspection
          console.error('If this is in the dev environment, you may need to npm run serve in the api directory');
        }
        return Promise.reject(error);
      }
    );
  },
  methods: {}
}).$mount('#app');
