var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('q-dialog',{attrs:{"persistent":""},model:{value:(_vm.editNotifierModal),callback:function ($$v) {_vm.editNotifierModal=$$v},expression:"editNotifierModal"}},[_c('q-card',{staticClass:"q-pa-sm shadow-1",attrs:{"dark":"","square":"","bordered":""}},[_c('q-card-section',[_c('q-form',{staticClass:"q-gutter-sm",on:{"submit":_vm.onSubmit}},[_c('div',{staticClass:"row items-center"},[_c('q-toolbar',{staticClass:"bg-grey-9 text-white shadow-2",staticStyle:{"width":"310px"}},[_c('q-toolbar-title',{staticClass:"row justify-center items-center"},[_c('q-icon',{staticClass:"q-mr-sm",attrs:{"name":_vm.notifierCopy.icon,"size":"md"}}),_vm._v(" "+_vm._s(_vm.notifierCopy.type)+" ")],1)],1)],1),(_vm.showName)?_c('q-select',{attrs:{"dark":"","square":"","filled":"","options":_vm.userList,"emit-value":"","map-options":"","label":"User"},model:{value:(_vm.notifierCopy.user_id),callback:function ($$v) {_vm.$set(_vm.notifierCopy, "user_id", $$v)},expression:"notifierCopy.user_id"}}):_vm._e(),_c('q-input',{attrs:{"dark":"","square":"","filled":"","label":"Notifier Name","outlined":"","lazy-rules":"","hide-bottom-space":"","rules":[
              function (val) { return (val && val.length > 0) || 'Please enter Notifier Name'; }
            ]},model:{value:(_vm.notifierCopy.name),callback:function ($$v) {_vm.$set(_vm.notifierCopy, "name", $$v)},expression:"notifierCopy.name"}}),_c('q-select',{attrs:{"dark":"","square":"","filled":"","options":_vm.notifierTypes,"label":"Notifier Type"},on:{"input":function($event){_vm.setIcon(), _vm.clearTarget()}},model:{value:(_vm.notifierCopy.type),callback:function ($$v) {_vm.$set(_vm.notifierCopy, "type", $$v)},expression:"notifierCopy.type"}}),(_vm.notifierCopy.type === 'Email')?_c('q-input',{attrs:{"dark":"","square":"","filled":"","type":"email","label":"Email","outlined":""},model:{value:(_vm.notifierCopy.target),callback:function ($$v) {_vm.$set(_vm.notifierCopy, "target", $$v)},expression:"notifierCopy.target"}}):_vm._e(),(_vm.notifierCopy.type === 'Phone')?_c('q-input',{attrs:{"dark":"","square":"","filled":"","label":"Phone #","type":"tel","mask":"(###) ### - ####","unmasked-value":"","outlined":"","lazy-rules":"","hide-bottom-space":"","rules":[
              function (val) { return (val && val.length === 10) || 'Please enter valid phone #'; }
            ]},model:{value:(_vm.notifierCopy.target),callback:function ($$v) {_vm.$set(_vm.notifierCopy, "target", $$v)},expression:"notifierCopy.target"}}):_vm._e(),(_vm.notifierCopy.type === 'Text')?_c('q-input',{attrs:{"dark":"","square":"","filled":"","label":"Text #","type":"tel","mask":"(###) ### - ####","unmasked-value":"","outlined":"","lazy-rules":"","hide-bottom-space":"","rules":[
              function (val) { return (val && val.length === 10) || 'Please enter valid phone #'; }
            ]},model:{value:(_vm.notifierCopy.target),callback:function ($$v) {_vm.$set(_vm.notifierCopy, "target", $$v)},expression:"notifierCopy.target"}}):_vm._e(),(_vm.notifierCopy.type === 'Whatsapp')?_c('q-input',{attrs:{"dark":"","square":"","filled":"","label":"Whatsapp #","type":"tel","mask":"(###) ### - ####","unmasked-value":"","outlined":"","lazy-rules":"","hide-bottom-space":"","rules":[
              function (val) { return (val && val.length === 10) || 'Please enter valid phone #'; }
            ]},model:{value:(_vm.notifierCopy.target),callback:function ($$v) {_vm.$set(_vm.notifierCopy, "target", $$v)},expression:"notifierCopy.target"}}):_vm._e(),_c('div',{staticClass:"q-mt-md"},[_c('q-toggle',{attrs:{"color":"primary","label":"Active"},model:{value:(_vm.notifierCopy.active),callback:function ($$v) {_vm.$set(_vm.notifierCopy, "active", $$v)},expression:"notifierCopy.active"}})],1),_c('div',{staticClass:"q-mb-lg"},[_c('q-toggle',{attrs:{"color":"red-14","label":"Do Not Disturb"},model:{value:(_vm.notifierCopy.snooze),callback:function ($$v) {_vm.$set(_vm.notifierCopy, "snooze", $$v)},expression:"notifierCopy.snooze"}})],1),_c('div',{staticClass:"row items-center justify-center"},[_c('timer-set',{attrs:{"size":"280px","StartTime":[_vm.notifierCopy.snooze_start],"StopTime":[_vm.notifierCopy.snooze_end],"Periods":[true],"disabled":!_vm.notifierCopy.snooze,"snap15":"","backColor":"#1d1d1d","circleColor":"#C4B983","onColor":"#F00","handleColor":"#AD9E52"},on:{"onValueChanged":_vm.timerValueChanged}})],1),_c('div',{staticClass:"q-mt-lg"},[_c('div',{staticClass:"row full-width"},[_c('div',{staticClass:"col spaced"},[_c('q-btn',{staticClass:"full-width",attrs:{"label":"Cancel","color":"secondary"},on:{"click":_vm.cancel}})],1),_c('div',{staticClass:"col spaced"},[_c('q-btn',{staticClass:"full-width",attrs:{"label":"Submit","type":"submit","color":"primary"}})],1)])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }