// import axios from 'axios';
import Vue from 'vue';
import './../lib/typedef.js'; /* intellisense helper */
import { AlarmGroup, AlarmDefs, alarmPriorities } from 'alarm-defs';
import connector from '@/services/mqtt/connector';
/**
 *
 * @param {string} variable
 * @param {Number} index
 * @param {Number} bit
 * @param {pondDataItem} pond
 * @returns {string|*}
 */
const resolve = function (variable, index, bit, pond) {
  switch (variable) {
    case 'AeratorName':
      return pond.aerator_names[index];
    case 'AeratorBlowAmps':
      return pond.blown.Aerator[index].toFixed(1);
    case 'BuoyName':
      return pond.buoy_names[index];
    case 'TestBlowAmps':
      return pond.blown.Test[index].toFixed(2);
    case 'CalBlowAmps':
      return pond.blown.Cal[index].toFixed(2);
    case 'LastDoAlarmValue':
      return pond.alarms.last_do[index].toFixed(1);
    case 'LastIntDoAlarmValue':
      return pond.alarms.last_int_do[index].toFixed(1);
    case 'SeineModeHours':
      // TODO: change once back end is ready
      return '10 hours';
    case 'bit':
      return bit;
    default:
      return 'ERROR';
  }
};

const convertDate = function (/** @type number | Date */time) {
  if (typeof time === 'number') {
    return new Date(time * 1000);
  } else {
    return new Date(0);
  }
};

/**
 * @typedef _PondState
 * @property {boolean} pondDataLoading
 * @property {boolean} pondBarFlash
 * @property {pondDataItem[]} pondData
 * */

const PondStore = {
  /** @type _PondState */
  state: {
    pondDataLoading: true,
    pondBarFlash: false,
    pondData: []
  },
  mutations: {
    SET_POND_DELETE (state, value) {
      const index = state.pondData.findIndex(element => element.id === parseInt(value.params.pondId));
      if (index !== -1) {
        state.pondData.splice(index, 1);
      }
    },
    SET_POND_ITEM (state, value) {
      let index = state.pondData.findIndex(element => element.id === parseInt(value.params.pondId));
      if (index === -1) {
        index = state.pondData.length;
        Vue.set(state.pondData, index, {
          id: parseInt(value.params.pondId),
          ready: false
        });
      }
      switch (value.params.item) {
        case 'delete':
          // eslint-disable-next-line no-case-declarations
          const deleteIndex = state.pondData.findIndex(element => element.id === parseInt(value.params.pondId));
          if (deleteIndex !== -1) {
            state.pondData.splice(deleteIndex, 1);
          }
          break;
        case 'in_service':
        case 'harvest_buoy':
          // Bool items
          Vue.set(state.pondData[index], value.params.item, value.payload);
          break;
        case 'name':
        case 'ready':
        case 'radio_id':
        case 'position':
        case 'aerlink_device':
          Vue.set(state.pondData[index], value.params.item, value.payload);
          break;
        case 'ChartLogs':
          // eslint-disable-next-line no-case-declarations
          const ChartLogs = value.payload;
          for (let j = 0; j < ChartLogs.length; j++) {
            ChartLogs[j].datetime = convertDate(ChartLogs[j].datetime);
          }
          Vue.set(state.pondData[index], value.params.item, ChartLogs);
          break;
        default:
          value.payload.last_modified = convertDate(value.payload.last_modified);
          Vue.set(state.pondData[index], value.params.item, value.payload);
          break;
      }
    },
    SET_POND_CLEAN_FARM (state) {
      state.pondData = [];
    },
    /**
     *
     * @param {_PondState} state
     * @param {boolean} value
     * @constructor
     */
    SET_POND_LOADING (state, value) {
      state.pondDataLoading = value;
    },
    /**
     *
     * @param {_PondState} state
     * @constructor
     */
    SET_POND_TEST (state) {
      // state.pondData[0].ChartLogs[2].do[0] = parseInt(state.pondData[0].ChartLogs[2].do[0]) + 1;
      const newValue = state.pondData[0].ChartLogs[2].do[0] + 1.0;
      Vue.set(state.pondData[0].ChartLogs[2].do, 0, newValue);
      Vue.set(state.pondData[0], 'ChartLogsModified', Date.now());
      Vue.set(state.pondData[0].alarms.alarms, 2, 47);
    },
    /**
     *
     * @param {_PondState} state
     * @constructor
     */
    SET_POND_TEST_ACK (state) {
      Vue.set(state.pondData[0].acknowledge.alarms, 0, 1);
      Vue.set(state.pondData[0].acknowledge.alarms, 1, 2);
      Vue.set(state.pondData[0].acknowledge.alarms, 2, 47);
      Vue.set(state.pondData[0].acknowledge.alerts, 0, 5);

      Vue.set(state.pondData[1].acknowledge.alarms, 0, 3);
      Vue.set(state.pondData[1].acknowledge.alarms, 1, 4);
      Vue.set(state.pondData[1].acknowledge.alerts, 0, 7);
    }
  },
  actions: {
    async createNewPond ({ commit, rootGetters }, pond) {
      const loc = rootGetters.selectedFarmInfo.location_id || null;
      return connector.publish(
        `farm/${rootGetters.selectedFarmInfo.farm_id}/${loc}/ponds/${pond.id}/create/`,
        JSON.stringify(pond));
    },
    async updatePondProfile ({ commit, rootGetters }, pond) {
      const loc = rootGetters.selectedFarmInfo.location_id || null;
      return connector.publish(
        `farm/${rootGetters.selectedFarmInfo.farm_id}/${loc}/ponds/${pond.id}/update/`,
        JSON.stringify(pond));
    },
    async deletePond ({ commit, rootGetters }, pond) {
      const loc = rootGetters.selectedFarmInfo.location_id || null;
      return connector.publish(
        `farm/${rootGetters.selectedFarmInfo.farm_id}/${loc}/ponds/${pond.id}/delete/`,
        JSON.stringify(pond));
    },
    async setPondPosition ({ commit, rootGetters }, { pond, direction }) {
      const loc = rootGetters.selectedFarmInfo.location_id || null;
      return connector.publish(
        `farm/${rootGetters.selectedFarmInfo.farm_id}/${loc}/ponds/${pond.id}/position/`,
        JSON.stringify(direction));
    }
  },
  getters: {
    // https://vuex.vuejs.org/guide/getters.html#method-style-access
    getPondNameById: (state) => (id) => {
      const pond = state.pondData.find(pond => pond.id === id);
      return pond ? pond.name : '--';
    },
    /**
     *
     * @param {_PondState} state
     * @returns {function(number): pondDataItem}
     */
    getPondNameByIndex: (state) => (index) => {
      return state.pondData[index].name;
    },
    /**
     *
     * @param {_PondState} state
     * @returns {function(number): boolean}
     */
    getAeratorToggle: (state) => (index) => {
      for (let i = 6; i < state.pondData[index].Setpoints.Aerator.length; i++) {
        if (state.pondData[index].Setpoints.Aerator[i].ControlSet !== 0) {
          return true;
        }
      }
      return false;
    },
    /**
     * @typedef _BarMessage
     * @property {string} message
     * @property {string} backColor
     * @property {string} backFlash
     * @property {string} foreColor
     * @property {boolean} flash
     */

    /**
     *
     * @param {_PondState} state
     * @param { * } getters
     * @returns {_BarMessage}
     */
    pondsAlarmBarMessage (state, getters) {
      let viewExisting = false;
      for (let i = 0; i < state.pondData.length; i++) {
        const values = getters.getUnAcknowledged(i);
        if (values.alarms.length > 0 || values.alerts.length > 0 || values.server.length > 0) {
          // FIXME: `state.pondBarFlash = true` shouldn't really be in a getter
          state.pondBarFlash = true;
          return {
            message: 'View new alarms/alerts',
            backColor: 'red',
            backFlash: 'yellow',
            foreColor: 'black',
            flash: true
          };
        } else if (state.pondData[i].alarms.alarms.length > 0 ||
          state.pondData[i].alarms.alerts.length > 0 ||
          state.pondData[i].alarms.server.length > 0) {
          viewExisting = true;
        }
      }
      if (viewExisting) {
        state.pondBarFlash = false;
        return {
          message: 'View existing alarms/alerts',
          backColor: 'gray',
          backFlash: 'gray',
          foreColor: 'black',
          flash: false
        };
      }
      // Default
      state.pondBarFlash = false;
      return {
        message: 'No alarms/alerts',
        backColor: 'gray',
        backFlash: 'gray',
        foreColor: 'black',
        flash: false
      };
    },
    /**
     *
     * @param {_PondState} state
     * @returns {function(number, number, string): (string|String)}
     */
    getPondAlarmMessage: (state) => (index, bit, type) => {
      if (state.pondData.length === 0) {
        return '';
      }
      switch (type) {
        case 'alarm':
          return AlarmDefs().AlarmMessage(bit,
            /**
               * @param {string} variable
               * @param {number} _index
               * @param {number} _bit
               */
            function (variable, _index, _bit) {
              return resolve(variable, _index, _bit, state.pondData[index]);
            });
        case 'alert':
          return AlarmDefs().AlertMessage(bit,
            /**
             * @param {string} variable
             * @param {number} _index
             * @param {number} _bit
             */
            function (variable, _index, _bit) {
              return resolve(variable, _index, _bit, state.pondData[index]);
            });
        case 'server':
          return AlarmDefs().ServerMessage(bit,
            /**
             * @param {string} variable
             * @param {number} _index
             * @param {number} _bit
             */
            function (variable, _index, _bit) {
              return resolve(variable, _index, _bit, state.pondData[index]);
            });
        default:
          return 'Invalid type';
      }
    },
    /**
     *
     * @param {_PondState} state
     * @returns {function(number): AlarmGroup}
     */
    getUnAcknowledged: (state) => (index) => {
      return AlarmDefs().subtractGroup(
        new AlarmGroup(
          state.pondData[index].alarms.alarms,
          state.pondData[index].alarms.alerts,
          state.pondData[index].alarms.server),
        new AlarmGroup(
          state.pondData[index].acknowledge.alarms,
          state.pondData[index].acknowledge.alerts,
          state.pondData[index].acknowledge.server));
    },
    /**
     *
     * @param {_PondState} state
     * @returns {function(number): Boolean}
     */
    getCriticalAlarmFiltered: (state) => (index) => {
      return AlarmDefs().priorityFoundBetween(
        new AlarmGroup(
          state.pondData[index].alarms.alarms,
          state.pondData[index].alarms.alerts,
          state.pondData[index].alarms.server),
        alarmPriorities.critical_alarm_L4,
        alarmPriorities.critical_super_L7
      );
    },
    /**
     *
     * @param {_PondState} state
     * @returns {function(number): Boolean}
     */
    getAlarmFiltered: (state) => (index) => {
      return AlarmDefs().priorityFound(
        new AlarmGroup(
          state.pondData[index].alarms.alarms,
          state.pondData[index].alarms.alerts,
          state.pondData[index].alarms.server),
        alarmPriorities.level_1_alert_L3
      );
    },
    /**
     *
     * @param {_PondState} state
     * @returns {function(number): Boolean}
     */
    getAlertFiltered: (state) => (index) => {
      return AlarmDefs().priorityFound(
        new AlarmGroup(
          state.pondData[index].alarms.alarms,
          state.pondData[index].alarms.alerts,
          state.pondData[index].alarms.server),
        alarmPriorities.level_2_alert_L1
      );
    }
  },
  modules: {}
};

export default PondStore;
