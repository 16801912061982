<template>
  <div>
    <q-page class="flex flex-center">
      <q-card
        square
        bordered
        class="q-pa-lg shadow-1"
      >
      <q-card-section class="row justify-center">
        <h4 class="text-custom">Pond Indicator Size</h4>
      </q-card-section>
      <q-card-section class="row justify-center">
        <q-btn class="button"
          :style="buttonColor.smBackground"
          variant="outline-dark"
          @click="setSmall">
          Sm
        </q-btn>
        <q-btn class="button"
          :style="buttonColor.mdBackground"
          variant="outline-dark"
          @click="setMedium">
          Md
        </q-btn>
        <q-btn class="button"
          :style="buttonColor.lgBackground"
          variant="outline-dark"
          @click="setLarge">
          Lg
        </q-btn>
      </q-card-section>
        <div class="divider"></div>
        <h4 class="text-custom">Fahrenheit / Celsius</h4>
        <div class="left-align">
          <q-radio
            v-model="fcSelected.f"
            stacked
            name="some-radios"
            val="Fahrenheit"
            @change="changeTemp('Fahrenheit')">
            Fahrenheit
          </q-radio>
          <q-radio
            v-model="fcSelected.c"
            stacked
            name="some-radios"
            val="Celsius"
            @change="changeTemp('Celsius')">
            Celsius
          </q-radio>
        </div>
        <div class="row justify-center text-custom">
          <h4>Dark Mode</h4>
        </div>
        <div class="row justify-center">
          <q-toggle
            v-model="darkMode"
        />
        </div>
        <div class="q-pa-lg text-bold bg-test text-test">
          Test
        </div>
        <q-btn class="button"
          icon="add"
          color="test"
          >
        </q-btn>
      </q-card>
      </q-page>
  </div>
</template>

<script>

import store from './../store';

export default {
  name: 'ViewSettings',
  methods: {
    changeTemp: function (temperature) {
      store.dispatch('setTemperatureSetting', temperature);
    },
    setSmall: function () {
      store.dispatch('setPondIndicatorSize', 'small');
    },
    setMedium: function () {
      store.dispatch('setPondIndicatorSize', 'medium');
    },
    setLarge: function () {
      store.dispatch('setPondIndicatorSize', 'large');
    }
  },
  computed: {
    darkMode: {
      get () {
        return this.$q.dark.isActive;
      },
      set (value) {
        this.$q.dark.set(value);
      }
    },
    buttonColor () {
      let smBackground = { backgroundColor: '#efefef' };
      let mdBackground = { backgroundColor: '#efefef' };
      let lgBackground = { backgroundColor: '#efefef' };

      if (this.$store.state.userSettings.pondIndicatorSize === 'small') {
        smBackground = { backgroundColor: 'red' };
      }
      if (this.$store.state.userSettings.pondIndicatorSize === 'medium') {
        mdBackground = { backgroundColor: 'red' };
      }
      if (this.$store.state.userSettings.pondIndicatorSize === 'large') {
        lgBackground = { backgroundColor: 'red' };
      }

      const background = {
        smBackground: smBackground,
        mdBackground: mdBackground,
        lgBackground: lgBackground
      };

      return background;
    },
    fcSelected () {
      const selected = {};

      selected.f = '';
      selected.c = '';
      if (this.$store.state.userSettings.temperatureSetting === 'Fahrenheit') {
        selected.f = this.$store.state.userSettings.temperatureSetting;
      } else {
        selected.c = this.$store.state.userSettings.temperatureSetting;
      }

      return selected;
    }
  }
};
</script>

<style scoped>

  .button {
    width: 55px;
    color: black;
    margin: 5px;
  }

  .divider {
    margin-top: 90px;
  }

  .left-align {
    margin: 0 auto;
    text-align: left;
    width: 200px;
    display: table;
  }

</style>
