<template>
  <div>
    <q-btn id="alarm-indicator"
           class="full-width"
           :class="alarmBarClass">
      {{ barMessage }}
    </q-btn>

    <q-dialog v-model="card" id="alarm-modal">
             title="Alarms"
      :header-bg-variant="alarmModalStyle.headerBackground"
      :header-text-variant="alarmModalStyle.headerColor">
      <q-checkbox
        id="all-alarms"
        v-model="allAlarms"
        name="all-alarms"
        @change="checkAll">
        All Alarms
      </q-checkbox>
      <div class="divider-div"></div>
      <b-form-group>
        <b-form-checkbox-group
          id="checkbox-group-1"
          v-model="selected"
          :options="alarms.options"
          stacked
          class="second-level"
          @change="checkSingleAlarm">
        </b-form-checkbox-group>
      </b-form-group>
      <div class="divider-div"></div>
      <p class="acknowledged-header">Acknowledged Alarms...</p>
    </q-dialog>

    <q-dialog v-model="settings" position="top">
      <q-card style="width: 350px">
        <q-card-section class="row items-center no-wrap">
          <q-badge color="primary" outline>
            Pond Icon Size:
            <span class="pond-icon-size-label">
              {{ pondIconSizeLabel }}
            </span>
          </q-badge>
        </q-card-section>
        <q-card-section class="row items-center no-wrap">
          <q-slider v-model="pondIconSize" :min="1" :max="3" @input="iconSizeChange"/>
        </q-card-section>
      </q-card>
    </q-dialog>

    <div id="pond-component">
      <div id="pond-parent">
        <PondIcon
          v-for="(x, index) in pondData.length" :key="index"
          :pondInfo="pondData[index]"
          :aerator="aerator"
          :flash="flash.flashState"
          :index="index"/>
      </div>
    </div>
    <q-page-sticky position="bottom-right" :offset="[28, 28]">
      <q-btn fab icon="settings" color="accent" class="text-black" @click="setPondIconSize" />
    </q-page-sticky>
  </div>
</template>

<script>
import PondIcon from '@/components/PondIcon.vue';
import store from './../store';
// import mapGetters from 'vuex';

export default {
  name: 'Home',
  components: {
    PondIcon
  },
  data () {
    return {
      flash: {
        flashTimer: null,
        flashState: false,
        background: 'yellow',
        text: 'red'
      },
      aerator: {
        timer: null,
        secondSix: false
      },
      alarmModalStyle: {
        headerBackground: 'dark',
        headerColor: 'light'
      },
      allAlarms: false,
      pondIconSize: 2,
      pondIconSizeLabel: 'Md',
      selected: [],
      settings: false,
      card: false
    };
  },
  methods: {
    iconSizeChange () {
      if (this.pondIconSize === 1) {
        store.dispatch('setPondIndicatorSize', 'small');
        this.pondIconSizeLabel = 'Sm';
      } else if (this.pondIconSize === 2) {
        store.dispatch('setPondIndicatorSize', 'medium');
        this.pondIconSizeLabel = 'Md';
      } else if (this.pondIconSize === 3) {
        store.dispatch('setPondIndicatorSize', 'large');
        this.pondIconSizeLabel = 'Lg';
      }
    },
    setPondIconSize () {
      this.settings = true;
      const selectedSize = this.$store.state.userSettings.pondIndicatorSize;

      if (selectedSize === 'small') {
        this.pondIconSize = 1;
        this.pondIconSizeLabel = 'Sm';
      } else if (selectedSize === 'medium') {
        this.pondIconSize = 2;
        this.pondIconSizeLabel = 'Md';
      } else if (selectedSize === 'large') {
        this.pondIconSize = 3;
        this.pondIconSizeLabel = 'Lg';
      }
    },
    test () {
      this.$store.dispatch('testPondStore');
    },
    testAck () {
      this.$store.dispatch('testPondStore2');
    },
    alarmModal () {
      console.log('alarm modal');
    },
    checkAll () {
      this.$nextTick(() => {
        if (this.allAlarms === false) {
          this.selected = [];
        } else {
          this.selected = this.alarms.allSelected.slice();
        }
      });
    },
    checkSingleAlarm () {
      this.$nextTick(() => {
        console.log(this.selected);
      });
    },
    showSettings () {
      console.log('showing settings');
    },
    startFlash () {
      console.log('starting flash timer');
      if (this.flash.flashTimer === null) {
        this.flash.flashTimer = setInterval(() => this.switchFlash(), 900);
      }
    },
    stopFlash: function () {
      console.log('stopping timer');
      clearInterval(this.flash.flashTimer);
      this.flash.flashTimer = null;
    },
    switchFlash: function () {
      this.flash.flashState = !this.flash.flashState;
    },
    startAeratorDisplayTimer: function () {
      console.log('starting aerator switch timer');
      this.aerator.timer = setInterval(() => this.switchAeratorDisplay(), 4000);
    },
    stopAeratorDisplayTimer: function () {
      console.log('stopping timer');
      clearInterval(this.aerator.timer);
      this.aerator.timer = null;
    },
    switchAeratorDisplay: function () {
      if (this.aerator.secondSix === true) {
        this.aerator.secondSix = false;
      } else {
        this.aerator.secondSix = true;
      }
    }
  },
  computed: {
    alarms () {
      const returnObj = {};
      returnObj.options = [];
      returnObj.allSelected = [];
      // const colorOne = '#4f5963';
      // const colorTwo = '#212529';
      const colorOne = '#9999ff';
      const colorTwo = '#000066';
      let color = colorOne;
      // console.log(this.pondData);
      for (let i = 0; i < this.pondData.length; i++) {
        if (!this.$store.state.ponds.pondData[i].ready) {
          continue;
        }
        if (this.pondData[i].alarms.alarms.length) {
          if (color === colorOne) {
            color = colorTwo;
          } else {
            color = colorOne;
          }
          // TODO: extend to server and alerts as well
          for (let j = 0; j < this.pondData[i].alarms.alarms.length; j++) {
            const optionsObj = {};
            const Message = this.$store.getters.getPondAlarmMessage(i, this.pondData[i].alarms.alarms[j], 'alarm');
            optionsObj.html = `<b style="color: ${color}">${this.pondData[i].name}</b> - ${Message}`;
            optionsObj.value = this.pondData[i].alarms.alarms[j] + ' - ' + this.pondData[i].name;
            returnObj.options.push(optionsObj);
            // This is necessary for checkAll to work
            returnObj.allSelected.push(optionsObj.value);
            // returnObj.allSelected.push(this.pondData[i].alarms.alarms[j]);
          }
        }
      }
      return returnObj;
    },
    pondData () {
      const filtered = [];
      for (let i = 0; i < this.$store.state.ponds.pondData.length; i++) {
        if (this.$store.state.ponds.pondData[i].ready) {
          filtered.push(this.$store.state.ponds.pondData[i]);
        }
      }
      filtered.sort(function (a, b) {
        return a.position - b.position;
      });
      return filtered;
    },
    alarmBarClass () {
      switch (this.barState) {
        case 'NEW':
          if (this.flash.flashState) {
            return 'alarm-bar-new-flash';
          } else {
            return 'alarm-bar-new';
          }
        case 'EXISTING':
          return 'alarm-bar-existing';
        default:
        case 'NONE':
          return 'alarm-bar-none';
      }
    },
    barMessage () {
      switch (this.barState) {
        case 'NEW':
          return 'View new alarms/alerts';
        case 'EXISTING':
          return 'View alarms/alerts';
        default:
        case 'NONE':
          return 'No alarms/alerts';
      }
    },
    barState () {
      let ret = 'NONE';
      for (let i = 0; i < this.$store.state.ponds.pondData.length; i++) {
        if (!this.$store.state.ponds.pondData[i].ready) {
          continue;
        }
        const values = this.$store.getters.getUnAcknowledged(i);
        if (values.alarms.length > 0 || values.alerts.length > 0 || values.server.length > 0) {
          return 'NEW';
        } else if (this.$store.state.ponds.pondData[i].alarms.alarms.length > 0 ||
          this.$store.state.ponds.pondData[i].alarms.alerts.length > 0 ||
          this.$store.state.ponds.pondData[i].alarms.server.length > 0) {
          ret = 'EXISTING';
        }
      }
      return ret;
    }
  },
  mounted () {
    this.startFlash();
    this.startAeratorDisplayTimer();
  },
  beforeDestroy () {
    this.stopFlash();
    this.stopAeratorDisplayTimer();
  }
};
</script>

<style scoped>

  .alarm-bar-new {
    background-color: red;
    color: black;
  }

  .alarm-bar-new-flash {
    background-color: yellow;
    color: red;
  }

  .alarm-bar-existing {
    background-color: gray;
    color: #000000;
  }

  .alarm-bar-none {
    background-color: gray;
    color: black;
  }

  .acknowledged-header {
    font-size: 20px;
    margin: 20px;
  }

  #alarm-indicator {
    /*color: red;
    background-color: #dad9d8;*/
    cursor: pointer;
    outline: none;
  }

  .divider-div {
    width: 100%;
    padding: 5px;
    margin-bottom: 5px;
    /* border-bottom: solid 1px #343a40; */
    border-bottom: solid 1px #ee0000;
  }

  .overview {
    width: 100%;
    min-height: calc(100vh - var(--below-nav-value));
    background-color: #969492;
  }

  .overview-inner {
    height: calc(100vh - var(--below-nav-value));
    overflow: auto;
  }

  #pond-component {
    text-align: center;
  }

  .pond-icon-size-label {
    width: 23px;
    padding-left: 5px;
  }

  #pond-parent {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(250px, max-content) );
    justify-content: center;
    justify-items: center;
    padding: 50px 20px 0 20px;
    width: 100%;
  }

  .second-level {
    padding-left: 15px;
  }

</style>
