<template>
  <div>
    <q-dialog v-model="checkMark" persistent>
      <q-avatar icon="check_circle_outline" text-color="green" size="12rem"
                style="box-shadow: none!important;"/>
    </q-dialog>
    <q-dialog v-model="retryModal" persistent>
      <q-card class="bg-dark text-white min-width">
        <q-card-section class="row items-center">
          <q-avatar icon="error" color="negative" text-color="white"/>
          <span class="q-ml-sm">Error: {{ message }}</span>
        </q-card-section>
        <q-card-section class="row items-center">
          <span class="q-ml-sm">Retry or Cancel changes?</span>
        </q-card-section>
        <q-card-actions align="center">
          <div class="row full-width">
            <div class="col spaced">
              <q-btn class="full-width spaced" label="Cancel" color="secondary" v-close-popup @click="confirmCancel"/>
            </div>
            <div class="col spaced">
              <q-btn class="full-width" label="Retry" color="primary" v-close-popup @click="confirmRetry"/>
            </div>
          </div>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import FinishModal from './FinishResult';
import {
  Loading,
  QSpinnerBars
} from 'quasar';

export default {
  name: 'FinishResult',
  data () {
    return {
      checkMark: false,
      retryModal: false,
      onRetry: null,
      onFail: null,
      message: ''
    };
  },
  methods: {
    confirmCancel () {
      this.retryModal = false;
      // this.loading = false;
      this.onFail();
    },
    confirmRetry () {
      this.retryModal = false;
      // this.loading = false;
      this.onRetry();
    },
    show () {
      Loading.show({
        spinner: QSpinnerBars,
        spinnerColor: 'primary',
        messageColor: 'primary',
        message: 'Saving changes'
      });
    },
    handleResultsAsync (results, resolve, reject) {
      this.onFail = function () {
        resolve('fail');
      };
      this.onRetry = function () {
        resolve('retry');
      };
      if (results.sent === false || results.reasonCode !== 0) {
        this.message = results.error;
        this.retryModal = true;
        Loading.hide();
      } else {
        this.checkMark = true;
        Loading.hide();
        setTimeout(function (that) {
          that.checkMark = false;
          // that.loading = false;
          Loading.hide();
          resolve('success');
        }, 500, this);
      }
    },
    handleResults (results, onSuccess, onRetry, onFail) {
      this.onRetry = onRetry;
      this.onFail = onFail;
      if (results.sent === false) {
        this.message = results.error;
        this.retryModal = true;
        Loading.hide();
      } else {
        this.checkMark = true;
        Loading.hide();
        setTimeout(function (that) {
          that.checkMark = false;
          // that.loading = false;
          Loading.hide();
          onSuccess();
        }, 500, this);
      }
    }
  },
  beforeMount () {
    // here we need to listen for emited events
    // we declared those events inside our plugin
    FinishModal.EventBus.$on('show', (params) => {
      this.show(params);
    });
    FinishModal.EventBus.$on('handleResults', (results, onSuccess, onRetry, onFail) => {
      this.handleResults(results, onSuccess, onRetry, onFail);
    });
    FinishModal.EventBus.$on('handleResultsAsync', (results, resolve, reject) => {
      this.handleResultsAsync(results, resolve, reject);
    });
  }
};
</script>

<style scoped>

.min-width {
  min-width: 300px;
}

.spaced {
  margin-left: 2px;
  margin-right: 2px;
}

</style>
