<template>
  <div>
    <canvas tabindex=0 ref="canvas" :width="size" :height="size" style="touch-action: pan-y;"></canvas>
  </div>
</template>

<script>
import { Rectangle } from '@/lib/drawing/Rectangle';
import { Point } from '@/lib/drawing/Point';
import { Size } from '@/lib/drawing/Size';
import '@/lib/drawing/GdiExtensions';

const defaultOptions = {
  circleColor: '#C4B983',
  backColor: '#FFF',
  onColor: '#D50000',
  handleColor: '#C48E73',
  textColor: '#000'
};

const GetCos = function (degAngle) {
  return Math.cos(Math.PI * degAngle / 180);
};

const GetSin = function (degAngle) {
  return Math.sin(Math.PI * degAngle / 180);
};

const Timer = function (canvas, event, options, initial) {
  this.canvas = canvas;
  this.context = canvas.getContext('2d', { alpha: false });
  this.width = canvas.width;
  this.height = canvas.height;
  this.IsMouseDown = false;
  this.IsHandleMoving = false;
  this.HandleMoving = 0;
  this.onChanged = event;
  this.StartTime = initial ? initial.StartTime : [];
  this.StopTime = initial ? initial.StopTime : [];
  this.Enable = initial ? initial.Enable : [];
  this.options = options || {};

  const HandlePoints = []; // 6
  const that = this;

  const Periods = {
    On1: 0,
    On2: 1,
    On3: 2,
    Off1: 3,
    Off2: 4,
    Off3: 5
  };

  const GetCenter = function (degAngle, radius) {
    let x = GetCos(degAngle + 90) * radius;
    let y = GetSin(degAngle + 90) * radius;
    x = -x + (that.width / 2);
    y = -y + (that.height / 2);
    return new Point(x, y);
  };

  const XYToDegrees = function (xy, origin) {
    let angle = 0.0;
    if (xy.y < origin.y) {
      if (xy.x > origin.x) {
        angle = (xy.x - origin.x) / (origin.y - xy.y);
        angle = Math.atan(angle);
        angle = angle * 180.0 / Math.PI;
      } else if (xy.x < origin.x) {
        angle = (origin.x - xy.x) / (xy.y - origin.y);
        angle = Math.atan(angle);
        angle = 360 + angle * 180.0 / Math.PI;
      } else if (xy.x === origin.x) {
        return 0;
      }
    } else if (xy.y > origin.y) {
      if (xy.x > origin.x) {
        angle = (origin.x - xy.x) / (xy.y - origin.y);
        angle = Math.atan(angle);
        angle = 180 + angle * 180.0 / Math.PI;
      } else if (xy.x < origin.x) {
        angle = (xy.x - origin.x) / (origin.y - xy.y);
        angle = Math.atan(angle);
        angle = 180 + angle * 180.0 / Math.PI;
      } else if (xy.x === origin.x) {
        return 180;
      }
    } else if (xy.y === origin.y) {
      if (xy.x > origin.x) {
        return 90;
      } else {
        return 270;
      }
    }
    return angle;
  };

  const localPosition = function (event) {
    const bRect = that.canvas.getBoundingClientRect();
    if (event.type.indexOf('touch') > -1) {
      return new Point(event.touches[0].clientX - bRect.left, event.touches[0].clientY - bRect.top);
    } else {
      return new Point(event.clientX - bRect.left, event.clientY - bRect.top);
    }
  };

  const TouchDown = function (event) {
    if (that.options.disabled) {
      return;
    }
    that.IsMouseDown = true;
    const Location = localPosition(event);
    for (let i = 0; i < 3; i++) {
      if (that.Enable[i]) {
        if (HandlePoints[i].on.circleContains(Location, 2.5)) {
          event.preventDefault && event.preventDefault();
          event.stopPropagation && event.stopPropagation();
          return false;
        } else if (HandlePoints[i].off.circleContains(Location, 2.5)) {
          event.preventDefault && event.preventDefault();
          event.stopPropagation && event.stopPropagation();
          return false;
        }
      }
    }
  };

  const TouchUp = function (event) {
    that.IsMouseDown = false;
    that.IsHandleMoving = false;
    that.draw();
    return false;
  };

  const MouseDown = function (event) {
    if (that.options.disabled) {
      return;
    }
    if (event.which === 1) {
      that.IsMouseDown = true;
    }
  };

  const MouseUp = function (event) {
    if (event.which === 1) {
      that.IsMouseDown = false;
      that.IsHandleMoving = false;
      that.draw();
    }
  };

  const OnValueChanged = function () {
    that.onChanged();
  };

  const TouchMove = function (event) {
    MouseMove(event);
    if (that.IsHandleMoving) {
      event.preventDefault && event.preventDefault();
      event.stopPropagation && event.stopPropagation();
      return false;
    }
  };

  const MouseMove = function (event) {
    if (that.IsMouseDown) {
      const Location = localPosition(event);
      if (!that.IsHandleMoving) {
        // Search for closest touch
        for (let s = 0.5; s < 3.0; s += 0.5) {
          if (that.IsHandleMoving) {
            break;
          }
          for (let i = 0; i < 3; i++) {
            if (that.Enable[i]) {
              if (HandlePoints[i].on.circleContains(Location, s)) {
                that.HandleMoving = Periods.On1 + i;
                that.draw();
                that.IsHandleMoving = true;
                break;
              } else if (HandlePoints[i].off.circleContains(Location, s)) {
                that.HandleMoving = Periods.Off1 + i;
                that.draw();
                that.IsHandleMoving = true;
                break;
              }
            }
          }
        }
      } else {
        const Center = new Point(that.width / 2, that.height / 2);
        const Degree = XYToDegrees(Location, Center);
        let TD = Math.floor((Degree / 1.25));
        if (that.options.snap15) {
          TD = Math.floor(TD / 3) * 3;
        }
        if (!that.Enable[1]) {
          switch (that.HandleMoving) {
            case Periods.Off1:
              if (that.StopTime[0] !== TD) {
                that.StopTime[0] = TD;
                that.draw();
                OnValueChanged();
              }
              return;
            case Periods.On1:
              if (!that.options.lockStartHandle) {
                if (that.StartTime[0] !== TD) {
                  that.StartTime[0] = TD;
                  that.draw();
                  OnValueChanged();
                }
              }
          }
        } else {
          switch (that.HandleMoving) {
            case Periods.Off1:
              if ((TD > that.StartTime[0]) && (TD < that.StartTime[1])) {
                if (that.StopTime[0] !== TD) {
                  that.StopTime[0] = TD;
                  that.draw();
                  OnValueChanged();
                }
              }
              return;
            case Periods.On1:
              if ((TD >= 0) && (TD < that.StopTime[0])) {
                if (that.StartTime[0] !== TD) {
                  that.StartTime[0] = TD;
                  that.draw();
                  OnValueChanged();
                }
              }
              return;
            case Periods.Off2:
              if ((TD > that.StartTime[1]) && (TD < that.StartTime[2])) {
                if (that.StopTime[1] !== TD) {
                  that.StopTime[1] = TD;
                  that.draw();
                  OnValueChanged();
                }
              }
              return;
            case Periods.On2:
              if ((TD > that.StopTime[0]) && (TD < that.StopTime[1])) {
                if (that.StartTime[1] !== TD) {
                  that.StartTime[1] = TD;
                  that.draw();
                  OnValueChanged();
                }
              }
              return;
            case Periods.Off3:
              if ((TD > that.StartTime[2]) && (TD <= 288)) {
                if (that.StopTime[2] !== TD) {
                  that.StopTime[2] = TD;
                  that.draw();
                  OnValueChanged();
                }
              }
              return;
            case Periods.On3:
              if ((TD > that.StopTime[1]) && (TD < that.StopTime[2])) {
                if (that.StartTime[2] !== TD) {
                  that.StartTime[2] = TD;
                  that.draw();
                  OnValueChanged();
                }
              }
          }
        }
      }
    }
  };

  this.draw = function () {
    const icon = this.width < 125;
    try {
      this.context.globalAlpha = 1.0;
      this.context.fillStyle = this.options.backColor;
      this.context.lineWidth = 1;
      this.context.fillRect(0, 0, this.width, this.height);
      const HoursFont = (this.height / 20) + 'px "Arial"';
      const IconFont = (this.height / 10) + 'px "Arial"';
      const GraphColor = 'brown';
      const Outside = new Rectangle(
        (this.width * 0.01), (this.height * 0.01),
        (this.width * 0.98), (this.height * 0.98));
      // Disabled gray out
      if (this.options.disabled) {
        this.context.FillEllipse('#555', Outside);
        this.context.globalAlpha = 0.5;
      }
      this.context.FillEllipse(this.options.circleColor, Outside);
      if (this.options.lockedStart) {
        this.StartTime[0] = (new Date().getHours() * 12) + new Date().getMinutes() / 5;
      }

      for (let z = 0; z < 3; z++) {
        let startD, sweep;
        if (this.Enable[z]) {
          if (this.StopTime[z] > this.StartTime[z]) {
            startD = ((this.StopTime[z] * 1.25) - 90);
            sweep = ((this.StartTime[z] - this.StopTime[z]) * 1.25);
          } else {
            startD = ((this.StartTime[z] * 1.25) - 90);
            sweep = (((this.StopTime[z]) * 1.25) + (270 - startD));
          }
          this.context.FillPie(this.options.onColor, Outside, startD, sweep);
        }
      }

      // Outer ring
      this.context.DrawArc(1, '#888', Outside, 0, 360);

      // let x, y;
      let tPoint, aPoint;
      let deg = 360 / 288;
      const spacing = icon ? 6 : 3;
      for (let i = 0; i < 288; i += spacing) {
        tPoint = GetCenter(i * deg, Outside.width / 2);
        if (i % 12 === 0) {
          aPoint = GetCenter(i * deg, Outside.width / 2 - 8);
        } else if (i % 6 === 0) {
          aPoint = GetCenter(i * deg, Outside.width / 2 - 4);
        } else {
          aPoint = GetCenter(i * deg, Outside.width / 2 - 2);
        }
        this.context.DrawLine('#555', 1, tPoint, aPoint);
      }

      deg = 360 / 288;
      let point1, point2;
      let FaceRadius = (this.width * 0.42);// size of the clock face
      const MouseSize = new Size(this.width * 0.1, this.width * 0.1);
      // this locates the handles
      for (let i = 0; i < 3; i++) {
        point1 = GetCenter(this.StartTime[i] * deg, FaceRadius);
        point2 = GetCenter(this.StopTime[i] * deg, FaceRadius - MouseSize.width);
        HandlePoints[i] = {
          on: new Rectangle(
            new Point(point1.x - MouseSize.width / 2, point1.y - MouseSize.height / 2),
            MouseSize),
          off: new Rectangle(
            new Point(point2.x - MouseSize.width / 2, point2.y - MouseSize.height / 2),
            MouseSize)
        };
      }

      const centerPoint = new Point(this.width / 2, this.height / 2);
      FaceRadius = (this.width * 0.42);// size of the clock face
      if (!this.options.readOnly) {
        for (let z = 0; z < 3; z++) {
          if (this.Enable[z]) {
            if (!this.options.lockStartHandle) {
              this.context.DrawLine('#222', 1.5, centerPoint,
                GetCenter(this.StartTime[z] * 360 / 288, Outside.radius));
            }
            this.context.DrawLine('#222', 1.5, centerPoint,
              GetCenter(this.StopTime[z] * 360 / 288, Outside.radius));
            this.context.lineWidth = 1;
            const st = HandlePoints[z].on;
            const sp = HandlePoints[z].off;
            this.context.FillEllipse(this.options.handleColor, st);
            this.context.FillEllipse(this.options.handleColor, sp);
          }
        }
      } else {
        for (let z = 0; z < 3; z++) {
          if (this.Enable[z]) {
            this.context.DrawLine('#222', 1, centerPoint,
              GetCenter(this.StartTime[z] * 360 / 288, Outside.radius));
            this.context.DrawLine('#222', 1, centerPoint,
              GetCenter(this.StopTime[z] * 360 / 288, Outside.radius));
          }
        }
      }

      deg = 360 / 24;
      FaceRadius = icon ? (this.width * 0.34) : (this.width * 0.42); // size of the clock face
      // Draw hours on the clock face
      for (let i = 0; i < 24; i += icon ? 2 : 1) {
        // i = hour  30 degrees = offset per hour
        tPoint = GetCenter(i * deg, FaceRadius);
        tPoint.y += (this.height / 25 / 4 + 1);
        this.context.font = icon ? IconFont : HoursFont;
        this.context.textAlign = 'center';
        this.context.fillStyle = this.options.textColor;
        this.context.offsetFillText(i, tPoint.x, tPoint.y);
      }

      FaceRadius = (this.width * 0.26);
      let Minute_count = (new Date().getHours() * 60) + new Date().getMinutes();
      point1 = GetCenter((Minute_count / 4), FaceRadius);
      // draw hour hand
      this.context.DrawLine('#000', icon ? 2 : 4, point1, centerPoint);
      // draw minute hand
      FaceRadius = (this.width * 0.37);
      Minute_count = (new Date().getMinutes() * 6) + new Date().getSeconds() / 10;
      point1 = GetCenter((Minute_count), FaceRadius);
      this.context.DrawLine(GraphColor, icon ? 1 : 2, point1, centerPoint);

      if (this.IsHandleMoving) {
        const helperLoc = new Rectangle(
          new Point((this.width / 2) - 25, (this.height / 2) - 25),
          new Size(50, 50));
        this.context.FillEllipse(this.options.handleColor, helperLoc);
        let minutes;
        if (that.HandleMoving >= Periods.Off1) {
          minutes = (this.StopTime[that.HandleMoving % 3] * 5);
        } else {
          minutes = (this.StartTime[that.HandleMoving] * 5);
        }
        const h = Math.floor(minutes / 60);
        const m = minutes % 60;
        let text = '';
        if (h < 10) {
          text = '0';
        }
        text += h + ':';
        if (m < 10) {
          text += '0';
        }
        text += m;
        this.context.DrawString(text, HoursFont, this.options.textColor, helperLoc, 'center');
      } else {
        const cSize = icon ? 6 : 10;
        this.context.FillEllipse(this.options.textColor,
          new Rectangle(
            new Point((this.width / 2) - (cSize / 2), (this.height / 2) - (cSize / 2)),
            new Size(cSize, cSize)));
      }
    } catch (error) {
      console.log(error);
    }
  };

  this.draw();
  if (!this.options.readOnly) {
    this.canvas.addEventListener('mousemove', MouseMove);
    this.canvas.addEventListener('mousedown', MouseDown);
    this.canvas.addEventListener('mouseup', MouseUp);
    this.canvas.addEventListener('touchstart', TouchDown);
    this.canvas.addEventListener('touchend', TouchUp);
    this.canvas.addEventListener('touchmove', TouchMove);
  }
};

export default {
  name: 'timer-set',
  props: {
    readOnly: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    lockStartHandle: {
      type: Boolean
    },
    lockedStart: {
      type: Boolean
    },
    circleColor: {
      type: String
    },
    backColor: {
      type: String
    },
    onColor: {
      type: String
    },
    handleColor: {
      type: String
    },
    textColor: {
      type: String
    },
    StartTime: {
      type: Array,
      required: true
    },
    StopTime: {
      type: Array,
      required: true
    },
    Periods: {
      type: Array,
      required: true
    },
    snap15: {
      type: Boolean
    },
    size: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      timer: null,
      interval: null,
      ready: false
    };
  },
  methods: {
    getOpts () {
      return {
        readOnly: this.readOnly,
        lockStartHandle: this.lockStartHandle,
        lockedStart: this.lockedStart,
        circleColor: this.circleColor ? this.circleColor : defaultOptions.circleColor,
        backColor: this.backColor ? this.backColor : defaultOptions.backColor,
        onColor: this.onColor ? this.onColor : defaultOptions.onColor,
        handleColor: this.handleColor ? this.handleColor : defaultOptions.handleColor,
        textColor: this.textColor ? this.textColor : defaultOptions.textColor,
        snap15: this.snap15,
        disabled: this.disabled
      };
    },
    getTime () {
      return {
        StartTime: this.StartTime,
        StopTime: this.StopTime,
        Enable: this.Periods
      };
    },
    updateOpts () {
      if (this.timer) {
        this.timer.options = this.getOpts();
        this.timer.StartTime = this.StartTime;
        this.timer.StopTime = this.StopTime;
        this.timer.Enable = this.Periods;
      }
    },
    updateTimer () {
      this.timer.draw();
    },
    onValueChanged () {
      this.$emit('onValueChanged', {
        StartTime: this.timer.StartTime,
        StopTime: this.timer.StopTime
      });
    }
  },
  mounted () {
    this.timer = new Timer(
      this.$refs.canvas,
      this.onValueChanged,
      this.getOpts(),
      this.getTime());
    this.ready = true;
    this.interval = setInterval(this.updateTimer, 15000);
  },
  destroyed () {
    clearInterval(this.updateTimer);
  },
  watch: {
    $props: {
      handler () {
        this.updateOpts();
        if (this.ready) {
          this.timer.draw();
        }
      },
      deep: true,
      immediate: true
    }
  }
};

</script>

<style scoped>
canvas {
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */
}
</style>
