<template>
  <div class="full-width">
    <ConfirmDialog
      v-if="confirmDelete"
      :icon="customIcon(this.notifier.type.toLowerCase())"
      btnLabel="Delete"
      btnIcon="delete"
      btnColor="negative"
      message="Are you sure you want to delete notifier?"
      :headerMessage="notifier.type"
      @canceled="confirmDelete = false"
      @confirmed="onDelete"
    />

    <EditModal
      v-if="editNotifierModal"
      :notifier="notifier"
      :showName="showName"
      @cancelEdit="cancelEdit"
      @formSubmitted="formSubmitted"
    >
    </EditModal>
    <ActionModal
      v-if="editActionModal"
      @cancelEdit="cancelEdit"
      @formSubmitted="actionSubmitted"
      :notifier="notifier">
    </ActionModal>
      <div class="q-pa-md q-mb-xl row">
        <div
          v-for="(notifier, index) in UserNotifierCopy"
          :key="index"
          class="q-pa-sm col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <q-card square bordered class="q-pa-lg" style="min-width: 280px; max-width: 500px;">
            <div>
              <div class="row items-center">
                <q-toolbar v-if="!notifier.active" class="bg-negative text-black shadow-2">
                  <q-toolbar-title class="row justify-center items-center">
                    <q-icon v-if="!notifier.active"
                            name="notifications_off" size="md" class="q-mr-sm"/>
                    <q-icon v-if="notifier.active"
                      :name="customIcon(notifier.type.toLowerCase())"
                      size="md" class="q-mr-sm"/>
                    {{ formatTarget(notifier.target) }}
                  </q-toolbar-title>
                </q-toolbar>
                <q-toolbar v-if="notifier.active" class="bg-grey-6 text-black shadow-2">
                  <q-toolbar-title class="row justify-center items-center">
                    <q-icon v-if="!notifier.active"
                      color="red-13"
                      name="notifications_off" size="md" class="q-mr-sm"/>
                    <q-icon v-if="notifier.active"
                      :name="customIcon(notifier.type.toLowerCase())"
                      size="md" class="q-mr-sm"/>
                    {{ formatTarget(notifier.target) }}
                  </q-toolbar-title>
                </q-toolbar>
              </div>
              <q-list bordered class="full-width">
                <template v-if="showName">
                  <q-item class="q-ml-sm">
                    <div class="row justify-center items-center">
                      <q-icon name="person" size="md" class="q-mr-sm" color="white"/>
                      <div class="q-ml-md">
                        {{ userName(notifier.user_id) }}
                      </div>
                    </div>
                  </q-item>
                  <q-separator />
                </template>
                <q-item class="q-ml-sm">
                  <div class="row justify-center items-center">
                    <q-avatar color="indigo" text-color="white" size="md">
                      {{ notifier.name.charAt(0) }}
                    </q-avatar>
                    <div class="q-ml-md">
                      Notifier Name
                      <div class="text-caption text-grey-7">
                      {{ notifier.name }}
                      </div>
                    </div>
                  </div>
                </q-item>
                <q-separator />

                <q-item class="q-my-sm">
                  <q-item-section avatar>
                    <div v-if="notifier.snooze">Do not disturb from
                      <br>
                      <timer-format :time="notifier.snooze_start"></timer-format>
                      to
                      <timer-format :time="notifier.snooze_end"></timer-format>
                    </div>
                    <div v-if="!notifier.snooze">Send any time</div>
                  </q-item-section>
                  <q-space/>
                  <q-item-section side>
                    <timer-set
                      size="100px"
                      :StartTime="[notifier.snooze_start]"
                      :StopTime="[notifier.snooze_end]"
                      :Periods="[true]"
                      :disabled="!notifier.snooze"
                      readOnly
                      backColor="#1D1D1D"
                      circleColor="#C4B983"
                      onColor="#EE0000"
                      handleColor="#AD9E52">
                    </timer-set>
                  </q-item-section>
                </q-item>
                <q-separator  />

                <q-item class="q-my-xs">
                  <div class="row justify-center items-center full-width">
                      <q-btn
                        icon="send"
                        label="Test"
                        class="q-mr-sm"
                        outline
                        padding="xs sm"
                        color="#e6e6e5"
                        text-color="secondary"
                        size="sm"
                        @click="sendTest(notifier)"
                      />
                    <q-space />
                    <div>
                      <q-btn
                        v-if="showActionEdit"
                        icon="settings"
                        class="q-mr-sm"
                        outline
                        padding="xs sm"
                        color="#e6e6e5"
                        text-color="secondary"
                        size="sm"
                        @click="editAction(notifier)">
                      </q-btn>
                      <q-btn
                        icon="edit"
                        class="q-mr-sm"
                        outline
                        padding="xs sm"
                        color="#e6e6e5"
                        text-color="primary"
                        size="sm"
                        @click="editNotifier(notifier)">
                      </q-btn>
                      <q-btn
                        icon="delete"
                        outline
                        color="#e6e6e5"
                        text-color="negative"
                        padding="xs sm"
                        size="sm"
                        @click="deleteNotifier(notifier)">
                      </q-btn>
                    </div>
                  </div>
                </q-item>
              </q-list>
            </div>
            <div class="row q-ml-xs">
              <q-space/>
            </div>
          </q-card>
        </div>
      </div>

    <q-page-sticky v-if="!editNotifierModal" position="bottom-right" :offset="[28, 28]">
      <q-btn fab icon="add" color="accent" @click="addRecipient"/>
    </q-page-sticky>
  </div>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import EditModal from '@/components/Notifications/RecipientsModal.vue';
import TimerSet from '@/components/TimerSet.vue';
import TimerFormat from '@/components/TimerFormat.vue';
import ActionModal from '@/components/Notifications/ActionModal.vue';
import { alarmNames, alertNames, serverNames } from 'alarm-defs';

export default {
  components: {
    ConfirmDialog,
    EditModal,
    TimerSet,
    TimerFormat,
    ActionModal
  },
  props: {
    /** @type NotifierSettings[]|vue-prop */
    UserNotifierCopy: {
      type: Array,
      required: true
    },
    showName: {
      type: Boolean
    },
    showActionEdit: {
      type: Boolean
    }
  },
  data () {
    return {
      addingRecipient: false,
      confirmDelete: false,
      confirmIcon: '',
      confirmMessage: '',
      doNotDisturb: true,
      editNotifierModal: false,
      editActionModal: false,
      notifier: {},
      notifierActive: true
    };
  },
  computed: {},
  methods: {
    userName (id) {
      return this.$store.getters.getUserNameById(id);
    },
    customIcon (type) {
      if (type === 'text') {
        return 'fas fa-sms';
      } else if (type === 'whatsapp') {
        return 'fab fa-whatsapp';
      }
      return type;
    },
    addRecipient () {
      this.addingRecipient = true;
      this.editNotifierModal = true;
      this.notifier = {
        id: 'new',
        alarms: [],
        active: true,
        snooze: false,
        snooze_end: 36,
        snooze_start: 264,
        target: '',
        type: 'Email'
      };
      for (let i = alarmNames.AeratorLow; i < alarmNames.FutureAlarms; i++) {
        this.notifier.alarms[i] = true;
      }
      for (let i = alertNames.AeratorMid; i < alertNames.FutureAlerts; i++) {
        this.notifier.alarms[i] = true;
      }
      for (let i = serverNames.Maintenance; i < serverNames.FutureServer; i++) {
        this.notifier.alarms[i] = true;
      }
    },
    cancelEdit () {
      this.editNotifierModal = false;
      this.editActionModal = false;
    },
    onDelete () {
      this.confirmDelete = false;
      this.$emit('onDeleteNotifier', this.notifier);
    },
    deleteNotifier (notifier) {
      this.confirmDelete = true;
      this.notifier = notifier;
    },
    editNotifier (notifier) {
      notifier.addingRecipient = false;
      this.notifier = notifier;
      this.editNotifierModal = true;
    },
    editAction (notifier) {
      this.editActionModal = true;
      this.notifier = notifier;
    },
    formatTarget (target) {
      // If tel
      if (!target.includes('@') && target.length === 10) {
        return target.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      }
      // If email
      return target;
    },
    formSubmitted (notifier) {
      notifier.addingRecipient = this.addingRecipient;
      this.editNotifierModal = false;
      this.$emit('formSubmit', notifier);
    },
    actionSubmitted (notifier) {
      this.editActionModal = false;
      console.log(notifier);
      this.$emit('actionSubmit', notifier);
    },
    sendTest (recipient) {
      console.log('Todo: set up send notifier');

      this.$q.notify({
        color: 'green-4',
        textColor: 'white',
        icon: recipient.icon,
        message: 'Test message was sent to ' + recipient.type
      });
    }
  }
};
</script>

<style scoped> </style>
