import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Charts from '../views/Charts.vue';
// Notifications
import Notification_root from '../views/Notifications/nt-root.vue';
import Nt_Actions from '../views/Notifications/nt-actions.vue';
import Nt_Recipients from '../views/Notifications/nt-recipients.vue';
import Nt_Tabs from '../views/Notifications/nt-tabs.vue';
// Ponds
import Pond_root from '../views/pond/pond-root.vue';
import Pond_Tabs from '../views/pond/pond-tabs.vue';
import Pond_home from '../views/pond/pond-home.vue';
import Pond_alerts from '../views/pond/pond-alerts.vue';
import Pond_alarms from '../views/pond/pond-alarms.vue';
import Pond_buoy from '../views/pond/pond-buoy.vue';
import Pond_setpoints from '../views/pond/pond-setpoints.vue';
import Pond_timers from '../views/pond/pond-timers.vue';
import Pond_settings from '../views/pond/pond-settings.vue';
import Pond_resets from '../views/pond/pond-resets.vue';

import ViewSettings from '../views/ViewSettings.vue';
import PondSetup from '../views/PondSetup.vue';
import Users from '../views/Users.vue';
import AerlinkDevices from '../views/AerlinkDevices.vue';
// Sentinel
// import Sentinel from '../views/Sentinel.vue';
import Sentinel_root from '../views/Sentinel/Root.vue';
import Sentinel_Actions from '../views/Sentinel/Actions.vue';
import Sentinel_Recipients from '../views/Sentinel/Recipients.vue';
import Sentinel_Tabs from '../views/Sentinel/Tabs.vue';

import FarmUsers from '../views/FarmUsers.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/pond/:id/',
    components: {
      default: Pond_root,
      tabs: Pond_Tabs
    },
    props: {
      default: true,
      tabs: true
    },
    children: [
      {
        path: '',
        name: 'Pond-Home',
        component: Pond_home
      },
      {
        path: 'alerts',
        name: 'Pond-Alerts',
        component: Pond_alerts
      },
      {
        path: 'alarms',
        name: 'Pond-Alarms',
        component: Pond_alarms
      },
      {
        path: 'buoy',
        name: 'Pond-Buoy',
        component: Pond_buoy
      },
      {
        path: 'setpoints',
        name: 'Pond-Setpoints',
        component: Pond_setpoints
      },
      {
        path: 'timers',
        name: 'Pond-Timers',
        component: Pond_timers
      },
      {
        path: 'settings',
        name: 'Pond-Settings',
        component: Pond_settings
      },
      {
        path: 'resets',
        name: 'Pond-Resets',
        component: Pond_resets
      }
    ]
  },
  {
    path: '/charts',
    name: 'Charts',
    component: Charts
  },
  {
    path: '/notifications',
    components: {
      default: Notification_root,
      tabs: Nt_Tabs
    },
    children: [
      {
        path: 'actions',
        name: 'Notifications',
        component: Nt_Actions
      },
      {
        path: 'recipients',
        name: 'Recipients',
        component: Nt_Recipients
      }
    ]
  },
  {
    path: '/settings',
    name: 'Settings',
    component: ViewSettings
  },
  {
    path: '/pond-setup',
    name: 'PondSetup',
    component: PondSetup
  },
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
  {
    path: '/farm-users',
    name: 'FarmUsers',
    component: FarmUsers
  },
  {
    path: '/sentinel',
    name: 'Sentinel',
    components: {
      default: Sentinel_root,
      tabs: Sentinel_Tabs
    },
    children: [
      {
        path: 'actions',
        name: 'sentinel-notifications',
        component: Sentinel_Actions
      },
      {
        path: 'recipients',
        name: 'sentinel-recipients',
        component: Sentinel_Recipients
      }
    ]
  },
  /* {
    path: '/sentinel',
    name: 'Sentinel',
    component: Sentinel
  }, */
  {
    path: '/aerlink-devices',
    name: 'AerlinkDevices',
    component: AerlinkDevices
  }

];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
